:root {
    // [design]: primary colors
    --color_white: #fff;
    --color_dark-navy: #1d1e2b;
    --color_stone: #73747d;
    --color_cool-grey: #a9acc0;
    --color_pale-grey: #e3e7ed;        
    --color_indigo: #464ac9;
    --color_salmon: #ed4b53;    
        
    // [design]: secondary colors
    --color_green: #12C39A;
    --color_neon-green:#31E886;
    --color_orange:#ff764c;
    --color_yellow:#FDAA02;

    // [design]: accents
    --color_mist:#F3F6FA;
    --color_baby-pink:#FFF2EF;
    --color_baby-blue:#F4F5FF;
    --color_baby-green:#EDFDF4;

    // [design]: custom color - attention
    --color-gradient_salmon-to-orange: linear-gradient(90deg, var(--color_salmon), var(--color_orange));        

    // mine
    --color_dark-indigo: #3034a7;
    --color_translucent-indigo: #3034a70d;
}
 