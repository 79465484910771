@import 'components/atoms/colours.scss';

.summaryBox {
  width: 100%;
  margin: 2.5rem auto;
}

.summaryCaption {
  text-align: left;
}

.autoSaved {
  color: $success;
  text-align: left;
}

.saveIcon {
  height: 1.2rem !important;
  width: 1.2rem !important;
  margin-right: 5px;
  vertical-align: middle;
}