.daVinciPage {
  padding: 1.6rem 3.2rem;
  display: flex;
  gap: 3.2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 0 300px;
  margin-bottom: 50px;
}

.inputSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.displayColumn { 
  & > iframe{ 
    flex: 0 0 800px;
    max-height: 1200px;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 2 0 600px;
}

.buttonContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.dataInputTitleContainer {
  display: flex;
}

.dataInputTitle {
  flex: 1;
}