@import 'components/atoms/colours.scss';

.button {
  display: inline-block;
  min-width: 8.8rem;
  text-align: center;
  padding: 1rem 1.2rem;
  line-height: 1;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: extra-bold;
  user-select: none;
  & ~ &{
    margin-left: .8rem;
  }
}

.primary {
  composes: button;
  border-color: $primary;
  background-color: $primary;
  color: $contrast;

  &:hover {
    border-color: #00001d;
    background-color: #00001d;
  }
}

.warning {
  composes: button;
  border-color: $callToAction;
  background-color: $callToAction;
  color: $contrast;
  font-weight: 500;

  &:hover {
    border-color: $callToActionActive;
    background-color: $callToActionActive;
  }
}

.secondary {
  composes: button;
  border-color: $hint;
  background-color: $hint;
  color: $secondary;

  &:hover {
    border-color: #878992;
    background-color: #878992;
  }
}

.negative {
  composes: button;
  border-color: $chromium;
  background-color: $chromium;
  color: $contrast;

  &:hover {
    border-color: #a7a7ad;
    background-color: #a7a7ad;
  }
}

.primaryStroke {
  composes: button;
  border-color: $primary;
  background-color: $contrast;
  color: $primary;

  &:hover {
    border-color: $primary;
    color: #00001d;
  }
}

.warningStroke {
  composes: button;
  border-color: $callToAction;
  background-color: $contrast;
  color: $callToAction;

  &:hover {
    border-color: $callToAction;
    color: $callToAction;
  }
}

.disabled {
  composes: button;
  border-color: $background;
  background-color: $background;
  color: $disabled;
  cursor: default;
  pointer-events: none;
}

.linkButton {
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  color: $callToAction;
  text-decoration: underline;
  cursor: pointer;
}
