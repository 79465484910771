@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

html {
  font-size: 62.5%;
}

// Workaround for iPhone5 as we don't really support them
@media (max-width: 320px) {
  html {
    font-size: 50%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  background-color: white;
}

:global
label {
  color: $primary;
  display: block;
  margin: 0 0 0.5rem 0;
  &.inline{
    display: inline-block;
    margin: 0 0 0 0.5rem;
  }
}

*:focus {
  outline: none;
}

.app {
  display: flex;
  height: 100vh;

  @media print {
    background: none;
  }
}

.body {
  flex: 1;
  flex-direction: column;
  margin: 0;
  margin-left: 0;
  width: 100%;
  background-color: $mist;

  @include for-desktop-only {
    position: absolute;
    transition: all 0.05s ease-in-out;
    top: 0;
    left: 5rem;
    width: calc(100% - 5rem);
    height: 100%;
    right: 0;

    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  @media print {
    left: 0;
    width: 100vw;
  }
}

.autoHeight {
  height: auto !important;
}

.menuOpen {
  transition: all 0.05s ease-in-out;
  left: 18rem;
  width: calc(100% - 18rem);
}

.contents {
  background: $contrast;
  & > * {
    flex: 1;
    @include for-desktop-only {
      display: flex;
      flex-direction: column;
    }
  }

  @media print {
    margin: 0;
  }

  @include for-desktop-only {
    display: flex;
    flex: 1;
    min-height: 100%;
    width: 100%;
  }


  @include for-phone-only {
    margin: 0;
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  }
}

