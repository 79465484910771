@import 'components/atoms/colours.scss';

.commentFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.4rem;
	border-radius: 5px;
	background-color: $background;
  font-size: 1.4rem;
  overflow: hidden;
  & > div {
    display: flex;
    align-items: center;
  }
}

.fileIcon {
  margin-left: 1.6rem;
  margin-right: 2.8rem;
  font-size: 2.6rem;
}

.fileName {

}

.fileSize {
  margin-left: 1rem;
  margin-right: 1rem;
  color: $hint;
  font-size: 1rem;
}

.downloadButton {
  margin-right: 1.6rem;
  font-size: 2.2rem;
  color: $hint;
  cursor: pointer;
  &:hover{
    color: $primary;
  }
}

.deleteButton {
  margin-right: 1.6rem;
  font-size: 2.2rem;
  color: #b2b4bd;
  cursor: pointer;
  &:hover{
    color: $primary;
  }
}
