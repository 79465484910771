@import 'components/atoms/colours.scss';

.unavailable {
  width: 100%;
  background-color: #a9abc0;
  position: absolute;
  display: flex;
  border-radius: 4px;
}

.busy {
  font-family: Montserrat;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: 1rem;
}
