$indigo: #464ac9;
* {
  font-family: Montserrat;
  box-sizing: border-box;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/static/login.svg) center center / cover;
  height: 100vh;
}
.successContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f3f4f7;
  height: 100vh;
}
.successContent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .successLogo {
    display: inline-block;
    width: 254px;
    svg {
      width: 100%;
    }
  }
}
.content {
  position: absolute;
  left: 50%;
  top: 52px;
  transform: translateX(-50%);
  text-align: center;
  @media screen and (max-width: 480px) {
    width: 88%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.logo {
  display: inline-block;
  height: 70px;
  @media screen and (max-width: 480px) {
    height: 58px;
  }
}
.title {
  font-size: 40px;
  font-weight: bold;
  color: white;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}
.signUpContainer {
  margin: 52px auto 0;
  border-radius: 8px;
  background-color: #f6f7fa;
  padding: 40px 0;
  width: 330px;
  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 14px;
    margin: 20px auto 0;
    padding: 30px 0;
  }
}
.formTitle {
  font-size: 18px;
  font-weight: bold;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
}
.formSubTitle {
  font-size: 12px;
  font-weight: 500;
  color: #a9acbe;
  margin-top: 12px;
  padding: 0 44px;
  @media screen and (max-width: 480px) {
    padding: 0 20px;
  }
}
.form {
  padding: 0 44px;
  @media screen and (max-width: 480px) {
    padding: 0 20px;
  }
  .formInputContainer {
    margin-top: 20px;
  }
  .inputContainer {
    margin-bottom: 10px;
  }
  .button {
    margin-top: 18px;
    border-radius: 50px;
    width: 100%;
    height: 40px;
    color: white;
    font-size: 14px;
    background-color: $indigo;
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(61, 64, 144, 0.38);
      background-color: #2d30b4;
    }
    &:disabled {
      border: 1px solid #a9acc0;
      color: #a9acc0;
      background-color: #f6f7fa;
    }
  }
}
.successTitle {
  font-weight: 600;
  font-size: 26px;
  color: #1d1e2a;
  margin-top: 42px;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
}
.successSubTitle {
  font-size: 14px;
  color: #73747c;
  margin-top: 28px;
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}
.errorMessage {
  color: #fff;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #ff764c;
  margin-top: 20px;
}
