@import 'components/atoms/responsiveBreakpoints.scss';

.pageHeading {
  @include for-desktop-only {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
  @include for-phone-only {
    font-size: 1.8rem;
    letter-spacing: 1px;
    text-align: center;
  }
}

.subHeading {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.modalHeading {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.body {
  font-size: 1.2rem;
  line-height: 1.8rem;
  white-space: pre-wrap;
  word-break: break-word;
}

.buttonText {
  font-size: 1.2rem;
  white-space: nowrap;
}

.caption {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: bold;
}

.tiny {
  font-size: 0.8rem;
  line-height: 1.6rem;
}

.tiny.capitals {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
