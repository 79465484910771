@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.conversationWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  @include for-phone-only {
    width: 100vw;
  }
}

.conversationHeader {
  margin-top: 0;
  // background-color: #464ac9;
}

.panes {
  overflow: hidden;
}
.control {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  min-height: 7rem;

  @include for-phone-only {
    padding: 0 1.5rem;
    height: 6rem;
    border-bottom: $defaultBorderStyle;
  }

  .searchInput {
    flex-grow: 1;
    @include for-phone-only {
      height: 4rem;
    }
  }

  .createMessageButton {
    margin-left: 1.2rem;

    @include for-phone-only {
      display: none;
    }
  }
}
.threadsWrapper {
  display: flex;
  flex-direction: column;
}

.threadsSideBar {
  @include for-desktop-only {
    min-width: 24rem;
    max-width: 34rem;
    flex-basis: 34rem;
    flex: 0 0;
  }
  @include for-phone-only {
    width: 100%;
  }
  border-right: $defaultBorderStyle;
  display: flex;
  flex-direction: column;
  position: relative;

 

  .threadsWrapper {
    @include for-phone-only {
      height: calc(100vh - 42rem);
      height: calc(100vh - 42rem - env(safe-area-inset-top));
      height: calc(100vh - 42rem - constant(safe-area-inset-top));
    }
    @include for-iphonex-only {
      height: calc(100vh - 42rem);
    }
    @include for-desktop-only {
      height: calc(100vh - 20rem);
    }
    overflow: auto;

    @extend %scrollbar-transparent;
  }
}
.threadType {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.hideThread {
  display: none;
}

.source {
  display: flex;
  align-items: center;
  color: #1d1e2b;
  font-size: 16px;
  font-weight: bold;
  padding-left: 2.4rem;
}

.messages {
  // width: 100%;
  display: flex;
  // flex-direction: column;
  // position: relative;
  // overflow: hidden;

  // @include for-phone-only {
  //   z-index: $six;
  //   background: #fff;
  //   height: calc(100vh - 0.2rem);
  //   height: calc(100vh - 0.2rem - env(safe-area-inset-top));
  //   height: calc(100vh - 0.2rem - constant(safe-area-inset-top));
  // }
}

.hideBar {
  @include for-phone-only {
    display: none;
  }
}

.newMessageButton {
  @include for-desktop-only {
    display: none;
  }
}

.groupSideBar{
  @include for-desktop-only {
    min-width: 20rem;
    max-width: 30rem;
    flex-basis: 30rem;
    flex: 0 0;
  }
  @include for-phone-only {
    width: 100%;
  }
  border-right: $defaultBorderStyle;
  display: flex;
  flex-direction: column;
  position: relative;

  .control {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    height: 7rem;

    @include for-phone-only {
      padding: 0 1.5rem;
      height: 6rem;
      border-bottom: $defaultBorderStyle;
    }
  }
}

.iconButton {
  height: 2.4rem;
  width: 2.4rem;
  margin-left: 4px;
}