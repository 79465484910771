@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.tasksListTab {
  * {
    box-sizing: border-box;
  }
}

.tasksListContainer {
  padding: 1rem;
}

.tasksNoDate {
  padding: 1rem 4rem;
  @include for-phone-only {
    padding: 0 0.5rem;
  }
}

.optionsBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 4.8rem;
}

.optionsRight {
  margin-right: 3.3rem;
  flex: 0 0 auto;
  text-align: right;
}

.tasksHeading {
  display: grid;
  grid-template-columns: auto 20rem 14rem 11rem;
  background-color: $background;
  padding: 1.2rem 6.2rem;
  @include for-phone-only {
    display: none;
  }
}

.noTasks {
  padding-left: 25px;
}

.tasksHeadingNoRoadmap {
  > div {
    &:nth-of-type(1) {
      flex: 0 0 80%;
    }
    &:nth-of-type(2) {
      padding-right: 7.6rem;
    }
  }
}

.heading {
  color: $secondary;
  font-size: 1rem;
  line-height: 1.6rem;
}

.dueDate {
  text-align: center;
}

.taskRow {
  position: relative;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-0.3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.missionContainer {
  @include for-phone-only {
    display: none;
  }
}

.addCircle {
  color: $callToAction;
  margin-left: -0.2rem;
  vertical-align: bottom;
  margin-right: 1.6rem;
  height: 100%;
  cursor: pointer;
}

.taskListSection {
  position: relative;
  transition: min-height 300ms cubic-bezier(0.42, 0, 0, 1) 100ms;
  margin-bottom: 1rem;
}

.simple {
  .tasksListContainer {
    padding: 0;
  }

  .tasksNoDate {
    padding: 0;
  }

  .addNew {
    margin: 0 1.5rem;
  }
}