@import "components/atoms/colours.scss";
@import 'components/atoms/responsiveBreakpoints.scss';

.infoBanner {
  margin: -2.4rem -3.2rem 0;
  padding: 2rem 3.2rem;
  background-color: $background;

  @include for-phone-only { 
    margin: -2rem -2rem 0;
  }

  & > div > svg {
    margin-right: 3.6rem;
    height: 1.8rem;
    width: 1.8rem;
    fill: $contrast;
  }
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subheading {
  margin-top: 0.8rem;
}
