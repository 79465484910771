@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/colours.scss';

.contents {
  @include for-phone-only {
    background-color: $mist;
    height: calc(100vh - 5.2rem);
  }
}

.tabList {
  display: flex;
  border-bottom: none;
  justify-content: flex-start;
}

.tabName {
  border-bottom: none;
  @include for-desktop-only {
    display: inline-block;
    // margin-right: 2rem;
    padding-bottom: 0.2rem;
    :hover {
      cursor: pointer;
    }
  }
  @include for-phone-only {
    text-align: center;
    padding-bottom: 0.5rem;
    flex: 1;
  }
}

.vertical {
  margin: 0 1rem;
}

.emptyContainer {
  padding: 2rem;
  margin: auto;
  margin-top: 20%;
  max-width: 30rem;
  @include for-desktop-only {
    margin-top: 10%;
  }
}

.emptyHeader {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $slate;
}

.emptyContent {
  margin-bottom: 2rem;
  text-align: center;
  color: $slate;
}

.footerText {
  bottom: 4rem;
  max-width: 30rem;
  font-weight: bold;
  text-align: center;
  color: $sky;
  font-size: 1rem;
  margin-top: 2rem;
  @include for-desktop-only {
    position: absolute;
  }
}

.emptyGraphic {
  height: 10rem;
  width: 100%;
  margin-bottom: 2rem;
}

.buttonDiv {
  margin: 0 auto;
  width: 100%;
  @include for-desktop-only {
    width: 60%;
  }
}

.button {
  width: 100%;
}

.loading {
  width: 100%;
  height: 40rem;
}

