@import 'components/atoms/colours.scss';

.folderActions {
  position: relative;
}

.folderActionsDropDown {
  position: absolute;
  top: 100%;
  background: white;
  width: 80%;
  box-shadow: 2px 2px 6px 0px $stone;
  display: grid;
  grid-auto-flow: row;
  border-radius: 4px;
}

.folderAction {
  padding: 1.2rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8rem;
  grid-auto-columns: max-content;
  align-items: center; /* vertical centering */
  cursor: pointer;
  border-radius: 4px;
}
.folderAction:hover {
  background-color: $chromium;
}

.fileList {
  display: grid;
  grid-gap: 0.8rem;
}

.file {
  display: grid;
  grid-template-columns: 3.2rem minmax(0, 1fr) 3.2rem;
  grid-gap: 0.8rem;
  align-items: center; /* vertical align */
  cursor: pointer;

  &:hover {
    background: $chromium;
  }

  .fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectedFile {
  background-color: $chromium;
}

.fileActions {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;
  cursor: pointer;
  justify-items: end;
}

.fileActionsDropDown {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 100%;
  background: white;
  box-shadow: 2px 2px 6px 0px $stone;
  display: grid;
  grid-auto-flow: row;
  border-radius: 4px;
}

.fileAction {
  padding: 1.2rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8rem;
  grid-auto-columns: max-content;
  align-items: center; /* vertical centering */
  cursor: pointer;
  border-radius: 4px;
}
.fileAction:hover {
  background-color: $chromium;
}

.emptyState,
.loading {
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 1.6rem;

  justify-items: center;
  align-content: center;

  text-align: center;
  padding: 1.6rem;

  .emptyIllustration {
    width: 15rem;
    height: 15rem;
  }
}

.loading {
  height: calc(100vh - 20.4rem);
}

.documentBanner {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-items: center;

  height: 5rem;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 2.2rem;
  border-bottom: 1px solid $border;

  i {
    font-size: 2rem;
    cursor: pointer;
  }
}

.start {
  justify-self: start;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  grid-gap: 1.6rem;
}
.end {
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: end;
  grid-gap: 1.6rem;
}

.fileLoading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    margin-bottom: 1rem;
  }
}
