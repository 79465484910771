@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.formArea {
  margin-top: 1.6rem;
}

.requestArea {
  margin: 3.2rem 0 0;
}

.noTutorsSelected {
  align-items: center;
  justify-content: center;
  padding-top: 1.8rem;
}

.cactusGraphic {
  margin-bottom: 1.6rem;
}

.studentDetailsSection {
  flex-flow: row wrap;
  display: flex;
}

.studentDetailEntry {
  width: 50%;
  margin-top: 2rem;
}
