@import 'components/atoms/colours.scss';

.filter {
  display: flex;
  color: $primary;
  background-color: $background;
  border-radius: 5px;
}

.inputText {
  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  flex: 1;
  padding: 1.1rem;
  font-size: 1.2rem;
}

::placeholder {
  color: $primary;
}

.iconContainer {
  display: flex;
  align-items: center;
  padding-right: 1.1rem;
}

.magnifyingGlass {
  fill: $primary;
  width: 1.2rem;
  height: 1.2rem;
}
