@import 'components/atoms/responsiveBreakpoints.scss';

.regular {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.semiBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.extraBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}