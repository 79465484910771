@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.track {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: $background;
  width: 8rem;
  height: 2.4rem;
  border-radius: 1.4rem;
  cursor: pointer;
  position: relative;
  border: 0.2rem solid $background;

  .toggle {
    background-color: $primary;
    position: absolute;
    left: 0rem;
    height: 2.4rem;
    width: 4rem;
    border-radius: 1.2rem;
    transition: left 0.1s ease-out;

    &.on {
      left: 4rem;
    }
  }

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: $white;
    mix-blend-mode: difference;

    svg {
      font-size: 1.7rem;
    }

    &.active {
      mix-blend-mode: screen;
    }
  }
}
