@import 'components/atoms/colours.scss';

.heading {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 1.6rem;
  color: $primary;
  font-size: 1.4rem;
}
