// Used for ordering z-index

$zero: 0;
$one: 100;
$two: 200;
$three: 300;
$four: 400;
$five: 500;
$six: 600;
$seven: 700;
$eight: 800;
$nine: 900;

$layerTopNav: $five;
$layerModal: $six;
$layerFloatingButton: $two;
$layerPageOverlay: $five;