@import 'components/atoms/colours.scss';

.container {
  margin: 3.2rem auto;
  max-width: 50rem;
  width: 100%;
}

.description {
  margin-top: 5.2rem;
  background-color: $background;
  color: $disabledText;
  width: 100%;
  max-width: 47.2rem;
  padding: 1.4rem;
  // Hide this for now
  display: none;
}

.rating {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
}