@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.searchInput {
  display: flex;
  flex-grow: 1;
  color: $primary;
  border: $defaultInputBorderStyle;
  border-radius: 0.2rem;
  cursor: text;

  &.focus {
    border: $activeInputBorderStyle;
  }
}

.inputText {
  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  flex: 1;
  display: flex;
  padding: 1rem;
  font-size: 1.2rem;
  min-width: 0;
}

.inputText:focus {
  outline: none;
}

::placeholder {
  color: $hint;
}

.iconContainer {
  display: flex;
  align-items: center;
  padding-right: 1.1rem;
}

.magnifyingGlass {
  fill: $hint;
  width: 1.2rem;
  height: 1.2rem;
}

.crossIcon {
  fill: $hint;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;

  &:hover {
    fill: $primary;
  }
}

.hidden {
  display: none;
}
