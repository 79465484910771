@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.schedule {
  background: $mist;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-phone-only {
    min-height: calc(100vh - 9.2rem);
  }
}

.timeTabWrapper {
  margin: 2rem 0 0 0;

  @include for-phone-only {
    margin: 0;
    padding: 2rem 1.6rem 0 1.6rem;
    width: 100%;
    box-sizing: border-box;
  }
}

.timeTab {
  min-width: 25rem;
  
  @include for-phone-only {
    width: 100%;
  }
}

.loader {
  margin-top: 5rem;
}