@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.session {
  background-color: $background;
  min-height: 100%;
  overflow: auto;
  padding-bottom: 4em;

  @include for-phone-only {
    min-height: 100vh;
    padding-bottom: 7em;
  }

  .sessionContent {
    margin: auto;
    max-width: 90rem;
  }

  .info {
    display: flex;
    align-items: center;
    margin: 1rem auto;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #73747d;
   
  }
  .infoIcon {
    color: #a9acbe;
  }

  .sessionHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3rem;
  
    span {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      color: #1f7a8d;
    }
  }

  .action {
    margin-left: auto;
    margin-right: 2rem;
  }
  .cancelButton {
    background-color: white;
    border: 1px solid #ed4b53;
    height: 3rem;
    padding: 0;
  }

  .cancelButtonText {
    color: #ed4b53;
  }
  .saveButton {
    height: 3rem;
    border: 1px solid #ed4b53;
    padding: 0;
  }

  .savedButton {
    border: 1px solid #e4e7ec;
    background-color: #e4e7ec;
    height: 3rem;
  }

  .savedButtonText {
    color: #a9acbe;
  }
  
  .card {
    color: $primary;
    margin: 0.8rem auto 0;
    
    &:hover {
      background-color: white;
    }

    @include for-phone-only {
      padding: 1.6rem 0;
    }

    h1 {
      margin: 0;
      padding-left: 2.4rem;
      padding-right: 2rem;
      font-size: 1.6rem;
      font-weight: 600;

      @include for-phone-only {
        padding: 0 1.6rem 1.6rem;
      }
    }
  }
  .cardDisabled {
    color: $primary;
    margin: 0.8rem auto 0;
    background-color: #cccccc;
    @include for-phone-only {
      padding: 1.6rem 0;
    }
    h1 {
      margin: 0;
      padding-left: 2.4rem;
      padding-right: 2rem;
      font-size: 1.6rem;
      font-weight: 600;

      @include for-phone-only {
        padding: 0 1.6rem 1.6rem;
      }
    }
  }
}

.hidden {
  display: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $background;
  text-align: right;
  height: 4em;
  border-top: 2px solid $border;
  padding: 1.5em 0;

  .sessionContent {
    margin: auto;
    max-width: 90rem;
  }

  @include for-phone-only {
    text-align: center;
  }
}

.editConflict{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1d1e2a;
  p:first-child { 
    margin-bottom: 1rem;
  }
}

.modalContent {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1d1e2a;
}

.modalFooter {
  align-self: center;
}

.secondButtonStyle {
  border: 1px solid #dc5758;
  background-color: white;
}