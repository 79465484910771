@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.replyContainer {
  display: flex;
  position: relative;
  width: 100%;
}

.textFieldContainer {
  display: flex;
  width: 100%;
}

.formInput{
  display: block;
  min-height: 4.6rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  line-height: 1.6;
  &:focus + .plusButton {
    color: $primary;
    border: $activeInputBorderStyle;
  }
  &:active + .plusButton {
    color: $primary;
    border: $activeInputBorderStyle;
  }
}

.plusButton {
  margin-left: -2px;
  width: 4.6rem;
  text-align: center;
  border: $defaultInputBorderStyle;
  color: $hint;
  cursor: pointer;
  &:hover > .plusIcon {
    color: $primary;
  }
}

.plusIcon {
  font-size: 3rem;
  margin-top: 0.8rem;
}

.uploader {
  display: none;
}

.addFileModal {
  width: 47rem;
}

.addFileModalContent {
  padding: 1.6rem 0 0;
}

.addFileModalTextFieldContainer {
  flex-direction: column;
  margin-top: 3.2rem;
}

.addFileModalTextFieldInput{
  margin: 0;
  height: 11rem;
}
