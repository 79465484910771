.thumbnail {
  margin: -1.6rem -1.6rem 0 -1.6rem;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 20rem;
  background-color: #1D1E2A;

  .thumbnailItem {
    margin: auto;
    text-align: center;

    img {
      width: 30%
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #F6F6F6;
    width: 80%;
  }

  label {
    color: #7B86A9
  }
}