@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.wholeDay{
  display: flex;
  width: 100%;
  flex-direction: column;
  // border-style: none solid none solid;
  // border: solid 1px #e3e7ed;
}

.vertical{
  border-left: solid 1px #e4e7ec;
  border-right: solid 1px #e4e7ec;
  border-bottom: solid 4px #e4e7ec;
  height: 3rem;
  flex-shrink: 0;
  box-sizing: border-box;
}
.day {
  border: $defaultBorderStyle;
  border-style: none none none solid;
  height: 168rem;
  position: relative;
  &:last-child {
    border-style: none solid none solid;
  }
  background: linear-gradient(
    to bottom,
    $contrast, 
    $contrast 99%, 
    $border 1px, 
    $border
  );
  
  /* The rectangle in which to repeat.
    It can be fully wide in this case */
  background-size: 100% 7rem;
}

.columnWidth {
  width: 100%;
}

.today {
  background: rgba(186, 188, 197, 0.1);
  height: 100%;
  z-index: 1;

  .currentTimeLine {
    width: 100%;
    border-bottom: 0.2rem solid $salmon;
    position: absolute;
    z-index: 3;
  }
}

.dayOverlay {
  background: transparent;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}