@import 'components/atoms/borders.scss';

.bookingAs {
  flex: 0;
  + div {
    max-height: calc(100vh - 22.5rem);
  }
}

.person {
  display: flex;
  align-content: center;
  padding: 1.6rem 0;
  border-bottom: $defaultBorderStyle;
}
