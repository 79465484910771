@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

$headerHeight: 4rem;
$footerHeight: 6rem;

$mobileHorizontalPadding: 2rem;
$mobileVerticalPadding: 1rem;

$smallOffSet: 4rem;
$mediumOffSet: 8rem;
$largeOffSet: 12rem;

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: $layerModal;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @include for-phone-only {
    -webkit-overflow-scrolling: touch;
    top: calc(0px + env(safe-area-inset-top));
    top: calc(0px + constant(safe-area-inset-top));
  }
}

.content {
  margin: 6rem auto;
  border-radius: 5px;
  outline: none;
  padding: 0 0 2.4rem;
  width: 51.2rem;
  background: $contrast;
  box-shadow: 0 4px 5px 0 rgba(109, 109, 109, 0.5);
  display: flex;
  flex-direction: column;

  @include for-phone-only {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0;
    display: block;
    border-radius: 0;
    margin: 0;
    overflow: auto;
  }
}

.closeAction {
  position: fixed;
  top: 0.8rem;
  right: 2.2rem;
  z-index: 502;

  @include for-phone-only {
    right: 0.5rem;
    top: 0.2rem;
  }

  &.withHeaderImage {
    position: absolute;
  }
}

.header {
  background: inherit;
  padding: 2rem 2.4rem 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5.2rem;
  box-sizing: border-box;
  border-bottom: $defaultBorderStyle;
  border-radius: 5px 5px 0 0;
  position: relative;

  @include for-phone-only {
    border-radius: 0;
    // padding-top: calc(2rem + constant(safe-area-inset-top)); /* iOS 11.0 */
    // padding-top: calc(2rem + env(safe-area-inset-top));
    height: $headerHeight;
    padding: 2rem;
  }

  .subtitle {
    color: $slate;
  }

  .title {
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 40rem;
    text-align: center;
  }

  .backAction {
    position: absolute;
    left: 2.2rem;

    @include for-phone-only {
      left: 0.5rem;
    }
  }
}

.main {
  @include for-phone-only {
    top: calc(0.2rem + #{$headerHeight});
    bottom: calc(0.2rem + #{$footerHeight});
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    // For iOS
    top: calc(0.2rem + #{$headerHeight});
    bottom: calc(0.2rem + #{$footerHeight} + env(safe-area-inset-bottom));

    &.withHeaderImage {
      position: initial;
      margin-top: 1rem;
      margin-bottom: $footerHeight;
    }
  }

  &.noFooter {
    @include for-phone-only {
      bottom: 0.2rem;
      bottom: calc(0.2rem + env(safe-area-inset-bottom));
    }
  }
}

.mainContent {
  padding: 2.4rem 3.2rem 0;
  font-size: 1.2rem;
  color: $primary;

  @include for-phone-only {
    padding: $mobileVerticalPadding $mobileHorizontalPadding;
  }

  flex: 1;
}

.small {
  @include for-phone-only {
    top: calc(0.2rem + #{$headerHeight} + #{$smallOffSet});
  }
}

.footer {
  padding: 2.4rem 3.2rem 0;
  align-self: flex-end;

  @include for-phone-only {
    position: absolute;
    bottom: 0;
    bottom: env(safe-area-inset-top);
    width: 100%;
    box-sizing: border-box;
    padding: 1rem $mobileHorizontalPadding;
    height: $footerHeight;
    border-top: $defaultBorderStyle;
    background: #fff;

    &.withHeaderImage {
      position: fixed;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  min-height: 3.6rem;

  & > * {
    &:nth-child(2) {
      margin-left: 0.8rem;
    }
  }

  @include for-desktop-only {
    align-items: flex-end;
  }

  @include for-phone-only {
    justify-content: center;
    align-items: center;

    & > * {
      width: 100%;
    }
  }
}

.tertiaryButton {
  margin-right: auto;
}

.buttonLoadingState {
  position: absolute;
  top: 1.2rem;
  left: calc(50% - 0.6rem);
}

.hidden {
  visibility: hidden;
}

.enableOverFlow {
  max-height: none;
  overflow: visible;
}

.hideClose {
  visibility: hidden;
}

.headerImage {
  display: flex;
  width: 100%;
  min-height: 20rem;
  position: relative;
  background-color: $slate;
  border-radius: 5px 5px 0 0;

  @include for-phone-only {
    min-height: 35%;
  }

  & > svg {
    width: 100%;

    @include for-desktop-only {
      border-radius: 5px 5px 0 0;
    }
  }
}


:global {
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    &--after-open {
      @include for-phone-only {
        overflow: hidden;
      }
    }
  }

  .ReactModalPortal .ReactModal__Content {
    @include for-desktop-only {
      transform: scale(0.75);
      opacity: 0.2;

      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

      &--after-open {
        transform: scale(1);
        opacity: 1;
      }
      &--before-close {
        transform: scale(0.75);
        opacity: 0.2;
      }
    }
  }
}
.hideHeader{
  .header{
    display: none;
  }
  .closeAction{
    display: none;
  }
}