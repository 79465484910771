// from: https://gist.github.com/marcelo-ferraz/f07c7a625eff7064c373b25b57914a80

:root {
  --one-unit: 8.33333%;
}

$xu-min: 30em;
$sm-min: 48em;
$md-min: 64em;
$lg-min: 75em;

@mixin getUnits($size: '') {
  > u#{$size}-0 {
    -mu-flex-preferred-size: 0;
    flex-basis: 0;
  }
  > *,
  > .u#{$size}-1-12 {
    -mu-flex-preferred-size: var(--one-unit);
    flex-basis: var(--one-unit);
  }
  > .u#{$size}-2-12,
  .u#{$size}-1-6 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 2);
    flex-basis: calc(var(--one-unit) * 2);
  }
  > .u#{$size}-3-12,
  .u#{$size}-1-4 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 3);
    flex-basis: calc(var(--one-unit) * 3);
  }
  > .u#{$size}-4-12,
  .u#{$size}-2-6,
  .u#{$size}-1-3 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 4);
    flex-basis: calc(var(--one-unit) * 4);
  }
  > .u#{$size}-5-12 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 5);
    flex-basis: calc(var(--one-unit) * 5);
  }
  > .u#{$size}-6-12,
  .u#{$size}-3-6,
  .u#{$size}-2-4,
  .u#{$size}-1-2 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 6);
    flex-basis: calc(var(--one-unit) * 6);
  }
  > .u#{$size}-7-12 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 7);
    flex-basis: calc(var(--one-unit) * 7);
  }
  > .u#{$size}-8-12,
  .u#{$size}-4-6,
  .u#{$size}-2-3 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 8);
    flex-basis: calc(var(--one-unit) * 8);
  }
  > .u#{$size}-9-12,
  .u#{$size}-3-4 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 9);
    flex-basis: calc(var(--one-unit) * 9);
  }
  > .u#{$size}-10-12,
  .u#{$size}-5-6 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 10);
    flex-basis: calc(var(--one-unit) * 10);
  }
  > .u#{$size}-11-12 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 11);
    flex-basis: calc(var(--one-unit) * 11);
  }
  > .u#{$size}-12-12,
  .u#{$size}-1 {
    -mu-flex-preferred-size: calc(var(--one-unit) * 12);
    flex-basis: calc(var(--one-unit) * 12);
  }
}

.horizontal,
.horiz {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -mu-flex-flow: row wrap;
  flex-flow: row wrap;

  &.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -mu-flex-direction: row-reverse;
    flex-direction: row-reverse wrap;
  }
}

.vertical,
.vert {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -mu-flex-flow: column;
  flex-flow: column wrap;

  &.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -mu-flex-direction: column-reverse;
    flex-direction: column-reverse wrap;
  }
}

.vertical,
.vert,
.horizontal,
.horiz {
  display: -webkit-box;
  display: -mu-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -mu-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -mu-flex-pack: start;
  justify-content: flex-start;

  &.align-items,
  .align-items {
    &.center {
      -webkit-box-pack: center;
      -mu-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -mu-flex-align: center;
      align-items: center;
    }
    &.start {
      -webkit-box-align: start;
      -mu-flex-align: start;
      align-items: flex-start;
    }

    &.end {
      -webkit-box-align: end;
      -mu-flex-align: end;
      align-items: flex-end;
    }

    &.justify-end {
      -webkit-box-pack: end;
      -mu-flex-pack: end;
      justify-content: flex-end;
    }

    &.justify-start {
      -webkit-box-pack: start;
      -mu-flex-pack: start;
      justify-content: flex-start;
    }
  }

  .align-self {
    align-self: auto;

    &.start {
      align-self: flex-start;
    }
    &.end {
      align-self: flex-end;
    }
    &.center {
      align-self: center;
    }
    &.baseline {
      align-self: baseline;
    }
    &.stretch {
      align-self: stretch;
    }
  }

  &.grow,
  .grow {
    -webkit-box-flex: 1;
    -mu-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  > * {
    -webkit-box-flex: 0;
    -mu-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  @include getUnits();

  @media only screen and (max-width: $xu-min) {
    @include getUnits('-xs');
  }

  @media screen and (min-width: $sm-min) {
    @include getUnits('-sm');
  }

  @media screen and (min-width: $md-min) {
    @include getUnits('-md');
  }

  @media screen and (min-width: $lg-min) {
    @include getUnits('-lg');
  }
}