@import 'components/atoms/colours.scss';

.info {
  display: flex;
}

.infoIcon {
  fill: $secondary !important;
  display: inline !important;
  vertical-align: middle !important;
  width: 2rem !important;
  height: 2rem !important;
  > * {
    width: 1.6rem !important;
    height: 1.6rem !important;
  }
}

.infoText {
  color: $secondary;
  font-size: 1rem;
  display: inline;
  margin-left: 1.6rem;
  flex-grow: 1;
}

.confirm {
  color: $primary;
  font-size: 1.2rem;
  margin: 1.6rem 0 0;
}

.incorrectLink {
  color: $callToAction;
  margin-right: 2.4rem;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.buttons {
  text-align: right;
  margin-top: 2.4rem;
}
