@import 'components/atoms/colours.scss';

.root {
  z-index: 501;
  display: flex;
}

.menuButton {
  margin-left: 12px;
  margin-right: 36px;
}

.hide {
  display: none;
}

.drawerPaper {
  white-space: nowrap;
  width: 18rem;
  transition: width 0.05s ease-in-out;
  border-right: none !important;
  overflow: hidden;
  background-color: $charcoal !important;
}

@media print {
  div.drawerPaper {
    display: none;
  }
}

.drawerPaperClose {
  overflow-x: hidden;
  width: 5.2rem;
  transition: width 0.05s ease-in-out;
}

.shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
}

.content {
  flex-grow: 1;
  background-color: grey;
  padding: 5px;
}
