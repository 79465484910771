@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/scrollbar.scss';

$iconSize: 2rem;

.navigationWrapper {
  height: 100%;
  overflow-y: auto;
  max-width: 70vw;

  @include for-desktop-only {
    height: 100%;
    overflow-y: hidden;
    max-width: 70vw;
    margin-top: 52px;
  }
}

.mobileScrollbar {
  @extend %scrollbar-transparent;
}

.navigation {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;

  min-height: 100%;

  font-size: 1.4rem;
  font-weight: bold;
  color: $slate;
  background-color: #fff;

  @include for-desktop-only {
    background-color: $charcoal;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }

  @include for-phone-only {
    overflow-y: auto;
    min-width: 20rem;
  }

  @media print {
    display: none;
  }
}

.navigationOpened {
  .navItemContent:hover {
    @include for-desktop-only {
      background: $slate;
    }
  }
}

.navTop {
  display: flex;
  flex-direction: column;
}

.transparentScrollbar {
  @include custom-scrollbar($charcoal, $slate);
}

.navItems {
  display: flex;
  flex-direction: column;

  svg {
    width: 2rem;
  }
}

.hideNav {
  display: none;
}

.navItem {
  color: $hint;
  text-decoration: none;
  font-size: 1.2rem;
  flex: 1 1;

  .leftNavIcon {
    font-size: 2rem;
    color: $hint;
    text-align: center;
    width: 100%;

    @include for-desktop-only {
      color: $slate;

      &:hover {
        color: white;
      }
    }
  }

  &.selected {
    .navItemContent {
      color: $salmon;

      svg {
        fill: $salmon;
      }

      @include for-desktop-only {
        color: white;

        svg {
          fill: white;
        }
      }
    }

    .leftNavIcon {
      color: $salmon;

      @include for-desktop-only {
        color: white;
      }
    }
  }

  .navItemContent {
    display: grid;
    grid-template-columns: 5.2rem auto auto;
    grid-template-rows: 4.6rem;
    align-items: center;
    color: $hint;
    position: relative;

    svg {
      fill: $hint;
      width: $iconSize;
      height: $iconSize;
    }

    i {
      font-size: $iconSize;
    }

    svg, i {
      margin: auto; // center align
    }

    span {
      line-height: 1.6rem;
    }

    @include for-desktop-only {
      color: $slate;

      svg {
        fill: $slate;
      }
    }


    .indicatorDot {
      color: $callToAction;
      font-size: 1rem;
      margin: 0;

      position: absolute;
      top: 1.2rem;
      left: 3rem;
    }
  }

  .navItemContent:hover {
    @include for-desktop-only {
      color: white;

      svg {
        fill: white;
        transition: none;
      }

      i {
        color: white;
      }

      .indicatorDot {
        color: $callToAction;
      }
    }
  }
}

.navBottom {
  border-top: 1px solid $chromium;
  padding: 0.8rem 0;

  @include for-desktop-only {
    border-top: 1px solid $charcoal;
    padding-bottom: 1.6rem;
  }

  cursor: pointer;
}

.relativePosition {
  position: relative;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(205, 75, 83, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(205, 75, 83, 0);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(205, 75, 83, 0);
  }
}

.miniCircle {
  border-radius: 50%;
  height: 13px;
  width: 13px; 
  display: inline-block;
  background: #dc5758;
  vertical-align: top;
  margin-left: -22px;
  margin-top: -10px;
  .number {
    display: block;
    color: #FFFFFF;
    height: 13px;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.3rem !important;
  }
}



.circle {
  border-radius: 50%;
  height: 15px;
  width: 15px; 
  display: inline-block;
  background: #dc5758;
  vertical-align: top;
}

.number {
  display: block;
  color: #FFFFFF;
  height: 15px;
  line-height: 15px;
  text-align: center;
  font-size: 1rem;
}