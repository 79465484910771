@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.dateGroup {
  display: flex;
  position: relative;
}

.missionGroup {
  display: flex;
  position: relative;
  overflow: hidden;

  > * {
    &:nth-child(1) {
      flex: 1 1 auto;
      min-width: 0;
    }

    &:nth-child(2) {
      flex: 0 1 3.6rem;
      min-width: 3.6rem;
    }
  }
}

.clearValueButton {
  flex: 1 0 auto;
  visibility: hidden;
}

.taskContainer {
  display: grid;
  grid-template-columns: 2.4rem auto;
  margin-bottom: 0.8rem;
  align-items: center;
  grid-gap: 0.8rem;
  height: 4.2rem;
  border-radius: 2px;
  padding-right: 1.2rem;
  padding-left: 1.6rem;
}

.taskContainerFull {
  grid-template-columns: 2.4rem auto 14rem 20rem 4.6rem;
  &.withMission {
    grid-template-columns: 2.4rem auto 14rem 20rem 4.6rem;
  }
  &:hover {
    background-color: $background;
    input {
      background-color: transparent;
    }
    .clearValueButton {
      visibility: visible;
      align-self: center;
    }
    .daysRemainingText {
      background-color: transparent;
    }
    .deleteTaskColumn {
      display: flex;
    }
    .deleteTaskColumnMobile {
      display: flex;
    }
  }
}

.taskContainerCompact {
    &:hover {
    background-color: $background;
    input {
      background-color: transparent;
    }
    .detailsButtonCompact {
      visibility: visible;
    }
  }
}

.taskContainerShowDate {
  grid-template-columns: 2rem auto 10rem 6rem;
  .clearValueButton {
    visibility: visible;
    align-self: center;
  }
}

.checkBoxColumn {
  justify-self: center;
  margin-left: 0.5rem;
}

.taskNameColumn {
  align-self: flex-end;
}

.taskDateColumn {
  text-align: center;
}

.taskDateColumnCompact {
  display: none;
}

.deleteTaskColumn {
  display: none;
}

.deleteTaskColumnCompact {
  justify-self: end;
}

// Checkbox
.circleContainer {
  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  
  &:hover:not(.noselect) {
    cursor: pointer;
  }
}

.unticked {
  color: $secondary;
}

.ticked {
  color: $disabledText;
}


// Task name
.nameContainer {
  position: relative;
  display: flex;
}

div.taskNameInputCompact {
  flex: 1;
  margin-right: 4rem;

  > input {
    padding-right: 0;
  }

  &.withDateCompact {
    > input {
      padding-right: 0;
    }

    &.completed {
      padding-bottom: 0;
    }

    &.notEditMode {
      > input {
        padding-bottom: 1.6rem;
      }
    }
  }

  &.notEditMode {
    > input {
      padding-bottom: 0;
    }
  }
}

.taskNameInput {
  display: inline-block;
  flex: 1 0 auto;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0.8rem;
  cursor: default;
  > input {
    transition: padding 0.1s ease-in;
    height: 4.2rem;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: $defaultBorderStyle;
    margin-left: 0;
    padding: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: text;

    &:focus {
      border-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: $activeInputBorderStyle;
    }
  }
  &.withDate {
    > input {
      position: relative;
      padding-right: 8.8rem;
    }
  }
}

.taskNameInvalid {
  > input {
    border-bottom: $warningInputBorderStyle;
    &:focus {
      border-bottom: $warningInputBorderStyle;
    }
  }
}

.detailsButtonCompact {
  position: absolute;
  right: 0;
  visibility: hidden;
  top: 0.2rem;
}

.detailsButtonDesktop {
  display: none;
}


// Task mission
.missionContainer {
  display: flex;
}

.missionContainerCompact {
  display: none;
}

.roadmapCategorylabelButton {
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    background-color: $backgroundHover;
  }
}


// Task date
.daysRemainingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: translateX(calc(-100% - 8px));
}

.daysRemaining {
  position: absolute;
  bottom: 0.4rem;
  left: 0;
}

.daysRemainingFull {
  display: none;
}

.daysRemainingText {
  background: $contrast;
  padding-left: 0.2rem;
  color: $secondary;
}

.warningDaysLeft {
  color: $warning;
}

.taskValidationMessage {
  position: relative;
  padding-left: 4.5rem;
  margin-top: -0.6rem;
  z-index: 1;
}

.taskValidationMessageCompact {
  padding-left: 3rem;
}

.nameComplete {
  > input {
    // transition: color 0.2s linear;
    color: $hint;
    position: relative;
    text-decoration: line-through;
  }
}

.daysRemainingComplete {
  color: $hint;
  text-decoration: line-through;
}