@import 'components/atoms/colours.scss';

.table {
  width: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.tableHeadings {
  align-items: center;
  width: 100%;
  background-color: $background;
  padding: 1.6rem 3.2rem 1.6rem 0;
}

.tableRow {
  align-items: center;
  width: 100%;
  padding: 2rem 3.2rem 2rem 0;
  &:hover {
    background-color: $background;
  }
}

.column {
  padding-left: 3.2rem;
}

.columnMinWidth {
  min-width: 10rem;
}

.emptyMessage {
  text-align: center;
  margin: 2rem 0;
}

.cell {
  a {
    color: $primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.clickable {
  cursor: pointer;
}

.scrollable {
  overflow-x: auto;
}