@import "components/atoms/colours.scss";

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $contrast;
  transition: 0.5s;

  & > div {
    background-color: currentColor;
  }
}
