@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.sessionHeader {
  background-color: $white;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-bottom: $defaultBorderStyle; */
  padding: 1rem;
  background-color: $mist;

  .message {
    margin-bottom: 0.5rem;
  }

  .actionGroup {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;

    @include for-phone-only {
      grid-auto-columns: 1fr;
      grid-gap: 1.6rem;
      width: 100%;

      & > * {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0.5rem;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.sessionDetails {
  padding: 0 2rem 1.2rem 2rem;
  display: flex;
  flex-direction: column;

  .detailBlock {
    display: flex;
    margin: 0 auto;
  }
  .actionButton {
    color: #2b2b34;
  }
}

.bookAs {
  margin-top: 1.2rem;
  text-align: center;
}

.participants {
  display: flex;
  justify-content: center;
  margin: 0 6rem;

  .participant {
    margin-right: 1rem;
  }

  .participant:last-child {
    margin-right: 0;
  }
}

.sessionStatus {
  border-bottom: $defaultBorderStyle;
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
}

.profilePic{
  text-decoration: none;
  cursor: pointer;
}
