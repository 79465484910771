@import 'components/atoms/colours.scss';
@import 'components/atoms/shadows.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.profileItem {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  font-size: 1.2rem;
  max-width: 64rem;

  &.readonly {
    border-radius: 2px;
  }
}

.unassigned {
  & div {
    & span {
      color: $hint;
    }
  }
}

.rightItem {
  @include for-desktop-only {
    margin-left: 0;
  }
}

.itemSwitcher {
  position: relative;
  border-radius: 0.2rem;
  padding: 1.2rem 0rem;

  @include for-phone-only {
    box-shadow: $defaultCardShadow;
  }
  &.readonly {
    border-radius: 2px;
    box-shadow: none;
  }
  &.archived > div {
    color: $hint;
  }

  @include for-phone-only {
    margin: 1.6rem 0;
  }
}

.bio {
  white-space: pre-wrap;
}

.itemSwitcherEditing {
  border-color: $primary;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.itemTitle {
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-bottom: $defaultBorderStyle;

  @include for-phone-only {
    margin-left: 0;
    margin-right: 0;
  }
}

.itemIcon {
  width: 2rem;
  margin-right: 0.8rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: $primary;
}

.itemName {
  font-weight: bold;
  margin-bottom: 1rem;
  color: $primary;
  font-size: 1.4rem;
}

.itemDescription {
  padding: 1rem 0 1rem 0;
  color: $primary;
}

.itemDetail {
  font-size: 1rem;
  color: $secondary;
  padding: 1rem 0 0;
  @include for-phone-only {
    padding: 1rem;
  }
}

.itemBody {
  margin: 0;
  color: $primary;
  word-break: break-word;
    @include for-phone-only {
    padding: 1rem;
  }

  & > .formRow {
    margin: 0;
    margin-bottom: 1.6rem;
  }

  & .horizontalGroup > div {
    margin-bottom: 0;
  }
}

.itemBodyCentered {
  padding: 0 2.2rem;
  color: $primary;
  display: flex;
  justify-content: left;
  @include for-phone-only {
    padding: 0;
  }
}

.itemBodyCompact {
  color: $primary;
  word-break: break-word;
  padding: 0rem 6.1rem 0rem 0rem;
  @include for-phone-only {
    padding: 1rem 0;
  }
}

.itemContent {
  white-space: pre-wrap;
  @include for-phone-only {
    padding: 1rem;
  }
}

.status {
  display: flex;
  align-items: center;
}

.subtitle,
.subName {
  font-size: 1rem;
  padding: 0 0 0.5rem 0;
  line-height: 1.2;
  color: $secondary;
}

.subText {
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.list {
  div {
    margin: 0 0 0.6rem;
  }
}

.addSubjectButton {
  float: right;
  margin: 1.4rem 0 1.6rem 0;
}

.horizontalLine {
  clear: both;
  outline: none;
  border: $defaultBorderStyle;
  margin: 1.6rem 0;
}

.formRow:nth-child(1){
  margin-top: 3rem;
}

.horizontalGroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.6rem;

  & > div {
    margin: 0 0 1.5rem 0;
    padding-right: 8rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.justifyColumns {
  justify-content: space-between;
}

.horizontalFormItem {
  flex: 1;
}

.horizontalBillingFormItem {
  flex: 1;
}

.itemEmptyText{
  color: $hint;
}

.modalContent {
  font-size: 1.4rem;
  color: $primary;

  .description {
    margin: 1rem 0;
  }
}

.toggleIconClass {
  width: 2rem;
  height: 2rem;
  fill: $primary;
}

.toggleButton {
  position: absolute;
  right: 0.4rem;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  max-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1.6rem;
  text-align: center;
}

.saveButton {
  composes: warning from "styles/buttons.scss";
}

.cancelButton {
  composes: linkButton from "styles/buttons.scss";
  text-decoration: none;
  margin-right: 2rem;
}

.newCancelButton {
  margin-right: 0.4rem;
}

.disabled {
  composes: disabled from "styles/buttons.scss";
}

.archived {
  &, &.readonly, & div, & i, & div:hover {
    border-color: $hint;
    color: $hint;
  }
}

.canEditText {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  color: $hint;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}


.taxRate,
.taxRate > input {
  text-align: right;
  flex-shrink: 0;
}

.dob {
  margin: 1.6rem 0;
}

.itemTitleCompact {
  margin-left: 0;
  margin-right: 0;
}

.itemSwitcherCompact {
  margin-left: 0;
  margin-right: 0;
}

.subItem {
  margin-bottom: 0.8rem;
}

.contactDetailsWrapper {
  color: $secondary;
}

.contactDetail {
  padding-bottom: 1.2rem;
}

.myTeamWrapper {
  padding: 0;
}