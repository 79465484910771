@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/borders.scss';

.invoiceFilters {
  padding: 1rem 2.5rem;
  border-bottom: $defaultBorderStyle;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media print {
    display: none;
  }

  @include for-phone-only {
    padding: 0.5rem 0.8rem; 
  }

  & > *:first-child {
    margin-right: 2rem;

    @include for-phone-only {
      display: none;
    }
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin: 0.5rem 2rem 0.5rem 0;

    @include for-phone-only {
      margin: 0.5rem 1rem 0.5rem 0;
    }
  }
}

.clearFilters {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.5rem 1rem;
}