@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.container {}

.emptyMessage {
  font-size: 1.8em;
  text-align: center;
  color: $stone;
  margin-bottom: 1em;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  thead tr {
    height: 2.8rem;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
    color: $secondary;
    border-bottom: $defaultBorderStyle;
    a {
      color: $primary;
      text-decoration: none;
    }
  }
  tbody tr {
    height: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.4;
    color: $secondary;
    background-color: $contrast;
    border-bottom: $defaultBorderStyle;
    &:hover {
      background-color: $background;
    }
    a {
      color: $primary;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  th {
    padding: 0.8rem 3.2rem;
    color: $primary;
  }
  td {
    padding: 1.6rem 3.2rem;
  }
  th,
  td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.horizontalCell {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.verticalCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.start {
  align-items: flex-start;
}
.end {
  align-items: flex-end;
}
.center {
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.icon {
  width: 2rem;
  margin-right: 1.5rem;
  font-size: 1.5rem;
}
