@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';
/* Container Style */
.messageBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @include for-phone-only {
    z-index: $six;
    background: #fff;
    height: calc(100vh - 0.2rem);
    height: calc(100vh - 0.2rem - env(safe-area-inset-top));
    height: calc(100vh - 0.2rem - constant(safe-area-inset-top));
  }
}
  .messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 0;
  }
  
  .landingPage {
    justify-content: center;
    align-items: center;
  }
  
  .messageField {
    // margin-top: 1.3rem;
    // margin-bottom: 1rem;
    // margin-left: 1rem;
    // margin-right: 1rem;
  
    @include for-phone-only {
      margin: 0;
      padding: 0.8rem 0.5rem;
      box-sizing: border-box;
    }
  }
  
  .errorPlaceholder {
    position: relative;
  
    .threadError {
      position: absolute;
      left: 2.4rem;
      right: 2.4rem;
      top: 2.4rem;
      border-radius: .3rem;
      padding: 1.5rem 2.4rem;
      background-color: $orange;
      font-size: 1.2rem;
      color: $white;
      display: flex;
      flex-direction: row;
      z-index: 1;
  
      @include for-phone-only {
        margin: 0;
        top: 6.4rem;
      }
  
      .errorMessage {
        flex: 1 0;
      }
  
      .errorRetry {
        flex: 0 1;
  
        a {
          color: currentColor;
        }
      }
    }
  }
  .vertical {
    margin-left: -1px;
    height: 100%;
    border: solid 1px #e3e7ed;
  }
  .group {
    min-width: 31.5rem;
    max-width: 34.5rem;

    .groupHeader {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: solid 1px #e3e7ed;
      padding: 0 2rem;
      height: 7rem;

      h3 {
        color: #707070;
        font-family: Montserrat;
        font-size: 16px;
      }
    }

    .addPeople {
      align-items: center;
      display: flex;
      padding: 2.3rem 0 0 3.5rem;

      .addBox {
        color: #464ac9;
      }

      span {
        line-height: 1.6rem;
        font-size: 13px;
        margin-left: 10px;
        color: #707070;
        text-align: left;
      }
    }

    .peopleList {
      margin-left: 3rem;
      margin-top: 1.6rem;
      overflow: auto;
      height: 100%;
      
      .people {
        display: flex;
        align-items: center;
        margin-top: 1.3rem;

        span {
          color: #1d1e2b;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
        }
        .moreHorizIcon {
          display: none;
        }
      }

      .people:hover {
        .moreHorizIcon {
          display: flex;
          margin-left: auto;
        }
      }
    }
  }