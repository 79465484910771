@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.tab {
  padding: 0 0 1rem 0;
  margin-right: 3.2rem;
  border: none;
  bottom: -0.2rem;
  display: inline-block;
  cursor: pointer;

  @include for-phone-only {
    color: $slate;
    flex: 0 0 auto;
    padding: 0 0 0.5rem 0;
  }
}

.selectedTab {
  border-bottom: 3px solid $salmon;
  > .tabTitle {
    color: $salmon;
    background-color: $contrast;

    @include for-phone-only {
      color: $salmon;
      background-color: transparent;
    }
  }
}

.tabTitle {
  @include for-phone-only {
    color: $slate;
    background-color: transparent;
  }
}

.circle {
  border-radius: 50%;
  height: 15px;
  width: 15px; 
  display: inline-block;
  background: #dc5758;
  vertical-align: top;
  margin-left: 1rem;

  .number {
    display: block;
    color: #FFFFFF;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 1rem;
  }
}
