@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.headerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem 2.5rem 1rem 2.5rem;

  @include for-phone-only {
    padding: 0.8rem 1rem 1rem 1.5rem;
  }
}

.showBorder {
  border-bottom: $defaultBorderStyle;
}

.headerActions {
  display: flex;
  justify-content: space-between;
  height: 3rem;

  .backButton {
    margin-left: -1rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    @include for-phone-only {
      position: relative;
    }

    .tag {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }

    .menu {
      margin-left: 1rem;
    }
  }
}

.headerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -2.7rem 2rem 0 2rem;

  @include for-desktop-only {
    margin: -3rem 12rem 0 12rem;
  }

  .title {
    text-align: center;
  }
}