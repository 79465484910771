/*
Truncate text that overflows an element with an ellipsis
*/
.truncateText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media print {
    text-overflow: clip;
    white-space: pre-wrap;
    overflow: visible;
  }
}
