@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: 1400;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @include for-phone-only {
    -webkit-overflow-scrolling: touch;
    top: calc(0px + env(safe-area-inset-top));
    top: calc(0px + constant(safe-area-inset-top));
  }
}
.uploadFileModal{
    margin: 6rem auto;
    border-radius: 15px;
    outline: none;
    width: 48rem;
    height: 24.8rem;
    border: solid 1px #e3e7ed;
    background: $contrast;
    box-shadow: 0 4px 5px 0 rgba(109, 109, 109, 0.5);
    display: flex;
    flex-direction: column;
    @include for-phone-only {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 0;
      display: block;
      border-radius: 0;
      margin: 0;
      overflow: auto;
    }
  
    .uploadDiv{
      width: 46.9rem;
      height: 23.5rem;
      margin: 0.5rem;
      border: dashed 1px #e3e7ed;
      border-radius: 15px;
    }
  }
  
  .uploadingFileModal{
    margin: 10rem auto;
    border-radius: 15px;
    outline: none;
    width: 48rem;
    height: 10rem;
    border: solid 1px #e3e7ed;
    background: $contrast;
    box-shadow: 0 4px 5px 0 rgba(109, 109, 109, 0.5);
    display: flex;
    flex-direction: column;
    @include for-phone-only {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 0;
      display: block;
      border-radius: 0;
      margin: 0;
      overflow: auto;
    }
  }
  
  
  .file-drop {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .file-drop > .file-drop-target {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
  
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
  
    text-align: center;
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    border: none;
    background-color: rgba(180, 178, 178, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;
  
    /* typography */
    color: white;
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    color: #71adfc;
    box-shadow: 0 0 13px 3px #71adfc;
  }
  
  .uploading{
      width: 100%;
  }
@-moz-document url-prefix() {
  .uploading{
    margin-top: 6rem;
  }
}
.clearFileButton{
  z-index: 999;
  margin-top: -1rem;
  position: absolute;
  right: 17px;
}
  .uploadFileTitle{
    height: 18px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #6d6d77;
    margin-top: 2rem;
  }
  
  .dropFileHere {
    width: 361px;
    height: 18px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #6d6d77;
    margin-top: 1rem;
  }


.loadingBarOverlay {
    height: 18px;
    width: 90%;
    background-color: #e3e7ed;
    margin: 2rem;
    border-radius: 5px;
    background: rgba(0, 183, 165, 0.15);
  }
  
.loadingBar {
    border-radius: 5px;
    background-color: $success;
    height: 100%;
    max-width: 100%;
    min-width: 0%;
    float: left;
} 

.loadingError {
  background-color: $warning;
}

.fileName {
    color: white;
    float: left;
    margin: 0.4rem 0 0 1rem;
    font-size: 8px;
}

.uploadingPercentage{
    color: white;
    float: right;
    margin: 0.4rem 1rem 0 0;
    font-size: 8px;
}

.fileUoloadSuccess{
    margin-top: 3rem;
}

.fileUploadSuccessText{
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #6d6d77;
    margin-top: 0.5rem;
}

.clickButton{
    background: transparent;
    border: 0;
    outline: none;
    color: #518ef8;
    cursor: pointer;
}

.errorMessage{
  color: red;
}