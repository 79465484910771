@import 'components/atoms/colours.scss';

.link {
  display: flex;
  margin: 0 1.6rem;
  flex: 0 0 auto;
  a {
    color: inherit;
    text-decoration: none;
    flex-grow: 1;
    padding-bottom: 0.5rem;
    border-bottom: 0.2rem solid transparent;
    &.active {
      border-bottom: 0.2rem solid $callToAction;
      div {
        color: $callToAction;
      }
    }
  }
}