@import 'components/atoms/colours.scss';

.peopleItemContainer {
  display: flex;
  padding: 0.8rem;
  cursor: pointer;
}

.peopleItemContainer:hover {
  background-color: $background;
}

.peopleItemContainerSimple {
  height: 4rem;
  display: flex;
}

.selected {
  background-color: $background;
}

.avatarContainer {
  align-items: center;
}

.peopleItem {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-self: center;
  width: 100%;
}

.line1 {
  font-size: 1.2rem;
	color: $primary;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.line2 {
  font-size: 1.2rem;
	color: $slate;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.line3 {
  font-size: 1.2rem;
	color: $slate;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: auto;
}

.altColor {
  color: #a9abc0;
}