@import 'components/atoms/colours.scss';

.errorMessage {
    color: $orange;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
.flagReasonAdd {
  margin-bottom: 0;
}
.flagReasonAdd label{
  margin-bottom: 2.5rem;
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}
@media (max-width: 812px) {
  .flagReasonAdd label{
    margin-bottom: 1.6rem;
  }
  .flagReportModal{
    height: 40%;
    bottom: 0;
  }
  .closeButtonClass{
    top: 60%;
  }
  .mainContentClass {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
