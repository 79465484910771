@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.container {
  max-width: 64rem;
  position: relative;
  &:hover {
    background-color: white;
  }
}

.cardFooter {
  min-height: 2rem;
  padding-top: 1rem;
  border-top: 1px solid $chromium;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footerSpan {
  width: 100%;
}

.footerSecondary {
  flex-shrink: 0;
  button {
    margin-left: 16px;
    font-family: Montserrat;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    color: #464AC9;
    padding: 6px 16px;
    background-color: #fff;
    border: 1px solid #464AC9;
    border-radius: 20px;
    box-shadow: 0x 1px 2px rgba(70,74,201,0.2);
  }
}

.footerSecondaryText {
  color: #FF764C;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}

.text {
  display: inline-block;
  margin-bottom: 0.4rem;
}

.icon {
  margin-top: -2rem;
  height: 1.4rem;
  margin-left: 9rem;
}