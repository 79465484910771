@import "./base.scss";

.ds-button.MuiButtonBase-root {
    padding: 6px 16px;
    @include box-shadow();
    
    &:hover {
        @include box-shadow();
    }
    
    @include button-base();    
    border-radius: var(--input_border_radius);

    &.MuiButton-outlinedPrimary, &.MuiButton-textPrimary, &.MuiButton-containedPrimary {
        @include button-base('primary');
    }    

    &.MuiButton-outlinedSecondary, &.MuiButton-textSecondary, &.MuiButton-containedSecondary {
        @include button-base('secondary');
    }  

    // icon buttons
    &.MuiIconButton-root {
        padding: 6px;
        border: none;
        box-shadow: none;
        
        &:hover {
            @include box-shadow();
            svg {
                color: var(--icon-button_default_color);
            }
        }

        &.MuiIconButton-colorPrimary {            
            @include button-base('primary');
        }
    
        &.MuiIconButton-colorSecondary {
            @include button-base('secondary');
        }
    }       

    &.Mui-disabled {
        @include button-base("disabled");

        box-shadow: none;
    }        
}


