@import 'components/atoms/colours.scss';

.checkboxInputStyle {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2.4rem;
  text-align: center;
  display: flex;
  align-items: center;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.unchecked {
  color: $disabled;
}

.checked {
  color: $callToAction;
}