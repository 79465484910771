@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.eventSummary {
  margin: 1.6rem 0;
}

.eventSummaryCard {
  margin: 1.6rem 0;
  padding: 1.6rem 1.6rem;
  border: $defaultBorderStyle;
  border-radius: 2px;
  color: $secondary;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 0 1.6rem;
}

.row {
  display: flex;
  font-size: 1.2rem;

  @include for-phone-only {
    flex-direction: column;
  }
}

.item {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subsequentItem {
  composes: item;
  margin-top: 0.3rem;
}

.column {
  flex: 1;
}

.originallyScheduled {
  font-size: 1rem;
}

.toggle {
  padding: 0.4rem 0 0.8rem;
}

.toggleLink {
  color: $callToAction;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 1.6rem;
}

.reasonBox {
  color: $secondary;
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 1.6rem 0 0.2rem;
}

.reasonBoxText {
  font-size: 1.2rem;
  color: $primary;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 4.8rem;
  max-height: 15rem;
  border: $defaultBorderStyle;
  border-radius: 3px;
  padding: 1.6rem 1.6rem;
}

.adjustTime {
  margin-top: 1.6rem;
}

.changedTime {
  text-decoration: line-through;
}

.invalidReasonBox {
  textarea {
    border-color: $warning;

    &:focus {
      border-color: $warning;
    }
  }
}

.errorMessageContainer {
  margin: 0.5rem 0 0.8rem;
  height: 1.2rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.comments {
  margin-top: 1rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
}

.extraLink {
  color: $callToAction;
  text-decoration: underline;
  cursor: pointer;
}
.timeSelectors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeSelectors > * {
  margin-right: .8rem;
}

.timeSelectors > :last-child {
  margin-right: 0;
}

.sessionAlert {
  display: flex;
  align-items: center;
  border: 1px solid #f3f6fa;
  background-color: #f3f6fa;
  padding: 5px;
  margin-top: 20px;
  border-radius: 3px;
}

.accessTimeIcon {
  color: #73747d;
}

.desc {
  font-size: 12px;
  font-weight: 500;
  color: #1d1e2a;
}

.deductHour {
  font-size: 16px;
  font-weight: 600;
  color: #ff764c;
  margin: 0 15px 0 auto;
}

.remindMessage{
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #ed4b53;
}
