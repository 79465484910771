@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.iconButtonWrapper {
  height: 3.7rem;
  width: 3.6rem;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  border: none;

  @include for-desktop-only {
    &:hover {
      background-color: $backgroundHover;
    }

    &:active {
      background-color: $backgroundActive;
    }
  }
}

.iconButton {
  color: inherit;
}