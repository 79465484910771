:root {
    // ### inputs ###    
    --input_border_width: 2px;
    --input_border_radius: 2.5rem;
    --input_font-size-small: 11px;
    --input_font-size-medium: 14px;
    --input_font-size-large: 17px;
    --input_label_color: var(--color_dark-navy);    
    
    // # primary
    --input_primary_color: var(--color_white);
    --input_primary_color_bkg:var(--color_indigo);
    --input_primary_color_bkg_hover:var(--color_dark-indigo);
    --input_primary_color_border: var(--color_indigo);     
    
    // # secondary
    --input_secondary_color: var(--color_indigo);
    --input_secondary_color_bkg: var(--color_white);  
    --input_secondary_color_bkg_hover: var(--color_white); 
    --input_secondary_color_border: var(--color_indigo);    
    
    // # default
    --input_default_color: var(--color_stone);
    --input_default_color_bkg: var(--color_white);
    --input_default_color_bkg_hover: var(--color_white);
    --input_default_color_border: var(--color_stone);   

    // # disabled
    --input_disabled_color: var(--color_stone);
    --input_disabled_color_bkg: var(--color_mist);
    --input_disabled_color_bkg_hover: var(--color_mist);
    --input_disabled_color_border: transparent;   

    // ## selects
    --select-list-item_color_bkg: var(--color_white);
    --select-list-item_color_bkg_hover: var(--color_mist);
    --select-list-item_color_border: var(--color_pale-grey);
    --select-list-item_color_border_hover: var(--color_indigo);
    --select-button_color: var(--color_dark-navy);
    --select-button_color-empty: var(--color_stone);
    --select-list-item_selected_color_bkg: var(--color_pale-grey);
    --select-list-item_selected_color_border_hover: var(--color_dark-indigo);
    // ## checkboxes
    // # primary    
    --checkbox_primary_color: var(--color_indigo);
    --checkbox_primary_color_bkg_hover: var(--color_translucent-indigo);
    // # secondary   
    --checkbox_secondary_color: var(--color_indigo);
    --checkbox_secondary_color_bkg_hover: var(--color_translucent-indigo);
    // # default 
    --checkbox_default_color: var(--color_stone);
    --checkbox_default_color_bkg_hover: var(--color_translucent-indigo);
    // # disabled
    --checkbox_disabled_color: var(--color_cool-grey);
    --checkbox_disabled_color_bkg_hover: transparent;

    // ## text-box
    --text-box_color: var(--color_pale-grey);
    --text-box_border_color: var(--color_pale-grey);
    --text-box_border_radius: 4px;

    // ## icon-button
    --icon-button_default_color: var(--input_primary_color_bkg);
}
