@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.confirm {
  color: $primary;
  font-size: 1.2rem;
  margin: 1.6rem 0 0;
}

.incorrectLink {
  color: $callToAction;
  margin-right: 2.4rem;
  text-decoration: underline;
  cursor: pointer;
}

.buttons {
  text-align: right;
}

.deductionConfirm {
  display: flex;
  align-items: center;
  margin: 2rem 0 4rem 0;
  cursor: pointer;
}

.confirmationMessage {
  margin: 0.2rem 0 0 1rem;
  color: $slate;

  &.isUnderstood {
    color: $primary;
  }
}
