.accordionSummary {
  margin: 0 1.6rem;
  flex: 1;
  display: flex;
  align-items: center;
}

.results {
  margin-left: 1rem;
}

.actions {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}