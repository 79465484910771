@import 'components/atoms/colours.scss';

.flag {
  color: $cheddar;
}

.error {
  color: $salmon;
}

.dot {
  color: $leaf;
}

.clock {
  color: $denim;
}

.check {
  color: $forest;
}

.notInterested {
  color: $tomatoRed;
}