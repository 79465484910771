@import 'components/atoms/colours.scss';

.summary {
  position: relative;
  color: $primary;
  text-align: left;
}

.toggleButton {
  position: absolute;
  top: -3.4rem;
  left: 13rem;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: .7rem;
  text-align: center;
}

.saveButton {
  composes: warning from "styles/buttons.scss";
  font-size: 1.4rem;
  margin-left: 1.5rem;
}

.cancelButton {
  composes: primaryStroke from "styles/buttons.scss";
  font-size: 1.4rem;
  margin-left: 1.5rem;
}

.disabled {
  composes: disabled from "styles/buttons.scss";
}
