@import 'components/atoms/colours.scss';

.timezone{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-bottom: solid 4px #e3e7ed;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 3rem;
  text-align: center;
  color: #6d6d77;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.hourIndicator {
  width: 6rem;
  flex-shrink: 0;
  height: 7rem;
  text-align: center;
  color: $slate;
  margin: 0 0.3rem;
  box-sizing: border-box;
}

.times {
  display: flex;
  flex-direction: column;
}
