@import 'components/atoms/colours.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.extraPadding {
  padding: 3.2rem;
  width: 85%;
  margin: auto;
}

.tables {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.newButton {
  margin-bottom: 1.6rem;
}

.outlineButton {
  border: 1px solid $callToAction;
  background-color: white !important;
  margin-bottom: 1.6rem;
}
.buttonText {
  color: $callToAction;
}