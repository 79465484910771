@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.mySubjects {
  text-align: left;

  @include for-phone-only {
    background: $background;
    min-height: calc(100vh - 5.2rem);
  }
}
