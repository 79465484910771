@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/typography/fontColour.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.datepickerContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
}

.label {
  margin-bottom: 0.4rem;
}

.error {
  input {
    border-color: $warning;
  }
}

.simpleInputStyle {
  width: 9rem;
  height: 4rem;
  border: none !important;
  text-align: right;
  composes: body from "components/atoms/typography/font.scss";
  composes: regular from "components/atoms/typography/fontFamily.scss";

  &:focus,
  &.active {
    border: none !important;
  }
}

.flatpickrInput {
  border: $defaultInputBorderStyle;
  width: 100%;
  height: 5rem;
  padding: 1.3rem 1.6rem;
  color: $primary;
  box-sizing: border-box;
  composes: body from "components/atoms/typography/font.scss";
  composes: regular from "components/atoms/typography/fontFamily.scss";

  &:focus {
    border: $activeInputBorderStyle  !important;
  }
}

.calendarIcon {
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;

  @include for-phone-only {
    display: none;
  }
}

.day {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &:hover {
    color: $contrast;
    background: $callToActionHover;
  }
}

.isViewOnly {
  pointer-events: none;
  cursor: not-allowed;
}

.inputIsViewOnly {
  background: $background;
}

/*
  Overrides flatpickr default styles
 */
:global {
  .flatpickr-wrapper {
    width: 100%;
  }

  .flatpickr-input {
    background-color: $contrast;
    color: $primary;
    border-radius: 0.2rem;
    -webkit-appearance: none;

    &.active {
      border: $activeInputBorderStyle;
    }

    @include for-phone-only {
      height: 4rem;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      border: $defaultInputBorderStyle;
      font-size: 1rem;
      font-family: inherit;
    }
  }

  .flatpickr-rContainer {
    width: 100%;
  }

  .flatpickr-calendar {
    max-width: 26.2rem;
    border-radius: 0.2rem;
    border: $activeInputBorderStyle  !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
    background: $contrast;

    * {
      box-sizing: border-box;
    }

    &.static {
      top: calc(100% + 1rem);
    }

    .dayContainer {
      width: 100%;
      min-width: 25.2rem;
      margin: 0 0.3rem;
      background: $background;
    }

    &.arrowTop,
    &.arrowBottom {
      &:before {
        bottom: 100%;
        top: auto;
        border-width: 1rem;
        margin: 0 -1rem;
        border-color: transparent !important;
        border-bottom-color: $primary  !important;
      }

      &:after {
        bottom: 100%;
        top: auto;
        border-width: 0.9rem;
        margin: 0 -0.9rem;
        border-color: transparent !important;
        border-bottom-color: $contrast  !important;
      }
    }

    .flatpickr-month {
      margin-top: 0.7rem;

      svg {
        fill: $primary  !important;
      }
    }

    .flatpickr-current-month,
    .cur-month {
      font-size: 1.2rem;
      font-weight: 400 !important;
      color: $primary  !important;
    }

    .flatpickr-weekday {
      font-size: 1.2rem;
      font-weight: 400;
      color: $secondary;
    }

    .flatpickr-days {
      font-size: 1.2rem;
      width: 100%;
      max-width: 26.2rem;
    }

    .flatpickr-day {
      max-width: 2.8rem;
      height: 2.8rem;
      margin: 0.4rem;
      line-height: 2.8rem !important;
      color: $primary;
      border-radius: 50%;
      border: none;

      &.today {
        background: transparent;
        color: $callToActionHover  !important;
        border: 1px solid $callToActionHover;

        &.selected {
          color: $contrast  !important;
        }
      }

      &:hover,
      &:focus,
      &.selected,
      &.startRange,
      &.endRange,
      &.selected.inRange,
      &.startRange.inRange,
      &.endRange.inRange,
      &.selected:focus,
      &.startRange:focus,
      &.endRange:focus,
      &.selected:hover,
      &.startRange:hover,
      &.endRange:hover,
      &.selected.prevMonthDay,
      &.startRange.prevMonthDay,
      &.endRange.prevMonthDay,
      &.selected.nextMonthDay,
      &.startRange.nextMonthDay,
      &.endRange.nextMonthDay {
        background: $callToActionHover  !important;
        color: $contrast  !important;
      }
    }
  }
}