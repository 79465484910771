@import 'components/atoms/colours.scss';

.highlighted {
  color: #ff764c;
  background: none;
}

.dropdownIndicator {
  margin-right: 4px;
  cursor: pointer;
}

.clearIndicator {
  cursor: pointer;
  background: $contrast;
  border-radius: 3px;
  margin-right: 4px;
  &:hover {
    background: $backgroundHover;
  }
}
