@import 'components/atoms/spacing.scss';
@import 'components/atoms/colours.scss';

.switchAppPopover {
  display: grid;
  grid-template-rows: 1fr;

  .appLink {
    display: flex;
    align-items: center;
    padding: $spacing-03 $spacing-04;
    cursor: pointer;
    font-weight: 600;
    font-size: 10px;
    &:hover {
      background: $background;
    }

    & > *:first-child {
      margin-right: 1rem;
    }
  }
}
