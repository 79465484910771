@import 'components/atoms/colours.scss';
@import 'components/atoms/typography/fontFamily.scss';

.details {
  flex: 1;
  display: flex;
  align-items: center;
}

.initials {
  flex: 0 0;
  font-size: 1.4rem;
  width: 3.4rem;
  height: 3.4rem;
  min-width: 3.4rem;
  min-height: 3.4rem;
}

.column {
  flex: 1;
  margin: 0 1rem;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $slate;
  margin-top: 0.3rem;
}

.userDetails {
  color: #CBCDD9
}