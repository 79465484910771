@import 'components/atoms/colours.scss';

.profile {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2.2rem 2.4rem;
}

.dashboardAvatar {
  width: 10rem;
  height: 10rem;
  margin-right: 1.2rem;
}
