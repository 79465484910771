@import "./base.scss";
@import "../typography.scss";

@function get-clear-all-icon($color) {
    $part: "%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22#{$color}%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E";
    @return url('data:image/svg+xml,#{$part}');
}


.ds-chip.MuiChip-root {    
    @include input-base();
    @include set-typo-for-body-02();  
    
    text-transform: capitalize;
    position: relative;
    
    &.MuiChip-clickable.MuiChip-colorPrimary:focus , &.MuiChip-colorPrimary {
        @include input-base("primary");
    }

    &.MuiChip-clickable.MuiChip-colorSecondary:focus , &.MuiChip-colorSecondary {
        @include input-base("secondary");
    }

    &.Mui-disabled {
        @include input-base("disabled");
    }     

    &:hover{
        border-color: var(--input_secondary_color_border);
    }

    &.MuiChip-deletable, &.deletable, &.delete {
        .MuiChip-deleteIcon {
            display: none;
        }

        &::before {
            content: '';
            top: 0;      
            background-color: var(--input_primary_color_bkg);
            background-image: get-clear-all-icon("white");
            background-repeat: no-repeat;
            background-position: center;
            color: var(--bkg_secondary-color);
            width: 100%;
            height: 0px;
            display: block;
            position: absolute;
            border-radius: var(--input_border_radius);
            transition: opacity 0.4s;
            opacity: 0;
            z-index: 1;    
        }
    
        &:hover{
            border-color: var(--input_secondary_color_border);            
            &::before {
                height: 100%;
                opacity: 0.9;
                transition: opacity 0.4s;
            }
        }
    }    
}