.card {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #FFF;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 30rem;
  height: 30rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

a {
  text-decoration: none;
}