@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.wrapper {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.line {
  flex: 1;
  margin-top: 0.6rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-top: $defaultBorderStyle;
}

.text {
  color: $stone;
  margin-right: 1rem;
  margin-left: 1rem;
}
