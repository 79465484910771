@import 'components/atoms/colours.scss';

.tutor {
  display: grid;
  grid-gap: 0.1rem 0.1rem;
  background-color: $border;
  grid-template-rows: auto 1fr;
  align-content: start;
}

.header {
  display: grid;
  grid-template-areas: "name name add" "rating sessions empty" "availability availability match";
  grid-template-areas: "top" "middle" "bottom";
  grid-template-rows: 2.8rem 2rem 2rem;
  grid-gap: 0.4rem;
  background-color: white;
  padding: 1.6rem;
}

.name {
  grid-area: top;
  /* All 3 of these (text-overflow, white-space and overflow) are needed to trigger the ellipsis */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* This avoids the name text from overlapping the icon in the top right corner */
  padding-right: 2rem;
  text-decoration: none;
}

.tutorAction {
  grid-area: top;
  color: $salmon;
  margin-left: auto; /* push it full right */
  svg {
    font-size: 1.8rem;
  }
  button {
    padding-top: 0.5rem !important; /* center text until iconbutton molecule is available */
  }
}

.rating {
  grid-area: middle;
  display: grid;
  grid-template-columns: 7rem 1fr;
}

.sessions {
  grid-area: middle;
}

.availability {
  grid-area: bottom;
}

.match {
  grid-area: bottom;
  margin-left: auto; /* push it full right */
  width: 10rem;
}

.main {
  background-color: white;
  padding: 1.6rem;
  display: grid;
  align-content: start;
  grid-gap: 2rem 1.2rem;
  grid-template-columns: auto 1fr;
  grid-template-areas: "icon text";
  color: $primary;
  svg {
    fill: currentColor;
    line-height: 1.5rem;
    font-size: 1.5rem;
    margin: 0.15rem 0rem;
  }
}

.safariIconFix {
  width: 1em;
  height: 1em;
}

.ratingcontainer {
  padding-top: 10px;
  max-width: 560px;
}

.preferredTimes {
  padding-left: 1rem;
  li {
    list-style-type: circle;
  }
}