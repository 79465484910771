@import 'components/atoms/spacing.scss';
@import 'components/atoms/colours.scss';

.profile {
  padding-left: 1.2rem;
}

.profilePopover {
  display: grid;
  grid-template-rows: 1fr;

  .profileLink {
    display: flex;
    align-items: center;
    color: $primary;
    padding: $spacing-03;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 600;
    font-size: 10px;
    &:hover {
      background: $background;
    }

    & > *:first-child {
      margin-right: 1rem;
      width: 1.8rem;
    }
  }
}
.avatarLink {
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
