@import 'components/atoms/colours.scss';
@import '../styles.scss';

.checkboxInputStyle {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.5rem;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  color: $inputBorder;
  &.checked {
    color: $callToAction;
  }
}

.checkboxDisabled {
  cursor: not-allowed;
  color: $disabledText;
  i,
  label {
    color: $disabledText;
    pointer-events: none;
  }
}

.checkboxLabel {
  cursor: pointer;
}
