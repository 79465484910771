@import 'components/atoms/colours.scss';

.info {
  text-align: start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  font-size: 1.2rem;
  font-weight: bold;
  color: $secondary;
  word-break: break-word;
  p {
    margin: 0;
    ~ p {
      margin-top: 1.4rem;
    }
    s {
      margin: 0;
    }
  }
}
