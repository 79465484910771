@import 'components/atoms/borders.scss';

.horizontalLine {
  border-bottom: $defaultBorderStyle;
}

.container {
  display: flex;
  padding: 3rem;
}
