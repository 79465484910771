@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.heading {
  display: flex;
  padding-left: 0.5rem;
  align-items: center;
  cursor: pointer;
  color: $primary;
  transition: background 0.3s;
  &:hover {
    background-color: $background;
  }
}

.title {
  font-size: 1.4rem;
  line-height: 4rem;
  margin-left: 1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.message{
  margin-left: 1rem;
  margin-top: 0.2rem;
  color: $secondary;
}

.arrow {
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: transform 0.1s cubic-bezier(0.2,0.0,0.5,1) !important;
}

.disabled {
  pointer-events: none;
  span {
    color: $disabledText;
  }
  svg {
    fill: $disabledText;
  }
}

.openArrow {
  transform: rotate(0deg);
}

.content {
  padding: 1rem 4rem;
  @include for-phone-only {
    padding: 0 0.5rem;
  }
}
