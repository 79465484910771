@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

@media print {
  svg.crimsonLogo {
    display: block;
  }
}

.invoiceWrapper {
  position: relative;
}

.headerBar {
  display: flex;
  flex-direction: horizontal;
  min-height: 6.4rem;
  align-items: center;
  padding: 0 2.2rem;
  border-bottom: $defaultBorderStyle;
  background: $contrast;

  @include for-phone-only {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    & > * {
      width: 100%;
      border-top: $defaultBorderStyle;
      padding: 1rem 1.5rem;
      flex: 1;
      box-sizing: border-box;
    }

    & > *:first-child {
      border: none;
    }
  }

  @include for-desktop-only {
    .headerBox:nth-child(1) {
      flex: 1;
      flex-direction: row;
    }
    .headerBox:nth-child(2) {
      & > *:first-child {
        margin-left: auto;
        margin-right: 0.2rem;
      }
      flex: 0 1 auto;
    }
  }

  @media print {
    display: none;
  }
}

.headerBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include for-phone-only {
    padding: 1rem 1.2rem 1rem 0.4rem;
    & > *:last-child {
      justify-self: flex-end;
    }
  }

  .invoiceHeaderDetails {
    display: flex;
  }
}

.backButtonLink {
  margin-bottom: 0;
}

.headerBarProfileView {
  @include for-phone-only {
    top: 3.4rem;
  }
}

.headerActions {
  display: flex;
  flex: 1;
  justify-content: space-between;

  .actions {
    display: flex;
  }
}

.extraActions {
  display: flex;

  & > *:last-child {
    margin-left: 1rem;
  }
}

.content {
  padding: 2.2rem;

  @extend %scrollbar;

  @include for-desktop-only {
    flex: 1;
    overflow-y: auto;
  }

  @include for-phone-only {
    // padding: 2rem 1.5rem;
    padding: 2rem 0;
  }
}

.contentProfileView {
  @include for-phone-only {
    padding: 8.6rem 2.2rem 9.4rem;
  }
}

.scrollableContentArea {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.emptyContainer {
  display: flex;
  align-items: center;
  margin-top: 14.5rem;
}

.emptyTitle {
  text-align: center;
  margin: 1.6rem 0;
}

.emptyText {
  text-align: center;
  margin: 0rem 6rem 10rem;
}

.lessonStatus {
  display: flex;
  align-items: center;
}

.iconDone {
  width: 2rem;
  font-size: 1.5rem;
  color: $success;
}

.iconIncomplete {
  composes: iconDone;
  color: $warning;
}

.invoiceStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-desktop-only {
    align-items: center;
  }

  & > *:last-child {
    margin-top: 0.3rem;
  }
}

.totalPay {
  border: none;
}

.loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.crimsonLogo {
  display: none;
  fill: $primary;
  width: 5rem;
  margin: 0 0 5rem 0.8rem;
}