@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.messageWindow {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;

  @include for-phone-only {
    margin-top: 5rem;
  }
}

.messages {
  overflow: auto;
  padding: 0 2rem 0.5rem;
  min-height: 5rem;

  @extend %scrollbar;
  @include for-phone-only {
    padding: 5.5rem 2rem;
  }
}

.typingIndicator {
  margin: 1rem 0;
  font-style: italic;
  color: $stone;
}

.progressWrapper {
  left: 0;
  right: 0;
  padding: 2.6rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.progress {
  color: $salmon !important;
}

.progress > svg > circle {
  stroke-width: .2rem;
}

.hidden {
  display: none;
}
