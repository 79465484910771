@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import '../styles.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.field {
  position: relative;
  display: flex;
  border-radius: 0.2rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include for-phone-only {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    border-top: solid 1px $chromium;
  }
}

.actionIconContainer {
  flex-direction: column;
  flex-shrink: 1;
}

.inputContainer {
  flex-grow: 1;
  margin-left: 2.5rem;
  margin-right: 3rem;

  @include for-phone-only {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.messageInputField {
  color: $primary;
  width: 100%;
  padding: 0.9rem 2.8rem 0.9rem 1.6rem;
  min-height: 4rem;
  -webkit-appearance: none;

  @include for-phone-only {
    padding: 0.8rem;
    min-height: 3rem;
  }

  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.2rem;
  line-height: 1.6;
  resize: none;
  border: none;
  max-height: 10rem;
  min-height: 9rem;
  border: $defaultInputBorderStyle;
  border-radius: 0.3rem;

  &::placeholder {
    color: $hint;
  }

  &:focus, &:not(:empty) {
    border: $activeInputBorderStyle;

    &::placeholder {
      color: transparent;
    }
  }
}

.arrow {
  border: none;
  border-left: $defaultInputBorderStyle;
  padding: 0 1.2rem;
  background-color: $contrast;
  cursor: pointer;

  .sendIcon {
    font-size: 1.5rem;
    color: $hint;

    &.focus {
      color: $primary;
    }
  }
}

.emojiButton {
  @include for-phone-only {
    display: none;
    bottom: calc(50% - 1.7rem);
    right: 4.2rem;
  }
  bottom: calc(50% - 1.7rem);
  float: left;
}

.filePicker{
  display: flex;
  float: left;
}

.hintMessage {
  float: right;
  color: #babcc5;
}