@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/borders.scss';

.scrollableContainer {
  @include for-desktop-only {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    min-height: 100vh;
  }
  
}

.invoicingPageWrapper {
  @include for-desktop-only {
    display: grid;
    grid-template-columns: minmax(40rem, 1fr) 3fr;
    overflow-y: auto;
  }

  & > :first-child {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;

    @include for-desktop-only {
      max-width: 40rem;
      border-right: $defaultBorderStyle;
      overflow-y: auto;
    }

    @include for-phone-only {
      flex: 1;
    }

    @media print {
      display: none;
    }
  }

  & > :last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.deleteConfirmationText {
  @include for-phone-only {
    text-align: center;
    padding: 3.8rem 0rem;
  }
}

.invoicingHeader {
  @media print {
    display: none;
  }
}