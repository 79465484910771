@import "./base.scss";

.ds-textbox.MuiFormControl-root {
    @extend .typography;

    .MuiFormLabel-root {        
        @include textbox-alike-label();
    }
    
    .MuiInputBase-root {        
        input {
            @include textbox-alike();
        }
    }
}