@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import '../styles.scss';

$inputHeight: 5rem;

.selectStyle {
  height: $inputHeight;
  width: 100%;
  color: inherit;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: normal;
  padding: 0 .8rem;
  border: $defaultInputBorderStyle;
  border-radius: 0.2rem;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  &:focus{
	  border: $activeInputBorderStyle;
  }

  &:disabled {
    cursor: not-allowed;
    color: $disabled;
    border-color: $border;
    background-color: $background;
  }
}

.dropdownIcon {
  fill: $primary;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  right: .8rem;
  transform: translate(0, -1rem);
  pointer-events: none;
}
