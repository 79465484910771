@import 'components/atoms/colours.scss';

.hourIndicator {
  width: 3rem;
  flex-shrink: 0;
  height: 6rem;
  text-align: right;
  color: $slate;
  margin: 0 0.3rem;
  box-sizing: border-box;
  font-size: 10px;
}

.times {
  display: flex;
  flex-direction: column;
}
