@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 6rem;
  width: 23rem;
  padding: 1.2rem 1.6rem 0.8rem 1rem;
  justify-content: space-between;
  border: solid 1px #e3e7ed;
  border-radius: 6px;
  box-sizing: border-box;
  margin-right: 12rem;
  float: left;
  cursor:pointer;

  @include for-phone-only {
    margin-right: 4rem;
  }
}
.info{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    height: auto;
    margin-left: 0.5rem;
}
.details {
  float: left;
  justify-content: center;
  align-items: flex-start;
  font-size: medium;
  height: auto;
  margin-top: -0.5rem;
  @include for-phone-only {
    font-size: small;
  }

  .fileName{
    width: 170px;
    height: 18px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2b2b34;
  }

  .subInfo{
    width: 96px;
    height: 16px;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #6d6d77;
   
  }

  .oval {
    width: 2px;
    height: 2px;
    display: inline-block;
    background-color: #b7b7bc;
    margin: 0 0.5rem 0.2rem 0.5rem;
  }
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 2rem;
  width: 2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.fileIcon { 
  margin: 0px 0.5em 0.5em;
}