@import '../colours.scss';

.primary {
  color: $primary;
}

.crimson {
  color: $salmon;
}

.secondary {
  color: $secondary;
}

.callToAction {
  color: $callToAction;
}

.warning {
  color: $warning;
}

.success {
  color: $success;
}

.hint {
  color: $hint;
}

.contrast {
  color: $contrast;
}

.disabled {
  color: $disabled;
}