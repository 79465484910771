@import "components/atoms/colours.scss";
@import "components/atoms/borders.scss";

.filters {
  display: flex;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}

.filter {
  display: flex;
  flex-direction: column;
  color: $primary;
  width: 33.3%;
  padding-right: 1.6rem;
}

.filterInput {
  height: 4.2rem;
  border: $defaultInputBorderStyle;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-family: unset;
  &:focus {
    border: $activeInputBorderStyle;
  }
}

.selectWrapper {
  position: relative;
}

.selectInput {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.dropdownIcon {
  fill: $primary;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 50%;
  right: .5em;
  transform: translate(0, -40%);
}

.multiSelect {
  div[class^='dropdown_'] {
    border-radius: 4px;
    padding: 8px;
    height: 4.2rem;  
  }
}

.radioButtons {
  width: 100%;
  height: 4.2rem;
  display: flex;
  align-items: center;
  .radio {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
    input[type='radio'] {
      -webkit-appearance:none;
      width:20px;
      height:20px;
      border:1px solid darkgray;
      border-radius:50%;
      outline:none;
      margin-right: 10px;
    }
    input[type='radio']:hover {
      cursor:pointer;
    }
    input[type='radio']:before {
        content:'';
        display:block;
        width:12px;
        height:12px;
        margin: 3px 4px 4px 3px;
        border-radius:50%;
    }
    input[type='radio']:checked {
        border:1px solid red;
    }
    input[type='radio']:checked:before {
        background:red;
    }
  }
}
