@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.sessionSummaryReport {
  max-width: 90rem;
  margin: 0.8rem auto 0;
  box-sizing: border-box;
  padding: 2rem;

  &:hover {
    background-color: white;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    flex-direction: column;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    color: $primary;
    margin: 0;
  }

  span {
    color: $stone;
    font-size: 1.2rem;

    @include for-phone-only {
      font-size: 1rem;
    }
  }
}
