@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/layers.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.calendarList {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100vh - 14rem);
}

.calendarInfo {
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1rem;
}
.calendarGrid{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 40rem;
  min-width: 18rem;
  justify-content: flex-start;
  height: 100%;
  @extend %scrollbar-transparent;
}
.wholeGrid{
  overflow-y: scroll;
  display: flex;
  margin-right: 1rem;
}
.weekGrid {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 40%;
  max-width: 40rem;
  justify-content: flex-start;
  height: 100%;
  @extend %scrollbar-transparent;
}

.columnWidth {
  width: 100%;
}

.today {
  color: $sky;
  border-top: none;
}

.pastDate {
  color: $stone;
}


.showArrow {
  opacity: 1;
}


// WeekHeader styles

.dayHeaderRow{
  padding: 1.2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @include for-phone-only {
    padding: 0.8rem 0;
  }

  .data {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    
    .date{
      display: flex;
      flex-direction: column;
      margin-left: 7rem;

      span {
        font-family: Montserrat;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
      }
      .number {
        font-size: 36px;
        font-weight: bold;
      }
      .week {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.23;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;
      margin-left: auto;

      .name {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1d1e2b;

        @media screen and (max-width: 1200px){
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 8rem;
        }
      }
      .name:hover{
        overflow: visible;
      }

     
      .role {
        font-family: Montserrat;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #a9acc0;
        @media screen and (max-width: 1200px){
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 8rem;
        }
      }

      .role:hover{
        overflow: visible;
      }
      a{
        margin-left: auto;
      }
    }
  }
}

.timezoneHeader {
  border-bottom: solid 4px #e4e7ec;
  border-right: solid 1px #e4e7ec;
  display: flex;
  // padding-right: 0.5rem;
  height: 3rem;

  .timezone {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #a9acc0;
    width: 6rem;
    text-align: center;
    align-self: center;
    flex-shrink: 0;
    margin: 0 0.3rem;
  }
  .vertical {
    border-left: solid 1px #e4e7ec;
    height: 3rem;
    flex-shrink: 0;
    box-sizing: border-box;
  }
}

.profilePic {
  text-decoration: none;
}