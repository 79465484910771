@import 'components/atoms/colours.scss';

.descriptionText {
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
	font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $secondary;
  margin-top: 0.2rem;
}

.errorState {
  color: $orange;
  svg {
    width: 12px;
    height: 12px;
    margin-bottom: -.2rem;
    margin-right: .8rem;
  }
}

.image{
  float: left;
  margin-right: 0.5rem;
  svg {
    height: 2rem;
    width: 2rem;
  }
}

.message{
  padding: 0.5rem;
}