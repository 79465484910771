.postFormInput {
  line-height: 1.6;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.6rem 0 2rem 0;
  text-align: center;
}

.selectedFile {
  min-width: 40rem;
}

.saveButton {
  composes: warning from "styles/buttons.scss";
  width: 10.3rem;
  margin-left: 0.8rem;
}

.addFileButton {
  composes: primaryStroke from "styles/buttons.scss";
  width: 10.3rem;
  margin-left: 0.8rem;
}

.disabled {
  composes: disabled from "styles/buttons.scss";
  width: 10.3rem;
  margin-left: 0.8rem;
}

.uploader{
  display: none;
}

.deleteFileModal {
  width: 60rem;
}
