@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/generic/Forms/InputField/styles.scss';
@import 'components/unique/ProfilePage/profileItem.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.buttonBlueCss{
  // background-color: #464ac9 !important;
  border-radius: 50px;
  width: 14.8rem;
}

.buttonGrayCss{
  background-color: #e3e7ed !important;
  border-radius: 50px;
  width: 14.8rem;
}
.buttonTextWhite{
  color: white;
}
.buttonTextGray{
  color: #707070;
}
.headerStyle {
  border-bottom: none;
  margin-top: 20px;

  div {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #1d1e2b;

    @include for-phone-only {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
  
}

.customButtonGroup {
  align-self: center;
}

.chatFormInput {
  padding: 0;
  flex: 1;

  @include for-phone-only {
    flex: 0 0 auto;
    overflow: auto;
    max-height: 10rem;
  }
}