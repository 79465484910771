@import 'components/atoms/colours.scss';

.callQuality {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  border-bottom: 0.2rem solid $chromium;

  .controls {
    display: flex;
    flex-direction: row;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
      width: 7.2rem;
      height: 7.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      transition: transform 200ms;
      width: 3.7rem;
      height: 3.7rem;
      fill: $stone;

      &.positive {
        fill: $tiffany;
      }

      &.negative {
        fill: $orange;
      }
    }

    .icon:hover {
      transform: scale(1.3);
    }
  }
}
