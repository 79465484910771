@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.radios {
  font-size: 1.2rem;
  color: $primary;
  margin-top: 2.4rem;

  input[type=radio] {
    margin: 0.2rem 0 0 0;
  }

  .reason {
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
  }
}

.commentBox {
  margin: 2.4rem 0 0;

  label {
    color: $secondary;
    font-size: 1rem;
  }

  textarea {
    border-radius: 3px;
    min-height: 4.8rem;
    border: $defaultBorderStyle;
  }
}

.commentInvalid {
  textarea {
    border-color: $warning;

    &:focus {
      border-color: $warning;
    }
  }
}

.errorMessageContainer {
  margin: 0.5rem 0 0.8rem;
  height: 1.2rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}