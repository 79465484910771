@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.peopleList {
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 18rem);

  @extend %scrollbar-transparent;

  @include for-phone-only {
    max-height: 40rem;
  }
}

.noneAvailable {
  margin-top: 1rem;
}