@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/scrollbar.scss';

.invoiceList {
  padding: 2rem;

  @extend %scrollbar;

  @include for-phone-only {
    background: $cloud;
    padding: 0;
  }
}

.emptySearchContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.emptyGraphic {
  width: 20rem;
  margin: 14rem 0 2rem 0;
}

.emptyResultMessage {
  color: $slate;
}

.loadingIndicator {
  height: auto;
}