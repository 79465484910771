
@mixin set-typography($weight, $size, $lineHeight, $spacing) {
    font-weight: $weight;    
    font-size: $size;
    line-height: $lineHeight;
    letter-spacing: $spacing;
}

@mixin fixTypographyForRoot($selectors) {
    @each $selector in $selectors {
        .Mui#{$selector}-root {
            @extend .typography;
        }
    }
}


@mixin set-typo-for-header-01() {
    @include set-typography(800 bold, 36pt, 44pt, 0);
}

@mixin set-typo-for-header-02()  {
    @include set-typography(800 bold, 24pt, 29pt, 0);
}

@mixin set-typo-for-header-03()  {
    @include set-typography(500, 24pt, 29pt, 0);
}

@mixin set-typo-for-body-01()  {
    @include set-typography(500, 16pt, 24pt, 0);        
}

@mixin set-typo-for-body-02()  {
    @include set-typography(500, 14pt, 20pt, 0);
}

@mixin set-typo-for-bread-crumb()  {
    @include set-typography(500, 12pt, 15pt, 0);        
}

@mixin set-typo-for-caption()  {
    @include set-typography(500, 12pt, 15pt, 50pt);       
    text-transform: capitalize; 
}

:root {
    --font-family: 'Montserrat', sans-serif;
}

.typography {
    font-family: var(--font-family);

    h1, &h1, .header-01 {
        @include set-typo-for-header-01();
    }
    
    h2, &h2, .header-02 {
        @include set-typo-for-header-02();
    }
    
    h3, &h3, .header-03 {
        @include set-typo-for-header-03();
    }

    .body-01 {
        @include set-typo-for-body-01();
    }

    .body-02 {
        @include set-typo-for-body-02();
    }

    .bread-crumb {
        @include set-typo-for-bread-crumb();
    }

    .caption {
        @include set-typo-for-caption();       
    }
}
