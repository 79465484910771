@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.menuItem {
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  color: $primary;
  font-family: inherit;
}

.highlighted {
  background: $background;
  color: $secondary;
}

.roadmapCategoryLabelContainer {
  padding: 0.5rem 1rem;
}

.toggleContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem 0 0;
}

.modalOverlay {
  align-items: baseline;
}

.modalContent {
  margin-top: 30vh;
  @include for-phone-only {
    margin-top: 0;
  }
}

.inputLabel {
  margin-bottom: 0.4rem;
}