@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.inviteTutorContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.headerMobileFix{
  > div {
    height: auto;
    > div {
      display: flex;
    }
  }
}

.emptyState {
  padding: 12rem 0rem;
  text-align: center;
}

.sortContainer {
  display: flex;
  border-bottom: 1px solid $border;

  [class$="menu"] {
    border-color: $border;
    border-style: solid;
    border-width: 1px;
  }

  /* Tweak Selector Design to fit with the page design. */
  & > div > div {
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-radius: unset;
    border-color: $border;
  }

  & > :nth-child(1) {
    width: 70%;
    padding-left: 15px;

    & > div:not([class$="menu"]) { 
      border-left-width: 0px;
    }

    /* Fix menu CSS */
    & > div[class$="menu"] {
      border-left-width: 0px;
      margin-left: -14px;

      /* Alter Content Spacing */
      & > div > div {
        padding-left: 23px;
      }
    }
  }

  & > :nth-child(2) {
    width: 30%;

    & > div[class$="menu"] {
      border-right-width: 0px;
    }
  }
}