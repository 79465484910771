@import 'components/atoms/colours.scss';

.accordion {
  width: 100%;
  flex-direction: column;
  border: solid 1px $chromium;
  border-radius: 2px;
}

.summary {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    transform: rotate(-90deg);
    transition: transform 100ms;
    margin: 1.6rem;
  }

  .expanded {
    transform: rotate(0deg);
  }

  &:hover {
    background-color: $cloud;
  }
}

.content {
  border-top: solid 1px $chromium;
}