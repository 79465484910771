@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.popover {
  z-index: 1000;
  svg {
    fill: $disabled;
  }
}

.tooltip {
  margin-right: 2rem;
  width: 26rem;
  background-color: $contrast;
  color: $secondary;
  padding: 1rem;
  border: $defaultBorderStyle;
  border-radius: 3px;
}

.line {
  font-size: 1rem;
  line-height: 130%;
  margin-bottom: 0.9rem;
  display: flex;
}

.bodyLine {
  display: inline;
  margin-right: 2.4rem;
}

.bodyLineNA {
  display: inline;
  margin-right: 1.3rem;
}
