@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.background {
    width: 100%;
    height: 100%;
}
.denied {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 250px;
}

.access {
    width: 100%;
    height: 100%;
    .video {
        width: 100%;
        height: 100%;
        background-color: black;
    }
    .noVideo {
        width: 100%;
        height: 100%;
        text-align: center;
        margin-top: 250px;
    }
}
.title {
    height: 29px;
    margin: 20px auto;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #1d1e2b;
}