@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 2px;

  @include for-phone-only {
    padding: 0;
  }
}

.mainSection {
  flex: 1;
}

.iconMargin {
  height: 3.6rem;
  padding-right: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: $defaultBorderStyle;
}

// Temporary override to styles for consistency on profile page
// while other profile section components have yet to be updated to atomic design
// Delete override class when all profile sections are in atomic design
.temporaryConsistencyStylingTitle {
  font-size: 1.4rem;
  font-weight: 600;
}

.header {
  display: flex;
  flex-direction: row;
  height: 3.6rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: $defaultBorderStyle;
  @include for-phone-only {
    border-bottom: none;
    border-top: $defaultBorderStyle;
  }
}

.editButton {
  display: flex;
}

.editModeFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 3.2rem;
}

.content {
  margin-top: 1.2rem;
  padding-left: 1.2rem;

  @include for-phone-only {
    margin-top: 0;
  }
}

.buttonSpacer {
  width: 0.8rem;
}
