@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.left {
  display: flex;
}

.right {
  display: flex;
  align-items: center;
  @include for-phone-only {
    display: none;
  }
  span {
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #1d1e2b;
    margin-right: 5px;
  }
}

.type {
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #a9acc0;
  cursor: pointer;
}

.isActive {
  color: #1d1e2b;
}
.vertical {
  margin: 0 10px;
}
.filterInput {
  padding: 10px 78px 10px 8px;
  border-radius: 4px;
  border: solid 1px #e3e7ed;
  background-color: #ffffff;
  &::placeholder {
    color: $hint;
  }
}

.circle {
  border-radius: 50%;
  height: 15px;
  width: 15px; 
  display: inline-block;
  background: #dc5758;
  vertical-align: top;
  margin-left: 0.5rem;
}

.number {
  display: block;
  color: #FFFFFF;
  height: 15px;
  line-height: 15px;
  text-align: center;
  font-size: 1rem;
}