@import 'components/atoms/colours.scss';

.cancelButton {
  cursor: pointer;
  display: flex;
  flex: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.cross {
  fill: $hint;
  width: 1.8rem;
  height: 1.8rem;
}
