button.iconButton {
  padding: 0.5rem;
  border-radius: 0%;
}

ul.menu {
  padding: 0.5rem;

  li.menuItem {
    padding: 0.5rem;
    border-radius: 0.4rem;
  }
}

.labelText {
  white-space: nowrap;
}