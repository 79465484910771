@import 'components/atoms/colours.scss';

.sessionQuality {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .controls {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
      color: $slate;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 5rem;
      height: 7rem;
      margin-top: 1.6rem;

      .icon {
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;
        font-size: 3rem;
        line-height: 4.5rem;
        transition: transform 200ms;

        img {
          height: 3rem;
        }
      }

      .ratingText {
        justify-self: flex-end;
        opacity: 0;
        line-height: 1.6rem;
        font-size: 1rem;
        font-weight: 600;
      }

      &.selected {
        .ratingText {
          opacity: 1;
        }
      }

      &:hover, &.selected {
        .icon {
          transform: scale(1.3);
        }
      }
    }
  }
}
