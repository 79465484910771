@import 'components/atoms/colours.scss';

.toolTipItem {
  background: $secondary;
  padding: 0.4rem 0.6rem;
  border-radius: 0.2rem;
  margin-top: 0.8rem;
}

.toolTipItem {
  &:before {
    top: -0.2rem;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  &:before {
    border-bottom-color: $secondary;
    border-width: 0.5rem 0.4rem;
    margin-left: -0.4rem;
  }
}