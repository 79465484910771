@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.card {
  min-height: 5rem;
  padding: 1.6rem;
  border: 1px solid $border;
  border-radius: 4px;
  margin: 1.6rem auto;
  background-color: white;
  box-sizing: border-box;

  &:hover {
    background-color: rgba($chromium, 0.4);
  }

  @include for-phone-only {
    border-radius: unset;
    border-left: none;
    border-right: none;
    margin: 0 auto;
  }
  &.selected {
    background-color: $pixieDust;
    border-color: $sky;
  }
}