@import 'components/atoms/colours.scss';

.createMessageButton {
  height: 4.4rem;
  width: 4.4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $backgroundHover;
  }
}

.createMessageIcon {
  background-color: $primaryBlue;
  color: white;
}
