@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.feedback {
  padding-top: 1.6rem;

  label {
    color: $secondary;
    font-size: 1rem;
  }

  .commentBox {
    margin-bottom: 0.2rem;


    textarea {
      border-radius: 3px;
      padding: 1.2rem;
      font-size: 1.1rem;
      border: $defaultBorderStyle;
      min-height: 6rem;
    }
  }

  .commentInvalid {
    textarea {
      border-color: $warning;

      &:focus {
        border-color: $warning;
      }
    }
  }
}

.errorMessageContainer {
  margin-top: 0.4rem;
  margin-bottom: 1.4rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.buttons {
  text-align: right;
}

.seeCriteria {
  color: $callToAction;
  font-family: Montserrat;
}

.seeCriteria:hover {
  cursor: pointer;
}

.selectLabel {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoPanel {
  color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -1.5rem -2rem 0;
  background-color: $cloud;
  padding: 1.5rem;
  box-sizing: border-box;

  @include for-desktop-only {
    margin: -2.5rem -3rem 0;
  }

  & > * {
    margin-bottom: 0.5rem;
    text-align: center;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  .name {
    font-weight: 600;
  }
}

.sessionWithWrapper {
  margin: 0 0 1.5rem 0;

  .sessionWithLabel {
    margin-bottom: 0.5rem;
    color: $slate;
  }
 
  // .primary {
  //   color: $secondary;
  // }
}