@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: $background;
  border-radius: 5px;
}

.dropdownFocused {
  border-radius: 5px 5px 0 0;
}

.wrapper {
  max-height: 35rem;
  width: 100%;
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  background-color: white;
  border: $defaultInputBorderStyle;
  box-sizing: border-box;
}

.dropdownContent {
  :hover {
    background-color: $background;
  }
}

.dropdownContent a {
  font-size: 1.2rem;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
