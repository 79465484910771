
@import 'components/atoms/responsiveBreakpoints.scss';

.contents {
  @include for-phone-only {
    display: flex;
    flex-direction: column;
  }
}

.studentsTable {
  padding-top: 1.6rem;
}

.tabList {
  border-bottom: none;
}