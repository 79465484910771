@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.emptyStatePadding {
  padding-top: 2rem;
}
.emptyStateIcon {
  width: 5rem;
  height: 5rem;
}

.preText {
  white-space: pre-wrap;
}
.table {
  margin-top: 2rem;
  font-size: 1.2rem;
  thead tr {
    background-color: $background;
    border-bottom: none;
  }
  tbody tr {
    border-bottom: none;
    font-size: 1.2rem;
    &:hover {
      a {
        color: $callToAction;
      }
    }
  }
  th {
    padding: 1.6rem 3rem 1.6rem 6rem !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: $secondary;
  }
  td {
    padding: 2.4rem 3rem 2.4rem 6rem !important;
    color: $secondary;
  }
}
.lessonTd {
  width: 30%;
  word-wrap: break-word;
  word-break: break-word;
}

.horizontalLine {
  margin: 2em 0;
  border-bottom: $defaultBorderStyle;
}

.upcoming {
  color: $success;
}

.inProgress {
  color: $secondary;
}

.completed {
  color: $secondary;
}

.absent {
  color: $hint;
}

.report {
  h4 {
    color: $secondary;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  p {
    color: $primary;
    font-size: 1.2rem;
    margin-top: 0;
    line-height: 1.6;
  }
}

.topFieldsContainer {
  display: flex;

  @include for-phone-only {
    flex-direction: column;
  }

  p {
    margin: 0;
  }
}

.topField {
  flex: 1 0 auto;
}

.sessionLink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}