@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.autoComplete {
  color: $primary;
  font-size: 1.2rem;
  margin: 1.6rem 0 0;

  @include for-phone-only {
    margin: 0;
    width: auto;
  }
}

.buttons {
  text-align: right;
}

.checkCorrectInstructions {
  margin-top: 0.4rem;
  margin-bottom: 1.6rem;
  font-size: 1rem;
  line-height: 1.6rem;
}
