@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.container {
  display: grid;
  grid-template-areas: "sidebar divider main";
  grid-template-rows: 1fr;
  grid-template-columns: minmax(min-content, 40rem) 2px 1fr;
  grid-gap: 0rem 0rem;
  box-sizing: border-box;
  height: 100%;
  background-color: $border;

  @include for-phone-only {
    grid-template-areas: "header" "sidebar" "main";
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    height: auto;
  }

  transition: grid-template-columns 1s ease;
}

.container.collapsed {
  grid-template-columns: 3.2rem 2px 1fr;
}

.container.collapsed .sidebarScroll {
  background-color: $background;
}
.container.collapsed .sidebarScroll .sidebar{
  display: none;
}

.sidebarDividerArea {
  grid-area: divider;
  background-color: rgba(0,0,0,0.0); /*$border;*/
  transition: background ease-out 0.3s;

  position: relative;
  left: -8px;
  width: 18px;

  display: grid;
  grid-template-areas: "main"; /* Only one area so the contents stack on top of each other */
  justify-content: center;
  justify-items: center;
  @include for-phone-only {
    display: none;
  }
}

.sidebarDividerLine {
  grid-area: main;
  width: 2px;
  background-color: $border;
  height: 100%;
  transition: border ease-out 0.1s, color ease-out 0.1s, background-color ease-out 0.1s;
}

.sidebarDividerButton {
  grid-area: main;
  position: relative;
  top: 12rem;
  box-sizing: border-box;
  background-color: white;
  width: 2.4rem;
  height: 2.4rem;
  border: solid 2px $primary;
  color: $primary;
  border-radius: 50%;
  cursor: pointer;
  transition: border ease-out 0.1s, color ease-out 0.1s, background-color ease-out 0.1s;
}

.sidebarDividerArea:hover .sidebarDividerLine {
  background-color: $primary;
}

.sidebarDividerArea:hover .sidebarDividerButton{
  border: solid 2px $primary;
  color: $primary;
}

.sidebarDividerArea:hover .sidebarDividerButton:hover{
  border: solid 2px $primary;
  background-color: $primary;
  color: white;
}


.sidebarScroll {
  grid-area: sidebar;
  overflow-y: auto;
  background-color: white;
}

.sidebar {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 1.6rem;
  padding: 1.6rem;
  align-content: start;
}


.main {
  grid-area: main;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  background-color: white;
  overflow: hidden; /* spookers but the iframe is weirdly larger than it should be so we are getting a scrollbar */
}


