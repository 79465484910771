@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.invoiceTag {
  @include for-phone-only {
    padding: 0 0.5rem;
  }
}

.pendingInvoice {
  border: 0.1rem solid $charcoal;
  background: white;
  color: $charcoal;
}

.submittedInvoice {
  border: 0.1rem solid $tiffany;
  background: white;
  color: $tiffany;
}

.paidInvoice {
  border: 0.1rem solid $tiffany;
  background: $tiffany;
  color: white;
}

.deletedInvoice {
  border: 0.1rem solid $salmon;
  background: $salmon;
  color: white;
}

.notreadyInvoice {
  border: 0.1rem solid $chromium;
  background: $chromium;
  color: $charcoal;
}