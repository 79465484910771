
/* Base Colours - MODIFY ONLY WITH DESIGNER APPROVAL */
$salmonDark: #770000;
$salmonLight: #c05f63;
$salmon: #ed4b53;
$slate: #6d6d77;
$tiffany: #00B7A5;
$midOrange: #fda502;
$orange: #ff764c;
$stone: #babcc5;
$chromium: #e3e7ed;
$cloud: #f1f3f6;
$cloudDark: #d8dadd;
$white: #ffffff;
$sky: #3C93F9;
$wheat: #fff5c7;
$paleGrey: #f7f8fa;
$charcoal: #1D1E2B;
$mulberry: #741443;
$mulberryDark: #450825;
$jellyBean: #207a8d;
$cheddar: #fdaa02;
$deepSea: #015567;
$mist: #f3f6fa;
$pixieDust: #e8f0fe;
$pinkOrange: #FF9269;

$transparentBlack: rgba(0,0,0,.13);
$transparentTiffany: rgba(0, 168, 150, 0.4);
$transparentSpaceCadet: rgba(35, 41, 80, 0.4);
$transparentSlate: rgba(79, 105, 135, 0.4);
$transparentOrange: rgba(255, 118, 76, 0.6);
$transparentPinkOrange: rgba(255, 146, 105, 0.6);
$transparentStone: rgba(186, 188, 197, 0.4);
$transparentSalmon: rgba(205, 75, 83, 0.4);
$transparentLeaf: rgba(100, 163, 36, 0.6);
$transparentDenim: rgba(18, 113, 172, 0.6);
$transparentCharcoal: rgba($charcoal, 0.8);

$tomatoRed: #ca0915;
$salamander: #ff5f00;
$mustard: #cda132;
$olive: #b5ba1b;
$leaf: #64a324;
$forest: #157112;
$glacier: #17b0a0;
$denim: #1271ac;
$cerulean: #1e4c77;
$midnightBlue: #1b2387;
$electricPurple: #9b19ff;
$indigo: #620ba6;
$fuschia: #d30f8e;
$eggplant: #860759;
$aliceBlue: #ebfaf7;
$indigoBlue: #464ac9;
$bubbles: #EBFBF7;
$caribbeanGreen: #14C39A;
$greenCyan: #0f8d70;
$brightGray: #ECEDF1;
$crayola: #A9ACC0;
$oldLace: #FFF6E3;
$chromeYellow: #FDAA03;
$gamboge: #e89c01;
$lavendarBlush: #FDF0F0;
$desire: #ED4B53;
$cultured: #F6F7FA;
$iris: #4C4FCA;
$antiFlashWhite: #F1F5F7;
$black: #000000;
$outrageousOrange: #FF764C;
$silverChalice: #ABABAB;
$sonicSilver: #73747d;
$darkSilver: #707070;
$davyGrey: #5D5D5D;

/* Main App Colours */
$primary: $charcoal;
$secondary: $slate;
$callToAction: $salmon;
$callToActionHover: $salmonLight;
$callToActionActive: $salmonDark;
$success: $tiffany;
$warning: $orange;
$attention: $salmon;
$disabled: $chromium;
$disabledText: $stone;
$border: $chromium;
$inputBorder: $stone;
$activeInputBorder: $charcoal;
$background: $mist;
$backgroundHover: $chromium;
$backgroundActive: $stone;
$hint: $stone;
$contrast: $white;
$online: $tiffany;
$highlight: $wheat;
$shadow: $transparentBlack;
$primaryBlue : $indigoBlue;

$secondaryButtonHover: rgba($charcoal, 0.08);
$secondaryButtonActive: rgba($charcoal, 0.24);

$shadow: $transparentBlack;
$primarySoft: $transparentSpaceCadet;
$secondarySoft: $transparentSlate;
$successSoft: $transparentTiffany;
$warningSoft: $transparentOrange;
$hintSoft: $transparentStone;

$callToActionLighter: color($callToActionHover tint(50%));

$available: $tiffany;
$seekingHours: $primary;
$full: $orange;
$resigned: $stone;
$onboarding: $tiffany;

$avatar0: $tomatoRed;
$avatar1: $salamander;
$avatar2: $mustard;
$avatar3: $olive;
$avatar4: $leaf;
$avatar5: $forest;
$avatar6: $glacier;
$avatar7: $denim;
$avatar8: $cerulean;
$avatar9: $midnightBlue;
$avatar10: $electricPurple;
$avatar11: $indigo;
$avatar12: $fuschia;
$avatar13: $eggplant;

$chartOverall: $leaf;
$chartOverall2: $transparentLeaf;
$chartSection1: $denim;
$chartSection1Transparent: $transparentDenim;
$chartSection2: $glacier;
$chartSection3: $mustard;
$chartSection4: $forest;
$chartSection5: $electricPurple;
$chartSection6: $denim;
$chartSection7: $glacier;
$chartSection8: $mustard;
$chartSection9: $forest;
$chartSection10: $electricPurple;
$chartSection11: $orange;
$chartSection11Transparent: $transparentOrange;

:export {
  primary: $primary;
  secondary: $secondary;
  disabled: $disabled;
  crimsonRed: $salmon;
  background: $background;
  backgroundHover: $backgroundHover;
  backgroundActive: $backgroundActive;
  contrast: $contrast;
  salmon: $salmon;
  chartOverall: $chartOverall;
  chartOverall2: $chartOverall2;
  chartSection1: $chartSection1;
  chartSection1Transparent: $chartSection1Transparent;
  chartSection2: $chartSection2;
  chartSection3: $chartSection3;
  chartSection4: $chartSection4;
  chartSection5: $chartSection5;
  chartSection6: $chartSection6;
  chartSection7: $chartSection7;
  chartSection8: $chartSection8;
  chartSection9: $chartSection9;
  chartSection10: $chartSection10;
  chartSection11: $chartSection11;
  chartSection11Transparent: $chartSection11Transparent;
  crimsonRedDark: $salmonDark;
  hint: $hint;
  mtaMatchHigh: $tiffany;
  mtaMatchMedium: $slate;
  mtaMatchLow: $orange;
  success: $success;
  warning: $warning;
  attention: $attention;
  transparentCharcoal: $transparentCharcoal;
  sky: $sky;
  orange: $orange;
  midOrange: $midOrange;
  tiffany: $tiffany;
  bubbles: $bubbles;
  caribbeanGreen: $caribbeanGreen;
  brightGray: $brightGray;
  crayola: $crayola;
  oldLace: $oldLace;
  chromeYellow: $chromeYellow;
  gamboge: $gamboge;
  lavendarBlush: $lavendarBlush;
  desire: $desire;
  cultured: $cultured;
  cloud: $cloud;
  iris: $iris;
  antiFlashWhite: $antiFlashWhite;
  black: $black;
  outrageousOrange: $outrageousOrange;
  aliceBlue: $aliceBlue;
  caribbeanGreen: $caribbeanGreen;
  greenCyan: $greenCyan;
  chromeYellow: $chromeYellow;
  silverChalice: $silverChalice;
  sonicSilver: $sonicSilver;
  darkSilver: $darkSilver;
  davyGrey: $davyGrey;
}