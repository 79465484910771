@import 'components/atoms/colours.scss';

.calendarIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.8rem;
  cursor: pointer;
  top: -0.1rem;
  width: 1.4rem;
  height: 1rem;
  border: 2px solid $primary;
  border-color: $primary;
  border-width: 0.5rem 0.2rem 0.2rem 0.2rem;
  font-size: 0.8rem;
  text-align: center;
  position: relative;
  border-radius: 0.2rem;
  line-height: 1rem;
  color: $primary;
  &:before {
    position: absolute;
    content: '';
    width: 0.3rem;
    height: 0.3rem;
    top: -0.7rem;
    right: 0.1rem;
    background: $primary;
    border-radius: 0.1rem;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0.3rem;
    height: 0.3rem;
    top: -0.7rem;
    left: 0.1rem;
    background: $primary;
    border-radius: 0.1rem;
  }
}

.innerIcon {
  position: absolute;
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  top: 0.2rem;
  left: 0.2rem;
  background: $primary;
  border-radius: 0.1rem;
}
