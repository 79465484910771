@import 'components/atoms/colours.scss';

.showtoggleButtonOnHover{
  .toggleButton{
    visibility: hidden;
  }
  &:hover {
    .toggleButton{
      visibility: visible;
    }
  }
}

.toggleButton {
  max-width: 2rem;
  cursor: pointer;
}

.editIcon {
  width: 1.8rem;
  height: 1.8rem;
}
