@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/shadows.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.invoiceCard {
  text-decoration: none;
  text-align: left;
  text-align: left;
  word-break: break-word;
  padding: 0;
  margin: -1.6rem;
  cursor: pointer;
}

.invoiceHours {
  display: flex;
  justify-content: space-between;

  .totalHours {
    margin-right: 3rem;
  }

  .lessonStatus {
    display: flex;
  }
}

.invoiceCardBody,
.invoiceCardFooter {
  padding: 1rem 1.6rem;

  @include for-desktop-only {
    padding: 1rem 1.2rem;
  }
}

.invoiceCardFooter {
  border-top: $defaultBorderStyle;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .invoiceStatus {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & > *:first-child {
      margin-right: 1rem;
    }

    @include for-desktop-only {
      align-items: center;
    }
  }

  .invoiceValue {
    display: flex;
    align-items: flex-end;
    text-align: right;
    justify-content: flex-end;
    min-width: 40%;
    white-space: nowrap;

    & > div {
      color: $slate;
    }
  }
}

.iconDone {
  width: 2rem;
  font-size: 1.5rem;
  color: $success;
}

.iconIncomplete {
  composes: iconDone;
  color: $warning;
}