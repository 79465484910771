@import 'components/atoms/colours.scss';

.chart {
    height:110px;
    width: 320px;
    padding: 10px;

    &[hastooltip] {
        margin-bottom: 30px;
    }
    &:not([hastooltip]) {
        margin-bottom: 10px;
    }
}

.chartBars {
    height: 95%;
    padding-right: 1px;
    padding-left: 1px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 2px;
    align-items: end;
}

.bar {
    background-color: $pixieDust;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
}

.median {
    font-size: 1rem;
    font-weight: bold;
    color: $slate;
    padding-left: 10px;
}

.rateScale {
    height: 5%;
    width: 100%;
    position: relative;

    & > * {
        height: 100%;
        position: absolute;
        border-radius: 100px;
    }

    & > :nth-child(3) {
        background-color: $tiffany;
    }
    
    & > :nth-child(2) {
        background-color: $midOrange;
    }
    
    & > :nth-child(1) {
        background-color: $orange;
    }
}

.key {
    display: flex;
    justify-content: space-between;
}

.tooltip {
    padding-top: 16px;
    opacity: 0.8;

    & :global(.static-tooltip-inner) {
        padding: 4px;
    }

    & :global(.static-tooltip-arrow) {
        border-width: 0 6px 12px;
    }
}