@import 'components/atoms/colours.scss';

.ourPeople {
  font-size: 1.4rem;
}

.warningButton {
  composes: warning from 'styles/buttons.scss';
  font-weight: bold;
  text-decoration: none;
}

.emptyMessage {
  font-size: 1.8em;
  text-align: center;
  color: $hint;
  margin-bottom: 1em;
}

.tabHeader {
  padding-left: 0.7rem;
  display: inline-block;
  text-align: left;
}

.studentsTable {
  padding-top: 1.6rem;
}

.noTabs{
  padding-top: 0.5rem;
}