@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  max-width: 64rem;
  margin: auto;
}

.info {
  width: 64rem;
  padding-bottom: 1.6rem;
}

.icon {
  width: 1rem;
  fill: $sky;
}

.eulaSection {
  color: $sky;
}

.eulaText {
  position: absolute;
  margin-top: 0.1rem;
  margin-left: 0.3rem;
  text-decoration: underline;
}
