@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/colours.scss';

.loading {
  width: 100%;
  height: 40rem;
}

.container {
  @include for-phone-only {
    background-color: $background;
  }
}
.emptyContainer {
  padding: 2rem;
  margin: auto;
  margin-top: 3rem;
}

.emptyHeader {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $slate;
}

.emptyContent {
  margin-bottom: 2rem;
  text-align: center;
  color: $slate;
  @include for-desktop-only {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

.emptyGraphic { 
  height: 10rem;
  width: 100%;
  margin-bottom: 2rem;
}

.cards {
  padding: 1rem;
}