@mixin spacingsFor($type, $size) {
    .#{$type}-#{$size} {
        #{$type}: var(--spacing-#{$size});
    }

    .#{$type}-top-#{$size} {
        #{$type}-top: var(--spacing-#{$size});
    }

    .#{$type}-left-#{$size} {
        #{$type}-bottom: var(--spacing-#{$size});
    }

    .#{$type}-left-#{$size} {
        #{$type}-left: var(--spacing-#{$size});
    }

    .#{$type}-rigtht-#{$size} {
        #{$type}-right: var(--spacing-#{$size});
    }
}

@include spacingsFor('padding', '01');
@include spacingsFor('padding', '02');
@include spacingsFor('padding', '03');
@include spacingsFor('padding', '04');
@include spacingsFor('padding', '05');
@include spacingsFor('padding', '06');
@include spacingsFor('padding', '07');

@include spacingsFor('margin', '01');
@include spacingsFor('margin', '02');
@include spacingsFor('margin', '03');
@include spacingsFor('margin', '04');
@include spacingsFor('margin', '05');
@include spacingsFor('margin', '06');
@include spacingsFor('margin', '07');