@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import '../styles.scss';

.formInput {
  width: 100%;
  color: $primary;
  font-family: inherit;
  font-size: 1.2rem;
  border-radius: 2px;
  border: $defaultInputBorderStyle;
  padding: 0 1.6rem;
  box-sizing: border-box;
  background-color: transparent;
  -webkit-appearance: none;
  padding: 1rem 3.5rem 1rem 1.5rem;
  height: 4rem;
  &::placeholder {
    color: $hint;
  }
  &:focus {
	  border: $activeInputBorderStyle;
  }

  @include for-phone-only {
    padding: 1rem;
    height: 4rem;
  }
}
