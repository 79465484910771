@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.emptyState {
  max-width: 33rem;
  margin: 12rem auto;
  display: block;
  text-align: center;
  & > svg {
    margin: 0rem auto 2rem;
  }
  @include for-phone-only {
    margin: 0 0.8rem;
    position: relative;
    transform: translateY(50%);
  }
}

.emptyStateHeading {
  line-height: 1.25;
  text-align: center;
  color: $secondary;
  display: block;
  margin-bottom: 2rem;
}

.emptyStateIcon {
  width: 5rem;
  height: 5rem;
  display: block;
  margin-bottom: 2.4rem;
}

.emptyStateBody {
  line-height: 1.5;
  text-align: center;
  color: $secondary;
  display: block;
  margin-bottom: 2.8rem;
}

.emptyStateButton {
  width: 15rem;
}
