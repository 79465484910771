@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.notificationsToggler {
  cursor: pointer;
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: $backgroundHover;
    transition: background-color .5s;
    border-radius: 50%;

    @include for-phone-only {
      background-color: white;
    }
  }
}

.notificationsToggler.highlighted {
  background-color: $backgroundActive;
  transition: background-color .5s;
  border-radius: 4px;
}

.bellIcon {
  vertical-align: middle;
  color: $primary;
  @include for-phone-only {
    font-size: 2rem;
  }
  font-size: 2rem;
}

.notifText {
  font-size: 1rem;
  color: #fff;
  user-select: none;
  -webkit-user-select: none;
}

.unseen {
  user-select: none;
  -webkit-user-select: none;
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  height: 1.4rem;
  width: 1rem;
  padding: 0 0.3em;
  line-height: 1.4rem;
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
  background-color: $salmon;
  border-radius: 1.8rem;
  border: solid 1px $cloud;
}
