.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyGraphic {
  width: 4.8rem;
  height: 4.8rem;
  margin-top: 13.4rem;
}

.emptyTitle {
  width: 33rem;
  margin-top: 2.1rem;
  text-align: center;
}

.emptyText {
  width: 33rem;
  margin-top: 2rem;
  text-align: center;
}

.emptyButton {
  width: 15rem;
  margin-top: 2.8rem;
}