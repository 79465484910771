@import "./base.scss";
@import "../typography.scss";

.ds-datepicker.MuiFormControl-root {
    @extend .typography;
           
    .MuiFormLabel-root {        
        @include textbox-alike-label();        
    }

    .MuiInputBase-root {      
        &::before, &::after {
            content:unset;
        }  

        .MuiInputBase-input {
            @include textbox-alike();
        }        
    }

    label + .MuiInput-formControl {
        margin-top: 0;
    }
    
    .MuiInputAdornment-root {            
        margin-left: -48px;

        .MuiButtonBase-root {
            @include textbox-alike-base();
            @include input-base('primary');
            
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }        
}

.MuiPopover-root {
    .MuiPickersBasePicker-container {
        @extend .typography;

        .MuiPickersBasePicker-pickerView {
            .MuiTypography-root {
                @include set-typo-for-caption();                            
                letter-spacing: 0;

                &.MuiPickersCalendarHeader-dayLabel {
                    visibility:hidden;
                
                    &::first-letter {
                        visibility:visible;
                    }
                }
            }

            .MuiPickersDay-daySelected {            
                background-color: var(--input_primary_color_bkg);
            }            
        }
    }
}