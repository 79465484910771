@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';
.group {
    min-width: 31.5rem;
    max-width: 34.5rem;

    @include for-phone-only {
      z-index: $six;
      background: #fff;
      height: calc(100vh - 0.2rem);
      height: calc(100vh - 0.2rem - env(safe-area-inset-top));
      height: calc(100vh - 0.2rem - constant(safe-area-inset-top));
      min-width: 100%;
    }
    .groupHeader {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: solid 1px #e3e7ed;
      padding: 0 2rem;
      height: 7rem;

      h3 {
        color: #707070;
        font-family: Montserrat;
        font-size: 16px;
      }
    }
    .manageLink {
      text-decoration: none;
      .manageMember {
        align-items: center;
        display: flex;
        padding: 2.3rem 0 0 2rem;
        cursor: pointer;
        span {
          color: #464ac9;
          font-family: Montserrat;
          font-size: 14px;
        }
      }
    }

    .manageGroupButton {
      color: #464ac9;
    }
    .addPeople {
      align-items: center;
      display: flex;
      padding: 2.3rem 0 0 3.5rem;
      cursor: pointer;

      .addBox {
        color: #464ac9;
      }

      span {
        line-height: 1.6rem;
        font-size: 13px;
        margin-left: 10px;
        color: #707070;
        text-align: left;
      }
    }

    .peopleList {
      margin-left: 3rem;
      margin-top: 1.6rem;
      overflow: auto;
      height: 100%;
      
      .people {
        display: flex;
        align-items: center;
        margin-top: 1.3rem;

        span {
          color: #1d1e2b;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
        }
        .moreHorizIcon {
          display: none;
        }
        a {
          text-decoration: none;
        }
      }

      .people:hover {
        .moreHorizIcon {
          display: flex;
          margin-left: auto;
        }
      }
    }
  }

  .mobileRemovePeople {
    width: 30rem;
    height: 25rem;
    border-radius: 15px;
    margin: 0;
  
    .groupNameInput {
      margin-top: 1rem;
    }
    .header {
      border-bottom: none;
    }
    p{
      text-align: center;
      color: #1d1e2b;
      font-size: 14px;
      font-weight: normal;
    }
    .buttonGroup{
      display: flex;
      margin-top: 1rem;
      button {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.7px;
        text-align: center;
        border-radius: 50px;
        width: 126px;
        padding: 1rem;
      }

      .cancelButton {
        background-color: #FFFFFF;
        color: #ed4b53;
        border: solid 2px#ed4b53;
        margin-right: 0.5rem;
      }
      .removeButton {
        background-color: #ed4b53;
        color: #FFFFFF;
        border: none;
      }
    }
   
  }