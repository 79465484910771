@import 'components/atoms/colours.scss';

.wave {
	text-align:center;
	width:3rem;
  height:1.1rem;
  margin-top: 0.2rem;
}

.dot {
  display:inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius:50%;
  margin-right:3px;
  background: $stone;
  animation: wave 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-0.5rem);
	}
}