@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.bannerContainer {
  width: 100%;
  height: 5rem;
  background-color: $contrast;
  z-index: 1002;
  cursor: pointer;

  @include for-phone-only {
    top: 5rem !important;
    top: calc(5rem + constant(safe-area-inset-top)) !important; /* iOS 11.0 */
    top: calc(5rem + env(safe-area-inset-top)) !important; /* iOS 11.2 */
  }
}

.nonClickBanner:hover {
  opacity: 1 !important;
}

.nonClickContainer {
  cursor: default !important;
}

.banner {
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
}

.messageText {
  @include for-phone-only {
    padding: 1rem;
  }
}

.icon {
  position: absolute;
  color: $contrast;
  margin-right: 2.2rem;
  right: 0;
  height: '2rem';
  width: '2rem';
}

.refreshing {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.warning {
  background-color: $warning;
}

.attention {
  background-color: $attention;
}

.sessionStarting {
  background-color: $jellyBean;
}

.sessionInProgress {
  background-color: $sky;
}

.bannerContainerDiv {
  @include for-phone-only {
    top: 5rem;
    position: relative;
  }
}