@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.topBar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: $layerTopNav;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.topNav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5.2rem;
  border-bottom: 1px solid $chromium;
  font-size: 1.4rem;
  color: $primary;
  fill: $primary;
  box-sizing: border-box;

  @include for-phone-only {
    background-color: $white;
    position: fixed;
    color: $primary;
    fill: $primary;
    top: 0;
    width: 100%;
    justify-content: space-between;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
    height: 5rem;
  }

  @include for-desktop-only {
    background: white;
    right: 0;
    left: 0;
    padding: 0 2rem;

    display: none;
  }

  @include for-iphonex-only {
    height: 8.5rem;
  }

  @media print {
    display: none;
  }
}

.logo {
  @include for-desktop-only {
    display: none;
  }

  height: 2.8rem;
  width: 2.8rem;
  color: $primary;
  fill: $primary;
  @include for-iphonex-only {
    padding-top: 2.5rem;
  }
  @include for-phone-only {
    padding-left: 3px;
  }
}

.hamburger {
  font-size: 2rem;

  @include for-desktop-only {
   display: none;
  }

  @include for-iphonex-only {
    padding-top: 2.5rem;
  }
}

.mobileSpacer {
  display: none;
  @include for-phone-only {
    display: block;
    height: 5rem;
  }
}