@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.feedback {
  width: 40rem;
  padding-top: 1.6rem;

  @include for-phone-only {
    width: auto;
  }

  .commentBox {
    margin-bottom: 0.2rem;
    label {
      color: $secondary;
      font-size: 1rem;
    }

    textarea {
      border-radius: 3px;
      padding: 1.2rem;
      font-size: 1.1rem;
      border: $defaultBorderStyle;
      min-height: 6rem;
    }
  }

  .commentInvalid {
    textarea {
      border-color: $warning;

      &:focus {
        border-color: $warning;
      }
    }
  }
}

.errorMessageContainer {
  margin-top: 0.4rem;
  margin-bottom: 1.4rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.buttons {
  text-align: right;
}

.emojiRow {
  margin: 2.2rem auto 1rem;
  height: 6rem;
  width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-phone-only {
    width: auto;
  }
}

.emojiBox {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  border-radius: 50%;
  min-width: 6rem;
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-phone-only {
    min-width: 3rem;
    min-height: 3rem;
  }
}

.emoji {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out;

  &:hover {
    width: 4rem;
    height: 4rem;
  }
}

.selected > .emoji {
  width: 4rem;
  height: 4rem;
}

.selected {
  background: $background;
}

.ratingMessage {
  height: 3rem;
  line-height: 1.5;
  font-size: 1.2rem;
  color: $secondary;
  text-align: center;
}

.formInfo {
  display: inline-flex;
  margin-bottom: 2.4rem;
}

.formInfoIcon {
  display: inline-block;
  width: 2.4rem;
}

.formInfoText {
  display: inline-block;
  color: $secondary;
  font-size: 1rem;
  flex: 1;
}

.seeCriteria {
  color: $callToAction;
  font-family: Montserrat;
  padding-top: 0.3rem;
}

.seeCriteria:hover {
  cursor: pointer;
}
