@import 'components/atoms/borders.scss';

.person {
  padding: 1.6rem 0;
  // border-top: $defaultBorderStyle;
  display: flex;
  align-items: center;

  .personCheckbox {
    flex: 0;
    margin: 0 0 0 1rem;
  }
}
