@import 'components/atoms/colours.scss';

.thumbPicker {
  display: flex;
  flex-direction: row;

  button {
    border: none;
    background: $mist;
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-right: 0.1rem solid $cloudDark;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &:hover {
      background: $secondaryButtonHover;
    }

    &:active {
      background: $secondaryButtonActive;
    }
  }

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    fill: $stone;

    &.positive {
      fill: $tiffany;
    }

    &.negative {
      fill: $orange;
    }
  }
}
