@import 'components/atoms/colours.scss';

.validationMessage {
  display: flex;
  align-items: start;
  margin-top: 0.6rem;

  a {
    color: $warning;
  }
}

.validationIcon {
  margin-right: 0.6rem;
}

.linkText {
  text-decoration: underline;
  color: $warning;
  cursor: pointer;
}
