@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.invoiceLine {
  border-bottom: $defaultBorderStyle;
  padding: 1.5rem 1rem 1.5rem 0;
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    align-self: center;
  }

  & > *:last-child {
    // text-align: right;

    @include for-phone-only {
      text-align: right;
    }
  }

  @include for-phone-only {
    padding: 1.5rem;
  }
}

.monies {
  color: $slate;
}