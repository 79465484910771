@import 'components/atoms/colours.scss';

.menuItem {
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  color: $primary;
  font-family: inherit;
}

.highlighted {
  background: $background;
  color: $secondary;
}

.roadmapCategoryLabelContainer {
  padding: 0.5rem 1rem;
}
