@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.threads {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.thread {
  display: block;
  flex: 1;
  text-decoration: none;
  color: $primary;
}

.loadingIndecator {
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.loadingIndecator > div {
  color: $hint !important;
}

.emptyConversationMessage {
  padding: 1rem 2.4rem;
  color: $stone;
  font-size: 1.2rem;

  @include for-phone-only {
    padding: 1.5rem;
  }
}