@import 'components/atoms/colours.scss';

.crimsonButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-block;
  min-width: 8.8rem;
  height: 3.6rem;
  text-align: center;
  padding: 1rem 1.2rem;
  line-height: 1;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  border: none;
  width: fit-content;
  position: relative;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & ~ & {
    margin-left: 0.8rem;
  }

  &.smallButton {
    height: 2.8rem;
    padding: 0.4rem 0.6rem;
    ;
  }

  &.callToActionButton {
    background-color: $callToAction;
    &:hover,
    &:focus {
      background-color: $callToActionHover;
      transition: background-color .2s;
    }
    &:active {
      background-color: $callToActionActive;
    }
  }

  &.disabledButton {
    background-color: $disabled;
    cursor: default;
    &:hover,
    &:focus {
      background-color: $disabled;
    }
    &:active {
      background-color: $disabled;
    }
  }

  &.secondaryButton {
    &:hover,
    &:focus {
      background-color: $backgroundHover;
      transition: background-color .2s;
    }
    &:active {
      background-color: $backgroundActive;
    }
  }

  &.disabledSecondaryButton {
    background-color: transparent;
    border: 3px solid $cloud;
    color: $hint;
    cursor: default;
    &:hover {
      color: $hint;
      background-color: transparent;
    }
    &:active {
      color: hint;
      background-color: transparent;
    }
  }

  &.dynamicActive {
    background-color: $background;
    &:hover {
      background-color: $background;
      transition: background-color .2s;
    }
    &:active {
      background-color: $background;
    }
  }
}

.buttonLoadingState {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.3rem;
  height: 1.3rem;
}

.hidden {
  visibility: hidden;
}
