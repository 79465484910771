@import "components/atoms/colours.scss";
@import 'components/atoms/borders.scss';

.commentField {
  color: $primary;
  min-height: 4.8rem;
  width: 100%;
  padding: 1.4rem;
  border-radius: 0.2rem;
  border: $defaultInputBorderStyle;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.2rem;
  resize: vertical;
  margin-top: 0.2rem;
  &:focus {
	  border: $activeInputBorderStyle;
  }

  &::placeholder {
    color: $disabledText;
  }
}

.error {
  border: $warningInputBorderStyle;
  &:focus {
	  border: $warningInputBorderStyle;
  }
}

.disabled {
  cursor: not-allowed;
  color: $disabled;
  border-color: $border;
  background-color: $background;
  &:hover {
    cursor: not-allowed;
  }
}
