@import 'components/atoms/colours.scss';

.hoursDisplay {
  padding: 0.6rem 0 0;
  font-size: 1rem;
  line-height: 1.4;
  color: $hint;
  width: 100%;
  display: flex;
  justify-content: center;
}

.error {
  color: $salmon;
}

.hoursAvailable {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.hoursPending {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: left;
}

.hoursTotal {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
