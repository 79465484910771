@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.session {
  background-color: $background;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
}

.layoutBody {
  display: grid;
}

.hidden {
  display: none;
}

.layoutLinks {
  display: flex;
  border-bottom: 1px solid $border;
  background-color: white;
}
.layoutCss {
  display: flex;
  max-width: 90rem;
  min-width: 890px;
  margin: auto;
}
.links {
  padding-top: 0.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  @include for-phone-only {
    padding-top: 1rem;
    left: 0;
    top: 5rem;
    top: calc(5rem + constant(safe-area-inset-top)); /* iOS 11.0 */
    top: calc(5rem + env(safe-area-inset-top)); /* iOS 11.2 */
    z-index: 11;
    background: $contrast;
    overflow-x: scroll;
  }

  @media print {
    display: none;
  }
}

.link {
  margin: 0 1.6rem;
}

.layoutRecording {
  margin-left: auto;
  margin-bottom: 5px;
}
.recording {
  color: #207A8D;
  width: 124px;
  height: 28px;
  border: 2px solid #207A8D;
  border-radius: 25px;
  display: flex;
  background-color: white;
  align-items: center;
  font-weight: 600;
  padding: 0.1rem 1.5rem;
  cursor: pointer;

  .palyIcon {
    margin-right: 0.8rem;
    font-size: 2rem;
  }
}