@import 'components/atoms/colours.scss';

.radioInputStyle {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  padding-left: 0.4rem;
}

.imageOptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.imageOptionLabel {
  max-height: 50%;
}

.radio {
  width: 1.7rem !important;
  height: 1.7rem !important;
  margin-right: 1.2rem;
  &.unchecked {
    color: $disabled;
  }
  &.checked {
    color: $callToAction;
  }
}

.optionImage {
  max-width: 62rem;
}

.reactRadio {
  display: none;
}

.loading {
  min-width: 1.5rem;
  height: 1.7rem;
  margin-right: 1.2rem;
  margin-left: 0.2rem;
}
