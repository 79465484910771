@import 'components/atoms/colours.scss';

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.2rem;
  color: $contrast;
}

.tagText {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
  color: inherit;
}

.small {
  height: 1.4rem;
  padding: 0rem 0.2rem;
}

.regular {
  height: 1.8rem;
  padding: 0 0.5rem;
}

.minWidth {
  min-width: 7.4rem;
}

.default {
  background-color: $secondary;
}

.success {
  background-color: $success;
}

.warning {
  background-color: $warning;
}

.disabled {
  background-color: $disabledText;
}

.draft {
  background-color: $transparentTiffany;
  color: $secondary;
}

.strokeBlue {
  background-color: #fff;
  color: $sky;
  border: 0.1rem solid $sky;
}

.fillBlue {
  background-color: $sky;
  color: #fff;
  border: none;
}

.strokeGrey {
  background-color: #fff;
  color: $stone;
  border: 0.1rem solid $stone;
}

.fillGrey {
  background-color: $stone;
  color: #fff;
  border: none;
}

.strokeGreen {
  background-color: #fff;
  color: $tiffany;
  border: 0.1rem solid $tiffany;
}

.fillGreen {
  background-color: $tiffany;
  color: #fff;
  border: none;
}

.strokeJellyBean {
  background-color: #fff;
  color: $jellyBean;
  border: 0.1rem solid $jellyBean;
}

.fillJellyBean {
  background-color: $jellyBean;
  color: #fff;
  border: none;
}

.fillCheddar {
  background-color: $cheddar;
  color: #fff;
  border: none;
}