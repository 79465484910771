@import "../typography.scss";

@mixin input-base($variant: "default", $type: "input") {
    @extend .typography;
    @include set-typo-for-body-02();
    
    color: var(--#{$type}_#{$variant}_color);
    background-color: var(--#{$type}_#{$variant}_color_bkg);
    border: var(--#{$type}_#{$variant}_color_border);    

    border-style: solid;   
    border-width: var(--#{$type}_border_width);

    > span {
        color: var(--#{$type}_#{$variant}_color);
    }

    &:hover {        
        background-color: var(--#{$type}_#{$variant}_color_bkg_hover);
    }
}

@mixin textbox-alike-base() {    
    border-radius: var(--text-box_border_radius);
    box-sizing: border-box;               
    height: 43px; 
    @include set-typo-for-body-02();   
}

@mixin textbox-alike() {
    @include textbox-alike-base();

    padding: 13px 20px 12px 20px;
    
    background-color: var(--input_primary_color);
    border: 1px solid var(--text-box_border_color);
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:active, &:focus, &:hover {
        box-shadow: 0 3px 6px 0 rgba(61, 64, 144, 0.23);
        border-color: var(--input_primary_color_border);
    }
}

@mixin textbox-alike-label() {  
    @include set-typo-for-body-02();
    font-weight: 600;
    color: var(--input_label_color); 
    position: relative;
    margin-bottom: 14px;
}

@mixin box-shadow() {
    box-shadow: 0px 2px 4px -1px RGB(0 0 0 / 20%), 0px 4px 5px 0px RGB(0 0 0 / 14%), 0px 1px 10px 0px RGB(0 0 0 / 12%);
}

@mixin button-base($variant: "default", $type: "input") {
    @extend .typography;
    
    @include input-base($variant, $type);

    &.MuiButton-sizeSmall {
        font-size: var(--input_font-size-small);
    }

    &.MuiButton-sizeLarge {
        font-size: var(--input_font-size-large);
    }
}