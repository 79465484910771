@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.calendar {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @include for-phone-only {
    display: block;
    height: calc(100vh - 9rem);
  }
}

.header {
  color: $primary;
  padding: 2.5rem 3rem 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $defaultBorderStyle;
}

.warningButton {
  composes: warning from 'styles/buttons.scss';
  font-weight: bold;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: row;

  @include for-phone-only {
    flex-direction: column;
  }
}

.viewSwitcher {
  display: none;

  @include for-phone-only {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px $chromium;
    padding: 0.6rem 1.5rem;
  }
}
.calendarIcon{
  color: #207a8d;
  position: absolute;
  right: 28rem;
  cursor: pointer;
  svg {
    vertical-align: middle;
    margin-right: 0.2rem;
  }
}
.calBlue {
  color: #207a8d;
}
.calendarSyncText{
  font-size: 12px;
}
.flexItem{
  display: flex;
}