@mixin custom-scrollbar($backgroundColor, $thumbColor: rgba(0,0,0,.3)) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: $backgroundColor;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $thumbColor;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

%scrollbar {
  @include custom-scrollbar(rgba(0,0,0,.1), rgba(0,0,0,.5));
}

%scrollbar-transparent {
  @include custom-scrollbar(transparent);
}