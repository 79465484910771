@import 'components/atoms/colours.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 5.4rem;
  background-color: $background;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.9rem 1.6rem 0.8rem 2rem;
  justify-content: space-between;
  box-sizing: border-box;
}

.loadingBarOverlay {
  height: 3px;
  width: 100%;
  background-color: $backgroundHover;
}

.loadingBar {
  background-color: $success;
  height: 100%;
  max-width: 100%;
  min-width: 0%;
}

.loadingBarTransition {
  > .loadingBar {
    transition: width 100ms linear;
  }
}

.done {
  color: $success;
  margin-right: 0.6rem;
}

.loadingError {
  background-color: $warning;
}

.errorMessage {
  margin-left: 0.4rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actionSpacer {
  width: 1.6rem;
  height: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
