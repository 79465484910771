@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

$inputHeight: 5rem;
$inputHeightMobile: 4rem;

.autocompleteInputWrapper {
  padding: 1rem 3.5rem 1rem 1.5rem;
  width: 100%;
  height: 5rem;
  border: 0.1rem solid $stone !important; //needs to replace with atomic colour later
  box-sizing: border-box;
  font-size: 1.2rem;
  color: $primary;
  font-family: inherit;
  border-radius: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  @extend %scrollbar-transparent;

  &.inputError {
    border: $warningInputBorderStyle !important;
  }

  &.disabled {
    cursor: not-allowed;
    background: $cloud;
  }

  &.isOpen {
    border: $activeInputBorderStyle !important;
    border-width: 0.1rem;
    border-radius: 0.2rem 0.2rem 0 0;
  }

  @include for-phone-only {
    height: $inputHeightMobile;
    padding: 1rem 3rem 1rem 1rem;
  }
}

.autoCompleteInput {
  border: none;
  flex-shrink: 100;
  input {
    font-family: inherit;
    font-size: 1.2rem;
    color: $primary;
  }

  &:focus {
    & + .searchIcon {
      color: $primary;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: $cloud;
  }

  &::placeholder {
    color: $hint;
  }

  & .searchIcon {
    font-size: 2rem;
    color: $inputBorder;
    cursor: text;
    margin-right: 1rem;
  }
  & .dropdownIcon {
    font-size: 2rem;
    margin-right: 1rem;
    &:hover {
      background: $backgroundHover;
      transition: background-color 0.2s;
      border-radius: 50%;
    }
  }
}

.autocompleteMenu {
  position: absolute;
  z-index: 11;
  background: #fff;
  width: 100%;
  max-height: 28rem;
  overflow-y: auto;
  border: $activeInputBorderStyle;
  border-width: 0 0.1rem 0.1rem 0.1rem;
  border-radius: 0 0 0.2rem 0.2rem;
  box-sizing: border-box;

  @extend %scrollbar;
}

.menuItem {
  padding: 1.5rem;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  color: $primary;
  font-family: inherit;
}

.noItems {
  padding: 1.5rem;
}

.highlighted {
  background: $cloud;
  color: $slate;
}

.pillWrapper {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;

  .multiPills {
    border: $defaultInputBorderStyle;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0 0.5rem 0.5rem 0;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .closeIcon {
    margin-left: 0.3rem;
    font-size: 1rem;
    color: $primary;
  }
}

.clearIcon {
  position: absolute;
  right: 0.4rem;
}
