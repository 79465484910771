@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.header {
  display: flex;
  padding: 1.6rem 3.2rem;
  background-color: #444FC2;

  @include for-phone-only {
    display: none;
  }
}

.main {
  grid-area: main;
  overflow: hidden;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #F8F8FB;
}

.border {
   border-bottom: $defaultBorderStyle;
}