@import 'components/atoms/colours.scss';

.studentContainer {
  color: $primary;
  display: flex;
  text-decoration: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  

  &:hover {
    .nameText {
      text-decoration: underline;
    }
  }
}

.roleText {
  color: $slate;
  font-size: 1rem;
}

.nameContainer {
  margin-left: 1.6rem;
}