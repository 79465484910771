@import 'components/atoms/colours.scss';

.gradesTable {
  margin-bottom: 2rem;
}

.newButton {
  margin: 1.5rem;
}

.table {
  border-bottom: solid 1px $chromium;
}

.overallTable {
  border-top: solid 0.8rem $background;
}