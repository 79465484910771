@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/generic/Forms/InputField/styles.scss';
@import 'components/unique/ProfilePage/profileItem.scss';
@import 'components/atoms/responsiveBreakpoints.scss';


$item-height: 60px;

.chatFormInput {
  padding: 0;
  flex: 1;

  @include for-phone-only {
    flex: 0 0 auto;
    overflow: auto;
    max-height: 10rem;
  }
}

.dropdownIcon {
  fill: $primary;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 50%;
  right: .8rem;
  transform: translate(0, -1rem);
  pointer-events: none;
}

.magnifyingGlass {
  fill: $primary;
}

.contactList {
  @include for-phone-only {
    flex: 1 1 auto;
    overflow: auto;
  }
}

.peopleItemContainer {
  height: $item-height;
  display: flex;
  padding: 1rem 2rem;
  cursor: pointer;
}

.peopleItemContainer:hover {
  background-color: $background;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.peopleItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.newChatContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  @include for-phone-only {
    max-height: calc(100vh - 12.5rem);
  }
  @include for-iphonex-only {
    max-height: calc(100vh - 17.5rem);
  }

  :global {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      background-color: rgba(0,0,0,.1);
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }
}

.groupName {
  margin-top: 1rem;

  input {
    height: 4rem;
  }
}

.labelGroup {
  margin-top: 1rem;
  label {
    color: #6d6d77;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
  }
  p {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #F0F0F0;
    padding: 1rem;
  }
}