@import 'components/atoms/responsiveBreakpoints.scss';
.pager {
  margin-top: 1rem;
  text-align: center;
}
  
.avatar {
  width: 3.4rem;
  height: 3.4rem;
  margin-right: 1rem;
}
  
.avatarContainer {
  display: flex;
  align-items: center;
}

.mobileStyle{
  display: none;
  @include for-phone-only {
    display: inline;
  }
}
