@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import '../styles.scss';

$inputHeight: 5rem;

.inputStyle {
  position: relative;
}

.formInput {
  flex: 1;
  height: $inputHeight;
  line-height: $inputHeight;
  width: 100%;
  color: $primary;
  font-family: inherit;
  font-size: 1.4rem;
  padding: 0 .8rem;
  box-sizing: border-box;
  cursor: default;
}

.formInputActive {
  border: $activeInputBorderStyle;
}

.uploadButton {
  margin: 8px;
}

.uploadIcon {
  width: 2rem;
  height: 2rem;
  fill: $contrast;
}

.inputMessage {
  margin-top: .8rem;
	font-size: 1rem;
  padding-bottom: .6rem;
  color: $hint;
  text-align: right;
}

.uploadRejected {
  color: $warning;
}

.filePreview {
  margin-top: .8rem;
  color: $primary;
  font-weight: bold;
}

.fileName{
  display: flex;
  align-items: center;
}

.removeFileBtn {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.5rem;
  padding: 0;
  border: none;
  line-height: .8;
  text-align: center;
  font-size: 1.6rem;
  background: $contrast;
  color: $callToAction;
  cursor: pointer;

  &:hover {
    color: $contrast;
    background-color: $callToAction;
  }
}

.formControlWrapper {
  border-radius: 2px;
  border: $defaultInputBorderStyle;
}
