@import 'components/atoms/colours.scss';

.messageWrapper {
  display: flex;
  padding: 0.8rem 0;
  transition: all .6s;
}

.messageAnim {
  transform: translateX(20px);
  // background-color: #fafafa;
}

/* Contains the name + message */
.info {
  flex: 1;
  position: relative;
}

.messageDetails {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}

.profilePic {
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
}

.userName {
  font-weight: bold;
  color: $primary;
  margin-right: 1rem;
  font-size: 11pt;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.timestamp {
  color: $hint;
  flex: 1 0;
  opacity: 0.6;
}

.progress {
  color: $hint !important;
}


.messageGroupIndicators {
  position: absolute;
  right: 0;
  bottom: 0;
}

.sentIndicator {
  color: $sky;
}

.loadingCircle {
  width: 1rem;
  height: 1rem;
}

.verticalLine {
  float: left;
  padding-right: 1rem;
  border-left: 1px solid $hint;
  height: 1rem;
  opacity: 0.6;
}

.botMessage {
  margin: auto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  font-family: Montserrat;
}