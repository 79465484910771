.textWithIconWrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: inherit;
}

.icon {
  color: inherit;
  margin-right: 0.8rem;
}

.text {
  line-height: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}