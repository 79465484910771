@import 'components/atoms/responsiveBreakpoints.scss';

.wrapper {
  position: relative;
  padding: 0;
  margin: 0 1.6rem 0 0;
  max-width: 4.8rem;
}

.large {
  font-size: 1.5rem;
  height: 4.8rem;
  width: 4.8rem;
  img {
    width: 4.8rem;
  }

  @include for-phone-only {
    height: 4rem;
    width: 4rem;
  }
}

.small {
  height: 3.2rem;
  width: 3.2rem;
  img {
    width: 3.2rem;
  }
}

.imageAvatar {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.initialsAvatar {
  overflow: hidden;
  border-radius: 50%;
}

.otherPeople {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  left: 2.8rem;
  top:  2.8rem;
  border-radius: .5rem;
  overflow: hidden;
}

.otherPeopleText {
  color: white;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
}

.otherPeopleTextContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

.overlayDiv {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgba(90, 90, 90, 0.5);
  width: 2.5rem;
  height: 2.5rem;
}

.secondaryAvatar {
  position: absolute;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.vipTag{
  position: absolute;
  bottom: -0.3rem;
  right: -0.3rem;
}
