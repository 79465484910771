@import 'components/atoms/colours.scss';

.progressBar { 
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  height: 2px;
  background-color: $callToActionLighter;
  opacity: 0;
  transition: opacity 250ms ease;
  transition-delay: 500ms;
}

.progressBarInner {
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  transform-origin: left;
  background-color: $callToAction;
}

.progressBarLoading {
  opacity: 1;
  transition: none;

  > .progressBarInner {
    transition: none;
  }
}

.progressBarLoadingTransition {
  > .progressBarInner {
    transition: width 400ms linear; 
  }
}
