@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.navIconLinkWrapper {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $backgroundHover;
    @include for-phone-only {
      background-color: white;
    }
  }
}

.navIconLinkIcon {
  vertical-align: middle;
  color: $primary;
  font-size: 2rem;
  
  @include for-phone-only {
    color: $primary;
    font-size: 2rem;
  }
}

.navIconLinkText {
  font-size: 1rem;
  color: #fff;
  user-select: none;
  -webkit-user-select: none;
}
