@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.radios {
  font-size: 1.2rem;
  color: $primary;
  margin-top: 2.4rem;

  input[type=radio] {
    margin: 0.8rem 1.6rem 0.8rem 0;
  }
}

.commentBox {
  margin: 2.4rem 0 0;

  label {
    color: $secondary;
    font-size: 1rem;
  }

  textarea {
    border-radius: 3px;
    min-height: 4.8rem;
    border: $defaultInputBorderStyle;
  }
}

.commentInvalid {
  textarea {
    border: $warningInputBorderStyle;

    &:focus {
      border: $warningInputBorderStyle;
    }
  }
}

.errorMessageContainer {
  margin: 0.5rem 0 0.8rem;
  height: 1.2rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.buttons {
  text-align: right;
}
