@import "components/atoms/colours.scss";

.eulaText {
  color: $slate;
  margin-left: -1rem;
  margin-right: -1rem;
}

.checkboxText {
  position: absolute;
  font-size: 1rem;
  display: inline-block;
  margin-top: 0.5rem;
  color: $slate;
}