@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';


.confirm {
  width: 40rem;
  color: $primary;
  font-size: 1.2rem;
  margin: 1.6rem 0 0;
}

.incorrectLink {
  color: $callToAction;
  margin-right: 2.4rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.buttons {
  text-align: right;
}

.eventSummary {
  margin: 1.6rem 0;
}

.reasonBox {
  color: $secondary;
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 2rem 0;
}

.reasonBoxText {
  font-size: 1.2rem;
  color: $primary;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 4.8rem;
  max-height: 15rem;
  border: $defaultBorderStyle;
  border-radius: 3px;
  padding: 1.6rem 1.6rem;
}

.adjustTime {
  margin-top: 1.6rem;
}

.changedTime {
  text-decoration: line-through;
}

.invalidReasonBox {
  textarea {
    border-color: $warning;

    &:focus {
      border-color: $warning;
    }
  }
}

.errorMessageContainer {
  margin: 0.5rem 0 0.8rem;
  height: 1.2rem;
}

.errorMessage {
  font-size: 1rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}

.comments {
  margin-top: 1rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
}
