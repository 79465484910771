@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.block {
  position: absolute;
  color: $contrast;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
  padding: 0.3rem 0.5rem;
  margin: 0.1rem;
  border-radius: 0.2rem;

  .description {
    font-weight: 600;
  }

  .person {
    font-size: 0.8rem;

    @include for-phone-only {
      font-size: 0.7rem;
    }
  }
}

.textContent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 1.0rem;
  text-align: left;
  margin-bottom: 0.2rem;
  font-size: 1.0rem;

  @include for-phone-only {
    white-space: nowrap;
    font-size: 0.8rem;
  }
}

.external {
  &.confirmed {
    &.inProgress{
      border-color: $pinkOrange;
    }
    background-color: $pinkOrange;
    .textContent {
      color: $contrast;
    }
    &.past {
      background-color: $transparentPinkOrange;
      .textContent {
        color: $contrast;
      }
    }
  }
  &.pending {
    background-color: $contrast;
    border: solid 0.1rem $pinkOrange;
    &.inProgress{
      border-color: none;
    }
    .textContent {
      color: $pinkOrange;
    }
    &.past {
      border-color: $transparentPinkOrange;
      .textContent {
        color: $transparentPinkOrange;
      }
    }
  }
}

.confirmed {
  background-color: $jellyBean;

  &.past {
    background-color: $stone;

    .textContent {
      color: $contrast;
    }
  }
  
  .textContent {
    color: $contrast;
  }
}

.completed {
  background-color: $stone;

  .textContent {
    color: $contrast;
  }
}

.pending {
  background-color: $contrast;
  border: solid 0.1rem $jellyBean;

  &.past {
    border-color: $stone;

    .textContent {
      color: $stone;
    }
  }

  .textContent {
    color: $jellyBean;
  }
}

.inProgress {
  background-color: $sky;
  border: solid 0.1rem $sky;

  .textContent {
    color: $contrast;
  }
}

/*
  Conditional styles.
  These are only applied if the booking fulfills
  the specific requirements for each element
  in index.js.
*/

.invisible {
  display: none;
}

.marginTop {
  margin-top: .7em;
}

.pending {
  .colorChange {
    color: $jellyBean;
  }
}

.confirmed {
  .colorChange {
    color: $contrast;
  }
}

.completed {
  .colorChange {
    color: $contrast;
  }
}

.continued {
  border-top: none;
}

.continuing {
  border-bottom: none;
}

.smallText {
  font-size: 0.8rem;
}
