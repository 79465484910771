@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas:
    "navigation main actions"
    "sub sub sub";
  padding: 1.6rem 3.2rem;

  @include for-phone-only {
    display: none;
  }
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navigation {
  grid-area: navigation;
  justify-content: flex-start;

  &>* {
    margin-right: 2.4rem;
  }
}

.main {
  grid-area: main;
  overflow: hidden;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subtitles {
  grid-area: sub;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  min-height: 3.2rem;
}

.subtitle {
  margin-right: 3.2rem;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
}

.subtitleWithIcon {
  display: flex;
  align-items: center;
  > * {
    margin-right: 0.5rem;
  }
}

.actions {
  grid-area: actions;
  justify-content: flex-end;

  &>* {
    margin-left: 2.4rem;
  }
}

.border {
   border-bottom: $defaultBorderStyle;
}

.backButton {
  @media print {
    display: none;
  }
}

.actionButtons {
  @media print {
    display: none;
  }
}

.mobileButton {
  display: none;
  color: #fff;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: $layerFloatingButton;
  background: $callToAction;
  border-radius: 2.3rem;
  box-shadow: 0 0.2rem 0.2rem $stone;

  width: 4.6rem;
  height: 4.6rem;

  @include for-phone-only {
    display: flex;
  }
}