@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.invoiceItem {
  border-bottom: $defaultBorderStyle;
  padding: 1.5rem 1rem 1.5rem 0;
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }

  @include for-phone-only {
    padding: 1.5rem;
  }
}

.itemDetails {
  & > *:nth-child(2) {
    margin-bottom: 1rem;
  }
}

.itemStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include for-phone-only {
    align-items: flex-end;
    text-align: right;
  }
}

.monies {
  color: $slate;
}

.iconDone {
  width: 2rem;
  font-size: 1.5rem;
  color: $success;
}

.iconIncomplete {
  composes: iconDone;
  color: $warning;
}

.iconNeutral {
  composes: iconDone;
  color: $secondary;
}