@import 'components/atoms/borders.scss';

.subjectSelect {
  :global {
    .Select-control {
      height: 4.2rem;
      padding-left: 1.6rem;
      border: $defaultInputBorderStyle;
      border-radius: 0.2rem;
      z-index: 2;

      .Select-multi-value-wrapper {
        .Select-input {
          padding-left: 0;
        }

        .Select-input > input {
          padding: 1.2rem 0 1.2rem;
        }

        .Select-value {
          padding-left: 1.6rem;
        }
      }
    }

    .Select-menu-outer {
      .Select-menu {
        .Select-option {
          padding-left: 1.6rem;
        }
      }
    }
  }
}