.emptyStateOuter  {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 12rem;
}

.emptyStateInner {
  flex: 0 1 42rem;
}
