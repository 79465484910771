@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.calendarHeader {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 1.5rem 3rem;
  align-items: center;
  box-sizing: border-box;
  border-bottom: $defaultBorderStyle;
  
  
  @include for-phone-only {
    width: 100%;
    padding: 1rem 0.7rem;
    justify-content: space-between;
  }
}

.header {
  display: flex;
  flex-direction: horizontal;
  justify-content: center;
  margin-bottom: 20px;
  color: $primary;
  align-items: center;
  margin: 0;
  text-align: center;

  @include for-phone-only {
    padding: 0;
    margin: 0;
  }
}

.currentDay {
  font-size: 1.5rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1rem;
  @include for-phone-only {
    font-size: 1.2rem;
    max-width: 12rem;
  }
}



.arrowButton {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  margin-right: 1rem;

  &:hover {
    background-color: $backgroundActive;
    border-radius: 3px;
    color: $contrast;
  }

  @include for-phone-only {
    margin: 0 0.5rem;

    &:hover {
      background-color: transparent;
      color: $primary;
    }
  }
}


.hidden {
  visibility: hidden;
  pointer-events: none;
}

.calendarBlocks {
  display: block;
  width: 100%;
}

.todayButton {
  padding: 0.8rem;
  cursor: pointer;
  color: #454fc2;
  border-radius: 0.4rem;
  display: flex;
  font-weight: 600;
  background: #f4f6fa;
  margin-right: 1rem;
  border: solid 1px #454fc2;
  &:hover {
    background: $cloudDark;
  }


  @include for-phone-only {
    font-size: 1rem;
    margin-left: 0.7rem;
    padding: 0.5rem 0.5rem 0.2rem 0;
    background: #fff;

    &:hover {
      background: #fff;
    }
  }

  .todayText {
    font-size: 1.2rem;
    @include for-phone-only {
      display: none;
    }
  }
}

.flatpickr {
  display: flex;
}
.dayFlatPickrInput {
  visibility: hidden;
  width: auto;
  margin-left: 4rem;
  @include for-phone-only {
    width: 1px;
  }
}

.dayCalendarIcon{
  margin-top: -1rem;
  position: absolute;
}

.closeButton {
  margin-left: auto;
}

.calendarMobile{
  display: flex;
  align-items: center;
}