@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.sessionList {
  display: grid;
  grid-template-columns: minmax(auto, 58rem);
  grid-template-rows: auto;
}

.sessionCard {
  border: $defaultBorderStyle;
  padding: 2rem;
  margin: 2rem;
}

.empty {
  display: grid;
  grid-auto-rows: 1fr 1fr;
  margin-top: 25%;
  grid-row-gap: 2rem;
  justify-content: center;

  & > * {
    justify-self: center;
  }

  & > *:last-child {
    font-weight: 600;
    color: $slate;
    font-size: 1.6rem;
  }
}

.loadingIndicator {
  background: $background;
}

.sessionGroup {
  margin-top: 2.4rem;
}
.dateHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 3.2rem;

  @include for-phone-only {
    margin: 0 1.4rem 0.8rem 1.6rem;
  }
}