@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.flex-row {
  display: flex;
  flex-direction: row;
}

.avatarWrapper {
  composes: flex-row;
}

.avatarContainer {
  position: relative;
}

.avatar {
  border-radius: 50%;
  width: 100%;
  composes: flex-row;
}

.large {
  font-size: 1.5rem;
  height: 4.8rem;
  width: 4.8rem;

  @include for-phone-only {
    height: 4rem;
    width: 4rem;
  }
}

.normal {
  height: 3.4rem;
  width: 3.4rem;
}

.smallNormal {
  height: 1.5rem;
  width: 1.5rem;
}
.small {
  height: 1rem;
  width: 1rem;
}

.round {
  border-radius: 50%;
}

.vipTag {
  position: absolute;
  bottom: -0.3rem;
  right: -0.3rem;
}