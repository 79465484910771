@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.contents {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.leftNav {
  flex-basis: 30rem;
  width: 30em;
  border-right: $defaultBorderStyle;

  @include for-phone-only {
    width: auto;
    flex-basis: auto;
  }
}

.peopleSearch {
  height: 370px;
}

.peopleSearchWrapper {
  @include for-phone-only {
    display: none;
  }
}