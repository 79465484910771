@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.tabList {
  display: flex;
  padding: 0 3rem;
  border-bottom: 1px solid $border;
  justify-content: center;
  list-style: none;
  margin: 0px;

  @include for-phone-only {
    justify-content: flex-start;
    padding-top: 1rem;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.compact {
  padding: 0;
}
