@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.banner {
  min-height: 5.2rem;
  display: grid;
  justify-content: center;
  align-content: center;
}

.bannerContent {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;

  @include for-phone-only {
    padding-left: 1rem;
    align-items: center;
  }
}

.bannerText {
  padding-left: 0.4rem;

  @include for-phone-only {
    font-size: 1rem;
    max-width: 85%;
    margin: 0.2rem;
    padding: 0.2rem;
  }
}