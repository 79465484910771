@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/layers.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.calendarGrid {
  color: $hint;
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include for-phone-only {
    height: calc(100vh - 14rem);
    height: calc(100vh - 14rem - env(safe-area-inset-top));
    height: calc(100vh - 14rem - constant(safe-area-inset-top));
  }
  @include for-iphonex-only {
    height: calc(100vh - 16.6rem);
  }
  height: calc(100vh - 14rem);
}


.weekGrid {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  justify-content: flex-start;

  @extend %scrollbar-transparent;
}

.columnWidth {
  width: 100%;
}

.today {
  color: $sky;
  border-top: none;
}

.pastDate {
  color: $stone;
}

.swipeIndicator {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background: $chromium;
  color: #fff;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  z-index: $two;
  display: none;

  @include for-phone-only {
    display: flex;
    opacity: 0;

    animation: all 0.4s ease;
  }
}

.swipeLeft {
  left: 4rem;
}

.swipeRight {
  right: 1rem;
}

.showArrow {
  opacity: 1;
}


// WeekHeader styles

.weekHeaderRow {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: $primary;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 3px 0 3.6rem; // this 3px is the padding for the scrollbar
}

.weekHeader {
  padding: 1.2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-bottom: $defaultBorderStyle;

  @include for-phone-only {
    padding: 0.8rem 0;
  }

  .date {

    text-transform: uppercase;

    & > div:last-child {
      margin: 0 0.5rem;
      font-size: 2.4rem;
    }

    @include for-phone-only {
      flex-direction: column;

      & > div:first-child {
        font-size: 0.8rem;
      }

      & > div:last-child {
        font-size: 1.8rem;
      }
    }
  }
}

.indicators {
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 0.8rem;

  @include for-phone-only {
    margin-top: 0.3rem;
    font-size: 0.6rem;
  }

  .indicator {
    margin-right: 0.2rem;
    color: $jellyBean;
    &.external {
      color: $pinkOrange;
      &.past {
        color: $transparentPinkOrange;
      }
    }
    &.past {
      color: $stone;
    }
  }

  .indicator:last-child {
    margin-right: 0;
  }
}

.external {
  &.confirmed {
    &.inProgress{
      border-color: $pinkOrange;
    }
    background-color: $pinkOrange;
    .textContent {
      color: $contrast;
    }
    &.past {
      background-color: $transparentPinkOrange;
      .textContent {
        color: $contrast;
      }
    }
  }
  &.pending {
    background-color: $contrast;
    border: solid 0.1rem $pinkOrange;
    &.inProgress{
      border-color: none;
    }
    .textContent {
      color: $pinkOrange;
    }
    &.past {
      border-color: $transparentPinkOrange;
      .textContent {
        color: $transparentPinkOrange;
      }
    }
  }
}

.confirmed {
  background-color: $jellyBean;

  &.past {
    background-color: $stone;

    .textContent {
      color: $contrast;
    }
  }
  
  .textContent {
    color: $contrast;
  }
}

.completed {
  background-color: $stone;

  .textContent {
    color: $contrast;
  }
}

.pending {
  background-color: $contrast;
  border: solid 0.1rem $jellyBean;

  &.past {
    border-color: $stone;

    .textContent {
      color: $stone;
    }
  }

  .textContent {
    color: $jellyBean;
  }
}

.inProgress {
  background-color: $sky;
  border: solid 0.1rem $sky;

  .textContent {
    color: $contrast;
  }
}
.textContent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 1.0rem;
  text-align: left;
  margin-bottom: 0.2rem;
  font-size: 1.0rem;

  @include for-phone-only {
    white-space: nowrap;
    font-size: 0.8rem;
  }
}
.alldayBlock{
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.2rem;
}
.weekHeaderContainer{
  position: relative;
}
.allDay{
  position: absolute;
  bottom: 1.5rem;
}
