@import 'components/atoms/colours.scss';

.header {
  background: $background url(/static/media/profile_pattern.svg);
  padding: 1.6rem 0;
  padding-top: calc(constant(safe-area-inset-top) + 1.6rem);
  padding-top: calc(env(safe-area-inset-top) + 1.6rem);
}

.avatar {
  border-radius: 50%;
  margin: 0 2.3rem 1.6rem;
  width: 7rem;
}

.link {
  display: block;
  text-decoration: none;
}

.title {
  display: block;
  font-weight: 600;
  color: $primary;
  margin: 0 2.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixedHamburger {
  position: absolute;
  top: 0;
  left: 0;
  width: 18rem;
  height: 5.2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  :hover {
    color: white;
  }
}

.hamburgerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

.crimsonLogo {
  margin: 1.2rem 0 0 0.8rem;
  height: 3.2rem;
  fill: white;
}

.closeMenu {
  font-size: 2.5rem;
  position: absolute;
  right: 2rem;
  top: 1.8rem;
  color: $slate;
}

.dulwichLogo {
  height: 4rem;
  margin: 1.2rem 0 0 1.2rem;
}