@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/behaviours/transitions.scss';

.tabContainer {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  box-sizing: border-box;
  text-align: center;
  background: $contrast;
  * {
    box-sizing: border-box;
  }
}

.tab {
  position: relative;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  padding: 0.7rem;
  color: $primary;
  cursor: pointer;
  justify-content: center;
  border: 1px solid $primary;
  

  &:hover {
    background: $background;
  }
  svg {
    height: 1.6rem;
  }

  &:first-child {
    border-right: none;
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-left: none;
    border-radius: 0 3px 3px 0;
  }
}

.tabActive {
  background: $primary;
  border-color: $primary;
  &:hover {
    background: $primary;
  }
}

.label {
  position: absolute;
  top: calc(100% + 0.8rem);
  left: 0;
  width: 100%;
  opacity: 0;
  text-align: center;
  color: $primary;
}

.tabContent {
  color: $primary;
  font-weight: 600;
}

.tabActiveText {
  color: $contrast;
  z-index: 1;
  + .label {
    opacity: 1;
  }
}