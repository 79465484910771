@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.tabPanel {
  color: $primary;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;

  @include for-phone-only {
    width: 100vw;
    box-sizing: border-box;
  }
}

.tabPanelSelected {
  padding: 1.6rem 3.2rem;

  @include for-phone-only {
    padding: 1.6rem 1.6rem;
  }
}

.compact {
  margin: 2rem 0;
}
