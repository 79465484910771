@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.recurringSessionRoot {
  margin-bottom: 2rem;
}

.rGroup {
  display: flex;
  width: 80%;
  margin-top: 0.5rem;
  @include for-phone-only {
    width: 100%;
  }
}

.rButtonContainer {
  flex: 1 1 4.5rem;

  label {
    margin-bottom: 0;
  }
}

.recurringSettings {
  width: 100%;
  height: 140px;
  background-color: #F3F6FA;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;

  .recurringSettingItem {
    display: flex;
    width: 85%;
    align-items: center;

    .settingLabel {
      width: 8.5rem;
      font-weight: 600;
      flex: 0 0 auto;
    }
  }
}

.occurrencesInput {
  width: 183px;
  background-color: white;
  margin: 0;
  margin-right: 1.5rem;
  input {
    border-radius: 4px;
    padding-left: 12px;
    height: 42px
  }
}

.errorMessage {
  color: $salmon;
}

.normalMessage {
  color: $stone;
}
