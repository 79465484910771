@import 'components/atoms/colours.scss';

.adjustTime {
  color: $primary;
}

.timeSelectors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeSelectors > * {
  margin-right: .8rem;
}

.timeSelectors > :last-child {
  margin-right: 0;
}

.errorMessage {
  font-size: 1rem;
  margin: 0.5rem 0 0.8rem;
  color: $warning;
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: $warning;
  margin-right: 1.2rem;
}
