@import 'components/atoms/colours.scss';

.modalBanner {
  padding: 1.5rem;
  color: #fff;
}

.info {
  background: $primary;
}

.success {
  background: $tiffany;
}

.warning {
  background: $salmon;
}

.error {
  background: $orange;
}