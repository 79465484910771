@import 'components/atoms/colours.scss';

svg.icon {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}

.secondaryText {
  display: flex;
  color: $charcoal;
  align-items: center;
}