
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.overdueContainer {
  color: $salmon;
  margin-bottom: 1rem;
  display: flex;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  @include for-phone-only {
    display: none;
  }
}

.lessonContainer {
  display: flex;
  flex-wrap: wrap;
}

.lessonBlockContainer {
  margin-right: 7rem;
  margin-bottom: 1rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
  color: $primary;
}

.subtitle {
  color: $slate;
}

.rightAligned {
  margin-left: auto;
}
