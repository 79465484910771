@import 'components/atoms/colours.scss';

.circle {
  width: 1.3rem;
  height: 1.3rem; 
}

.contrast {
  color: $contrast !important;
}
