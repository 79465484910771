@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.session {
  background: $jellyBean;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 1rem;

  & > div {
    padding: 1rem 1.5rem;

    @include for-desktop-only {
      padding: 0 2rem;
    }
  }

  &.inProgress {
    background: $sky;
  }

  &.sessionVideoLink {
    grid: 6rem 3.8rem / auto 12rem;

    &.inProgress {
      grid-template-rows: 6rem;

      .testLink {
        display: none;
      }
    }

    @include for-desktop-only {
      grid-template-columns: auto 15rem 15rem;
    }
  }

  .testLink {
    grid-column-end: span 3;
    background-color: $deepSea;
    color: $white;
    height: 100%;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    a {
      color: $white;
      font-weight: 600;
    }

    @include for-desktop-only {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 4px 4px;
      width: unset;
    }
  }

  @include for-desktop-only {
    display: grid;
    grid: 6rem / auto;
    border-radius: 4px;
  }
}

.messageContainer {
  display: flex;
  align-items: center;
  padding-bottom: 0;

  .message {
    color: #fff;
    margin-left: 1rem;
  }

  .timerIcon {
    font-size: 1.8rem;
  }
}

div.joinBtnCtn {
  padding-top: 0;
}

.joinBtn {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 600;
  background: #fff;
  color: $jellyBean;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  border: none;

  cursor: pointer;

  &:hover {
    background: $cloud;
  }

  .videoIcon {
    margin-right: 1rem;
    font-size: 1.6rem;
    color: $jellyBean;
  }

  &.inProgress {
    color: $sky;

    .videoIcon {
      color: $sky;
    }
  }

  &:disabled {
    background: $mist;
    color: $slate;
    cursor: not-allowed;

    .videoIcon {
      color: $slate;
    }
  }
}

.sessionVideoTooltip {
  width: 12rem;
  padding: 0.8rem;
  text-align: center;
  font-size: 1rem;
}

.invisible {
  display: none;
}

.shareLinkIcon {
  &:hover {
    cursor: pointer;
  }
}

.refreshMeetingLink {
  display: flex;
  align-items: center;
  font-size: 1.3rem;

  cursor: default;

  .refreshIcon {
    margin-right: 1rem;
    font-size: 1.8rem;

    cursor: not-allowed;

    &:not([disabled]) {
      cursor: pointer;
    }
  }

  .refreshSuperscript {
    display: none;

    @include for-desktop-only {
      display: inline-block !important;
      float: right;
    }
  }

  .addMobileText {
    display: inline-block;

    @include for-desktop-only {
      display: none !important;
    }
  }
}