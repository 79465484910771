@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import '../styles.scss';

$inputHeight: 5rem;
$inputHeightMobile: 4rem;

.dropdownWrapper {
  position: relative;
  color: $primary;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
  .chevron {
    right: 0.8rem;
    position: relative;
  }
}

.dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  height: $inputHeight;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.6rem;
  border: $defaultInputBorderStyle;
  border-radius: 0.2rem;

  @include for-phone-only {
    height: $inputHeightMobile;
    padding: 1rem;
  }
}
.active {
  border: $activeInputBorderStyle;
}
.placeholderText {
  flex-grow: 1;
  width: 0rem; /* Required to trigger white-space: nowrap,
  does not set the actual width value as this is overridden by flex-grow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $hint;

  &.hasSelected {
    color: $primary;
  }
}

.chevron {
  font-size: 2rem;
}

.select {
  width: 100%;
  display: block;
  position: absolute;
  background-color: $contrast;
  border: $activeInputBorderStyle;
  top: calc(100% - 0.1rem);
  color: $primary;
  box-sizing: border-box;
  cursor: pointer;
  max-height: 34.4rem;
  overflow-y: scroll;
  z-index: 1;
}

.option {
  display: flex;
  align-content: center;
  align-items: center;
  height: 3.4rem;
  padding: 0.4rem 1.6rem;

  &:hover{
    background-color: $background;
  }
}

.optionLabel {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxInputStyle {
  font-size: 2rem;
  margin-right: .8rem;
  color: $callToAction;
  cursor: pointer;
}


/* temporary store this overide here, we may use this library later */
/* --- Start of React select styles override --- */
/*
:global {
  .Select {
    .Select-control {
      display: table;
      border-radius: 2px;
      height: $inputHeight;
      font-family: inherit;
      border: $defaultBorderStyle;

      .Select-input {
        height: $inputHeight;
      }

      .Select-value {
        padding-left: 5px;
        line-height: $inputHeight;
      }

      .Select-clear-zone > .Select-clear {
        margin-top: -2px;
      }
    }

    &.is-open > .Select-control {
      border: $defaultBorderStyle;
    }

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      line-height: $inputHeight;
      padding-left: 5px;
    }
  }

  .Select-menu-outer {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

    .Select-option {
      padding: 5px;

      &.is-focused {
        background-color: $primary;
        color: $contrast;
      }
    }
  }
}
*/
/* --- End of React select style override --- */
