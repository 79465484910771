@import 'components/atoms/borders.scss';

.container {
  display: flex;
  max-width: 64rem;
  padding: 3.2rem;
  margin: auto;
  flex-flow: column;
}

.form {
  flex-grow: 1;
  margin-top: 1.6rem;
}

.half {
  display: inline-block;
  width: 49%;
}

.marginRight {
  margin-right: 2%;
}

.footer {
  text-align: right;
}

.nextButton {
  position: relative;
}

.buttonLoadingState {
  position: absolute;
  top: 1rem;
  left: calc(50% - 0.6rem);
}

.hide {
  display: none;
}

.label {
  color: $slate;
  font-family: inherit;
  font-size: 1rem;
}

.option {
  display: flex;
  align-content: center;
  margin-bottom: 1rem;

  input {
    margin: 0 0.5rem 0 0;
  }
}

.warning {
  svg {
    display: none;
  }
  div[class^='warning'],
  div[class*=' warning'] {
    font-weight: normal;
  }
}
