@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/scrollbar.scss';

.container {
  position: relative;
  display: grid;
  grid-template-areas: "header header" "sidebar main";
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(min-content, 40rem) 1fr;
  grid-gap: 0.1rem 0.1rem; /* 1px border is really the background showing through */
  box-sizing: border-box;
  height: calc(100% - 10.2rem);
  background-color: $border;

  @include for-phone-only {
    grid-template-areas: "header" "sidebar" "main";
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    height: auto;
  }
}

.header {
  grid-area: header;
  display: flex;
  flex-flow: row wrap;
  padding: 0.8rem;
  background-color: white;
  > * {
    margin: 0.8rem;
  }
}

.sidebarContainer {
  grid-area: sidebar;
  background-color: white;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sidebarScroll {
  overflow-y: auto;
  flex-grow: 1;
  @extend %scrollbar;
}

.sidebar {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 1.6rem;
  padding: 1.6rem;
  align-content: start;
}

.main {
  grid-area: main;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  background-color: white;
  overflow-y: auto;
}