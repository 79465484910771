@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 0.2rem;

  &.primary {
    color: $contrast;
    .circle {
      background-color: $callToAction;
    }
    &:hover .circle, &:focus .circle {
      background-color: $callToActionHover;
    }
    &:active .circle {
      background-color: $callToActionActive;
    }
  }

  &.primary.disabled {
    color: $contrast;
    .circle {
      background-color: $disabled;
      cursor: default;
    }
    &:hover .circle, &:focus .circle {
      background-color: $disabled;
    }
    &:active .circle{
      background-color: $disabled;
    }
  }

  &.secondary {
    color: $callToAction;
    .circle {
      background-color: $background;
    }
    &:hover .circle, &:focus .circle {
      background-color: $backgroundHover;
    }
    &:active .circle {
      background-color: $backgroundActive;
    }
  }

  &.secondary.disabled {
    color: $hint;
    .circle {
      background-color: transparent;
      cursor: default;
    }
    &:hover .circle, &:focus .circle {
      background-color: transparent;
    }
    &:active .circle {
      background-color: transparent;
    }
  }
}

.circle {
  position: absolute;
  border-radius: 100px;
  border: none;
  /* Padding takes its % from the width of the element. These 2 lines cause
   the element to maintain aspect ratio as it scales.
   see: https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css */
  width: 100%;
  padding-bottom: 100%;
}

.icon {
  position: relative;
  color: inherit;
}


