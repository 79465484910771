$indigo: #464ac9;

.selectComponent {
  position: relative;
}
.inputTab {
  width: 100%;
  border: 1px solid #e3e7ed;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background: white;
  input {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
}
input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
input:focus {
  outline: none;
}
.expandMoreIcon {
  cursor: pointer;
  color: #a9acc0;
}
.dropdownList {
  position: absolute;
  z-index: 99;
  width: 100%;
  background: white;
  border: 1px solid #e3e7ed;
  border-top: none;
  max-height: 120px;
  overflow: scroll;
  li {
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #e3e7ed;
    text-align: left;
    padding: 0 20px;
    font-size: 14px;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $indigo;
    }
  }
}
.validationMessage {
  padding-left: 22px;
  text-align: left;
  color: #ed4b53;
}
