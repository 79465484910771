@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $defaultBorderStyle;
  padding: 0 2rem;
  height: 7rem;

  @include for-phone-only {
    padding: 1rem;
    height: auto;
    top: 5rem;
    top: calc(0rem + constant(safe-area-inset-top)); /* iOS 11.0 */
    top: calc(0rem + env(safe-area-inset-top));
    background: white;
    z-index: 100;
    width: calc(100vw - 2rem);
    position: fixed;
  }
  .people {
    display: flex;
    align-items: center;

    span {
      color: #464ac9;
      font-size: 14px;
    }
  }

  .peopleIcon {
    color: #464ac9;
  }
}

.headerDetails {
  min-height: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include for-phone-only {
    width: 100%;
    margin-left: 2rem;
  }

  h3 {
    color: $primary;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
    @include for-phone-only {
      font-size: 1.2rem;
    }
  }
  p {
    color: $secondary;
    font-size: 1.2rem;
    margin: 0.5rem 0 0 0;
    @include for-phone-only {
      font-size: 1rem;
      margin: 0;
    }
  }

  .mobileEdit{
    display: flex;
    align-items: center;
  }
}

.communityHeader {
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include for-phone-only {
    width: 100%;
    margin-left: 2rem;
  }

  h3 {
    color: $primary;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
    @include for-phone-only {
      font-size: 1.2rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  span {
    color: $secondary;
    font-size: 1.2rem;
    margin: 0.5rem 0 0 0;
    @include for-phone-only {
      font-size: 1rem;
      margin: 0;
    }
  }

}
.userList {
  margin: 1rem;
  padding: 1rem;
  max-height: 37rem;
  min-width: 20rem;
  max-width: 30rem;
  overflow: auto;

  @include for-phone-only {
    max-height: 50vh;
    min-width: 0;
    max-width: 70vw;
  }

  @extend %scrollbar-transparent;

  .usersCount {
    font-size: 1.2rem;
    color: $slate;
    margin-bottom: 1rem;
  }
}


.messageBackButton {
  display: none;

  @include for-phone-only {
    display: block;
    margin: 0;
    & > div {
      padding: 0.5rem;
      margin-left: -0.5rem;
    }
  }
}

.mobileEditGroupName {
  display: flex;
  margin-top: 1.5rem;

  .iconStyle {
    height: 1.5rem;
    color: #ed4b53;
  }

  button {
    border: none;
    background: none;
    color: #ed4b53;
    padding: 0;
  }
}

.horizontalLine {
  margin-top: 1.5rem;
  width: 100%;
  border: 1px solid #e9ebf0;
}

.editGroupModal{
  width: 30rem;
  height: 25rem;
  border-radius: 15px;
  margin: 0;

  .groupNameInput {
    margin-top: 1rem;
  }
  .header {
    border-bottom: none;
  }
  .saveButton {
    padding: 1rem;
    margin-top: 1rem;
    margin-left: 40%;
    border-radius: 4px;
    background-color: #ed4b53;
    color: #FFFFFF;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }
}