@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.messageText {
  font-size: 1.4rem;
  color: $secondary;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.6;
  width: 100%;
  display: block;
  padding-top: 0.5rem;
  // overflow: auto;
  position: relative;
  .messageAction{
    visibility: hidden;
  }
  &:hover {
    .messageAction{
      visibility: visible;
    }
    background-color:  #F8F8F8;
  }
  &:after{
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.markdown {
  p {
    margin: 0;
  }
  font-size: 11pt;
  color: $primary;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.6;
  margin-right: 4rem;
  width: 85%;
  padding-left: 0.5rem;
  // float: left;
  @include for-phone-only {
    margin-right: 0rem;
  }
}

.imageBlock {
  align-content: flex-start;
  align-items: flex-start;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
}

.emoji {
  font-size: 2.2rem;
}

.largeEmoji {
  font-size: 4rem;
}

.indicators {
  bottom: 0;
  padding-bottom: 0.6rem;
  display: flex;
  align-items: flex-end;
  // float: right;
  & > * {
    margin-left: 0.1rem;
  }

  .seenByPlus {
    font-size: 0.8rem;
  }

  &.error {
    display: flex;
    align-items: center;

    .errorText {
      color: $salmon;
      font-size: 1rem;

      .tryAgain {
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.2rem;
      }
    }

    .errorIcon {
      color: $salmon;
    }
  }
}

.messageAction {
  display: flex;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #ffffff;
  overflow: hidden;
  text-align: center;
  float: left;
  z-index: 999;
  top: -0.2rem;
  width: 2rem;
  @include for-phone-only {
    right: -2.5rem;
  }
}

.deleteButton{
  color: #232950;
  height: 1.5rem;
  width: 1.5rem;
}

.disableButton{
  color: #B8B8B8;
}
.withdrawHint {
  color:  #ddd8d8;
  white-space: pre-wrap;
  padding: 0.5rem 0 0 0.5rem;
  display: block;
  font-size: 11pt;
}

.rightBottom{
  display: flex;
  // align-items: center;
  position: absolute;
  right: 0px;
  bottom: 0;
}
.message_list_item {
  position: relative;
}
.show_more_options{
  position: relative;
  display: none;
  .more_icon{
    position: absolute;
    right: 0;
    bottom: -4px;
  }
}
.show_more_options:hover{
  cursor: pointer;
}
.message_list_item_selected .show_more_options{
  display: inline-block;
}

.hover_message_container{
  width: 14.5rem;
  display: none;
  position: absolute;
  right: 0;
  top: 2.5rem;
  background-color: #484954;
  border-radius: 4px;
  padding: 0.8rem;
  color: white;
  z-index: 999;
}
.is_last{
  top: auto;
  bottom: 2.5rem;
}
.option_item_selected.hover_message_container{
  display: inline-block;
}


.botMessage {
  .markdown {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
}

.error {
  display: flex;
  .errorMessage{
    margin: auto;
    .markdown {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
}