$indigo: #464ac9;

.formInput {
  padding: 12px 20px;
  border: solid 1px #e3e7ed;
  border-radius: 4px;
  width: 100%;
  background: white;
  font-size: 14px;
  &:hover {
    box-shadow: 0 3px 6px 0 rgba(61, 64, 144, 0.23);
    border: solid 1px $indigo;
  }
  :focus {
    border: solid 1px $indigo;
  }
}
.validationMessage {
  padding-left: 22px;
  text-align: left;
  color: #ed4b53;
}