@import "components/atoms/colours.scss";
@import 'components/atoms/responsiveBreakpoints.scss';

.modalAlertContainer {
  background: $slate;
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  z-index: 1;
  
  .modalAlertIcon {
    color: #fff;
  }

  .modalAlertMessage {
    color: #fff;
    margin-left: 1rem;
    font-size: 1rem;

    @include for-desktop-only {
      font-size: 1.2rem;
    }
  }
}