@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.calendarHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 3rem;
  align-items: center;
  box-sizing: border-box;
  border-bottom: $defaultBorderStyle;
  
  
  @include for-phone-only {
    width: 100%;
    padding: 1rem 0.7rem;
  }
}

.header {
  display: flex;
  flex-direction: horizontal;
  justify-content: center;
  margin-bottom: 20px;
  color: $primary;
  align-items: center;
  margin: 0;
  text-align: center;

  @include for-phone-only {
    padding: 0;
    margin: 0;
  }
}

.weekContainer {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  color: $primary;
}

.currentWeek {
  font-size: 1.5rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include for-phone-only {
    font-size: 1.2rem;
    max-width: 10rem;
  }
}

.weekDay {
  text-align: center;
  flex-grow: 1;
}

.arrowButton {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 2rem;

  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: transparent;
  color: inherit;

  &:hover {
    background-color: $backgroundActive;
    border-radius: 3px;
    color: $contrast;
  }

  @include for-phone-only {
    margin: 0 0.5rem;

    &:hover {
      background-color: transparent;
      color: $primary;
    }
  }
}


.hidden {
  visibility: hidden;
  pointer-events: none;
}

.calendarBlocks {
  display: block;
  width: 100%;
}

.todayButton {
  padding: 0.8rem;
  cursor: pointer;
  color: $primary;
  border-radius: 0.2rem;
  display: flex;
  font-weight: 600;
  background: $cloud;

  &:hover {
    background: $cloudDark;
  }


  @include for-phone-only {
    font-size: 1rem;
    margin-left: 0.7rem;
    padding: 0.5rem 0.5rem 0.2rem 0;
    background: #fff;

    &:hover {
      background: #fff;
    }
  }

  .todayText {
    font-size: 1.2rem;
    @include for-phone-only {
      display: none;
    }
  }
}

.modeSwitcher {
  padding: 0.8rem;
  color: $primary;
  border-radius: 0.2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  background: $cloud;

  &:hover {
    background: $cloudDark;
  }

  @include for-phone-only {
    padding: 0.6rem 0.5rem;
    font-size: 1rem;
    margin-right: 0.5rem;
    background: $cloud;

    &:hover {
      background: $cloud;
    }
  }
}

.calendarHeaderRight{
  display: flex;
  align-items: center;
}

.externalCalendarNote{
  position: absolute;
  right: 11rem;
  display: flex;
  font-size: 14px;
  align-items: center;
  @include for-phone-only {
    display: none;
  }
}
.externalCalendarBlock{
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: rgba(255, 118, 76, 0.9);
  margin-right: 0.8rem;
}