@import 'components/atoms/colours.scss';
@import 'components/atoms/typography/fontFamily.scss';
@import 'components/atoms/typography/font.scss';
@import 'components/atoms/borders.scss';

.fieldCaption {
  margin-bottom: 0.4rem;
}

.inputText {
  @extend .body, .regular;
  color: $primary;
  box-sizing: border-box;
  width: 100%;
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  border-radius: 3px;
  border: solid 1px $inputBorder;

  &::placeholder {
    color: $hint;
  }

  &:focus{
	  border: $activeInputBorderStyle;
  }

}

.invalid {
  border: $warningInputBorderStyle;
}

.disabled {
  border-color: $disabledText;
  color: $disabledText;
}