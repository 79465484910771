@import 'components/atoms/colours.scss';

.subjectText {
  font-weight: bold;
  line-height: 1.5rem;
}

.parentSubjectText {
  margin-bottom: 0.5rem;
  color: $slate;
  font-size: 1rem;
}

.subjectLink {
  display: block;
  text-decoration: none;
  padding-top: 0.8rem;

  &:hover {
    .subjectText {
      text-decoration: underline;
    }
  }
}

.pastSubjectLink {
  display: block;
  text-decoration: none;

  &:hover {
    background-color: $cloud;
  }
}