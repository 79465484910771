@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import '../styles.scss';


.rGroup {
  display: flex;
  width: 100%;
}

.rButtonContainer {
  width: 2.6rem;
  height: 2.6rem;
  flex: 1;
}

.rButton {
  display: none;
  &:checked+label {
    &:before {
      content: '';
      width: 5px;
      height: 10px;
      border-right: $contrastInputBorderStyle;
      border-bottom: $contrastInputBorderStyle;
      transform: rotate(45deg);
      left: 10px;
      top: 3px;
    }
  }
}

.colorPickerWrapper {
  margin: 2.4rem 0 0;
}

.selected {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.7);
  border: $contrastInputBorderStyle;
}

.colorPicker label {
  position: relative;
  height: 2.6rem;
  margin-bottom: 0px !important;
  cursor: pointer;
  &:before {
    width: 2.9rem;
    height: 2.2rem;
    position: absolute;
    content: '';
    padding-top: 4px;
    color: $contrast;
    text-align: center;
    vertical-align: middle;
  }
  &:hover {
    transform: scale(1.2);
    z-index: 200;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.7);
    border: $contrastInputBorderStyle;
  }
}

.colorPicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.6rem;
  border: $defaultInputBorderStyle;
  border-radius: 0.2rem;
}
