@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.report {
  h4 {
    color: $secondary;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  p {
    color: $primary;
    font-size: 1.2rem;
    margin-top: 0;
    line-height: 1.6;
  }
}

.topFieldsContainer {
  display: flex;

  @include for-phone-only {
    flex-direction: column;
  }

  p {
    margin: 0;
  }
}

.topField {
  flex: 1 0 auto;
}
