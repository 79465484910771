.averageRatingBadge {
  max-width: 5.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
}

.emoji {
  margin-left: 0.8rem;
  height: 1.2rem;
}

.sessions {
  padding-top: .5rem;
}
