@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.feedback {
  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin-top: 2.4rem;
      max-width: 48rem;
    }

    .ratingComment {
      transition: all 500ms;
      margin-top: 0;
      max-height: 0;
      overflow-y: hidden;

      width: 100%;
      margin-bottom: 0;

      &.active {
        margin-top: 1.6rem;
        max-height: 50rem;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      transition: all 300ms;
      overflow-y: hidden;
      max-height: 0;

      &.active {
        max-height: 50rem;
      }

      .optionalFields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0.4rem 0 2rem;

        &>div {
          width: 18rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 1.6rem;
          padding: 0 1rem;

          @include for-phone-only {
            width: 100%;
          }
        }
      }
    }
  }

  .info {
    color: $stone;
    display: flex;
    flex-direction: row;

    svg {
      height: 1.2rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.6rem;
      margin: 0;
    }
  }
}
