@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

:global {
  .redux-toastr {
    .top-center {
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;

      @include for-phone-only {
        margin-top: constant(safe-area-inset-top); /* iOS 11.0 */
        margin-top: env(safe-area-inset-top); /* iOS 11.2 */
      }
    }

    .toastr {
      margin: 0;
      border-radius: 0;
      min-height: 5rem;
      padding: 1rem;
      opacity: 1;
      display: flex;
      justify-content: center;
      @media print {
        display: none;
      }

      &.rrt-success {
        background: $success;
      }

      &.rrt-error {
        background: $warning;
      }

      &.rrt-warning {
        background: $salmon;
      }

      &>div {
        display: flex;
        justify-content: center;
        min-width: 75%;

        @include for-phone-only {
          width: 100%;
        }
      }

      .rrt-left-container {
        width: auto;
        position: inherit;
        display: flex;
        align-items: center;

        .rrt-holder {
          display: flex;
          width: 1.6rem;
          height: 100%;
          margin: 0;
          margin-right: 0.5rem;
          position: inherit;
          line-height: 1.6;


          .toastr-icon {
            display: none;
            /* icon has inline styles so we need !important to override.*/
            width: 100% !important;
            height: 100% !important;
            margin: 0;
          }
        }
      }

      .rrt-right-container {
        /*display: none;*/
        .close-toastr {
          color: white;
          opacity: 1;
          font-size: 1.8rem;

          @include for-phone-only {
            right: 0.5rem;
          }
        }
      }

      .rrt-middle-container {
        width: auto;
        margin: 0;
        position: inherit;
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;

        @include for-phone-only {
          font-size: 1rem;
        }

        .rrt-title {
          background-color: red;
          font-size: unset;
          font-weight: bold;
          margin: 0 0 0 0;
        }
      }
      /* Has this been renamed to rrt too? */
      .toastr-progress-container {
        height: 2px;

        .toastr-progressbar {
          background: white;
        }
      }
    }
  }
}
