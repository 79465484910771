@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.eventSummary {
  margin: 1.6rem 0;
}

.eventSummaryCard {
  margin: 2.4rem 0;
  padding: 2rem 2rem;
  border: $defaultBorderStyle;
  border-radius: 2px;
  color: $secondary;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  text-overflow: ellipsis;
  max-width: 40rem;
  overflow: hidden;
  display: block;
  line-height: 1.8rem;
}

.row {
  display: flex;
  font-size: 1.2rem;
  padding-top: 1.6rem;

  @include for-phone-only {
    flex-direction: column;
  }
}

.item {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.2rem;
}

.notes {
  line-height: 1.8rem;
  white-space: pre-wrap;
  word-break: break-word;
}

.column {
  flex: 1;
  margin-right: 1rem;

  @include for-phone-only {
    margin-bottom: 1rem;
  }
}

.columnLast {
  flex: 1;
}

.originallyScheduled {
  font-size: 1rem;
}

.toggle {
  padding: 1rem 0 0;
}

.toggleLink {
  color: $callToAction;
  font-size: 1.2rem;
  cursor: pointer;
  margin-right: 1.6rem;
}

.changedTime {
  text-decoration: line-through;
}