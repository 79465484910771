/* Base spacing - MODIFY ONLY WITH DESIGNER APPROVAL */

$spacing-01: 0.2rem;

$spacing-02: 0.4rem;

$spacing-03: 0.8rem;

$spacing-04: 1.2rem;

$spacing-05: 1.6rem;

$spacing-06: 2.4rem;

$spacing-07: 3.2rem;
