@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.progressBar {
  position: relative;
  min-width: 2.0rem;
  width: 100%;
  height: 1.4rem;
  overflow: hidden;

  .outerBar {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    border: 2px solid $tiffany;
    border-radius: 0.7rem;
    overflow: hidden;
  }

  .innerBar {
    position: absolute;
    min-width: 1.8rem;
    max-width: 100%;
    height: 100%;
    border-radius: 10rem;
    background-color: $tiffany;
    transition: width .6s ease;
  }

  &.warning {
    .outerBar {
      border-color: $warning;
    }
    .innerBar {
      background-color: $warning;
    }
    .innerBarSecondary {
      background-color: $warningSoft;
    }
  }

  &.error {
    .outerBar {
      border-color: $salmon;
    }
    .innerBarSecondary {
      background-color: $transparentSalmon;
    }
  }

  .innerBarSecondary {
    position: absolute;
    min-width: 1.8rem;
    max-width: 100%;
    height: 100%;
    border-radius: 10rem;
    background-color: $successSoft;
    transition: width .6s ease;
  }

  &.disabled > .outerBar {
    border-color: $disabledText;
  }

  &.disabled > .innerBar {
    background-color: $disabledText;
  }

  &.disabled > .innerBarSecondary {
    background-color: $hintSoft;
  }
}
