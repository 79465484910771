.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.row1 {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.col1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row2 {
  display: flex;
  flex-direction: row;
  flex: 2;
}

.col2 {
  display: flex;
  flex-direction: column;
  flex: 2;
}
