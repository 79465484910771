@import 'components/atoms/colours.scss';

.container {
	position: relative;
	fill: white;
}

.toggler {
  text-align: right;
	display: inline-block;
}

.wrapper {
  width: 35rem;
	text-align: left;
  box-shadow: 0 0 0.5rem 0 $primary;
	font-size: 1.3rem;
	z-index: 1001; 
  position: absolute;
	right: -5rem;
	top: 4.8rem;
}

/* arrow style */
.wrapper:after {
  bottom: 100%;
	right: 6rem;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-bottom-color: $primary;
	border-width: 0.8rem;
}
