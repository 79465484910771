@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.infoPanel {
  color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -1.5rem -2rem 0;
  background-color: $cloud;
  padding: 1.5rem;
  box-sizing: border-box;

  @include for-desktop-only {
    margin: -2.5rem -3rem 0;
  }

  .fullName {
    margin-top: 0.8rem;
  }

  .text {
    margin-top: 0.4rem;
  }
}