@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  padding: 0.8rem 1.2rem 0.8rem 0.7rem;
  height: 2.8rem;
  border-radius: 3px;
  box-sizing: border-box;
  width: fit-content;

  &.reversed {
    flex-direction: row-reverse;
  }
}

.button:hover {
  background-color: $backgroundHover;
}

.button:active {
  background-color: $backgroundActive;
}

.icon {
  margin-right: 1.2rem;

  &.reversed {
    margin-right: 0;
    margin-left: 1.2rem;
  }
}

.paddingLeft {
  padding-left: 0.3rem;
}

.backButtonText {
  @include for-phone-only { 
    display: none;
  }
}

.isDisabled {
  cursor: default;
  color: $disabledText;
  &:hover {
    color: $disabledText;
  }

  &:active {
    background: none;
  }
}

.disabledText {
  color: $disabledText;
}
