@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/behaviours/transitions.scss';

.buttonContainer {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 0 0.2rem;
  margin-top: 0.2rem;
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 0px;
  text-align: center;
  * {
    box-sizing: border-box;
  }
}

.button {
  position: relative;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  padding: 0.8rem;
  background: $contrast;
  cursor: pointer;
  border-radius: 0.4rem;
  margin: 0 0.4rem;
  border: $defaultInputBorderStyle;
  justify-content: center;

  &:focus {
    border: $activeInputBorderStyle;
  }
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

.buttonContent {
  color: $disabledText;
  svg {
    color : $disabledText;
  }
}

.activeButton {
  background: $primary;
}

.buttonActiveText {
  color: $contrast;
  z-index: 1;
  svg {
    color : $contrast;
  }
}

.invalid > button {
  border-color: $warning;
}