@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.moreOptionsIcon {
  cursor: pointer;
  margin-left: auto;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 10rem;
  margin-bottom: -1.2rem;

  .reportDate {
    display: flex;
    flex-direction: row;

    .reportUsers {
      display: flex;
      flex-direction: row;
      margin-left: auto;
    }

    .reportUsersItems {
      margin: auto;
      padding-left: 1rem;
    }
  }

  .reportDetailsText{
    margin: 0.5rem 0.5rem 0.5rem 0;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}