@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.value {
    color: $charcoal;
}

.positive * {
    color: $tiffany;
}

.negative * {
    color: $orange;
}

.fields {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .field {
      width: 25rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      flex-direction: row;
      align-items: center;

      .fieldRating {
        width: 13rem;
        height: 3.6rem;
        display: flex;
        flex-direction: row;
        background-color: $mist;
        color: $stone;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:last-child) {
            border-right: solid 1px $chromium;
            border-radius: 0.3rem 0 0 0.3rem;
          }

          &:last-child {
            border-radius: 0 0.3rem 0.3rem 0;
          }

          .icon {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.4rem;
          }
        }
      }

      @include for-phone-only {
        width: 100%;
        padding: 0 1.3rem;
      }
    }
  }