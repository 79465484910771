@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.toggleButton {
  box-sizing: initial;
  display: inline-block;
  outline: 0;
  width: 2.8em;
  height: 1.2em;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: $hint;
  border-radius: 4em;
  padding: 0.4rem;
  transition: all 0.4s ease;
}

.toggleContainer {
  border: none;
  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.toggleContainer:focus .toggleButton::after, .toggleContainer:focus .toggleButton {
  box-shadow: 0 0 0 0.1rem $transparentBlack, 0 0.4rem 0 $transparentBlack;
}

.toggleContainer:focus .toggleButton::after,
.toggleButton:active::after {
  box-sizing: initial;
}

.toggleButton::after {
  left: 0;
  position: relative;
  display: block;
  content: '';
  width: 45%;
  height: 100%;
  border-radius: 4em;
  background: $white;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
}

.toggleButton.toggleButtonOn::after {
  left: 50%;
}

.toggleButton.toggleButtonOn {
  background: $success;
}

.toggleButton.toggleButtonOn:active {
  box-shadow: none;
}

.toggleButton.toggleButtonOn:active::after {
  margin-left: -0.8em;
}

.toggleButton:active::after {
  padding-right: 0.8em;
}