@import 'components/atoms/borders.scss';
@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.invoiceSummary {
  border-bottom: $defaultBorderStyle;
  padding-bottom: 1rem;

  @include for-phone-only {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
}

.from {
  margin-bottom: 2rem;

  & > *:first-child {
    margin-bottom: 0.5rem;
  }
}

.summaryContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include for-phone-only {
    flex-direction: column;
  }

  & > * {
    flex: 1;
  }

  .billTo {
    & > *:first-child {
      margin-bottom: 0.5rem;
    }
  }
  
  .invoiceDetails {
    @include for-phone-only {
      margin-top: 1.5rem;
    }

    & > * {
      margin-top: 0.5rem;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }

  .invoiceStatus {
    display: none;
  }
}

@media print {
  .summaryContainer {
    flex-direction: row;
    margin-bottom: 2rem;

    .invoiceDetails {
      margin-top: 0;
    }

    .invoiceStatus {
      display: block;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}