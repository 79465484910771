@import 'components/atoms/borders.scss';

:global {
  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding: 3rem;
  }

  .custom-ql-toolbar {
    border: none !important;
    border-bottom: $defaultBorderStyle !important;
    padding: 1rem 3rem;
    &.notoolbar {
      padding: 0px;
    }
    &.no-padding {
      padding: 0px;
    }
  }

  /* remove default link tooltip input label */
  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: none;
  }

  .floating-agenda {
    .ql-editor {
      min-height: 175px;
    }
  }

  .floating-notes {
    .ql-editor {
      min-height: 275px;
    }
  }

  /* control when to show link text input and label */
  .ql-snow .ql-tooltip .link-text-container {
    display: none;
  }
  .ql-snow .ql-tooltip[data-mode="link"] .link-text-container {
    display: block;
    text-align: start;
  }
  .ql-snow .ql-tooltip > span {
    display: none;
  }
  .ql-snow .ql-tooltip[data-mode="link"] > span {
    display: inline-block;
    margin-right: 5px;
  }
}