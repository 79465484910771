@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.horizontalLine {
  margin: 4rem 0;
  border-bottom: $defaultBorderStyle;
}

.personNameCell {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 3.4rem;
  height: 3.4rem;
  margin-right: 1rem;
}

.personDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
}

.userEmail {
  margin-top: 0.8rem;
  color: $hint;
  font-size: 1.2rem;
  word-break: break-all;
}

.editIcon {
  font-size: 1.8rem;
  margin-right: 1rem;
  transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.editIcon:hover {
  background-color: $secondaryButtonHover;
}

.editIcon:hover svg {
  fill: $primary;
}

.activateButton {
  composes: linkButton from "styles/buttons.scss";
  font-family: inherit;
  word-break: keep-all;
}

.tableContainer {
  margin: 0 -1.5rem;
  @include for-phone-only {
    display: none;
  }
}

.commonButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-block;
  min-width: 10rem;
  text-align: center;
  padding: 0.8rem 1.2rem;
  line-height: 1;
  border-radius: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative; 
}
.messageButton {
  color: white;
  background-color: #ed4b53;
  border: none;
}

.acceptButton {
  color: #ed4b53;
  background-color: white;
  border: 1px solid #ed4b53;
}

.friendAction {
  display: flex;
  align-items: center;
}

.noneStyleButton {
  background-color: transparent;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.bookButton {
  color: #ed4b53;
  background-color: white;
  border: 1px solid #ed4b53;
  margin-left: 0.5rem;
}