@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.card {
  padding: 0;
}

.info {
  display: grid;
  grid-template-areas:
    'time avatar'
    'sessionName avatar'
    'summary status';
  grid-template-columns: minmax(0, 70%) minmax(0, 30%);
  grid-gap: 0.8rem;
  padding: 2.4rem;
  text-decoration: none;

  @include for-phone-only {
    grid-template-areas:
      'time avatar'
      'sessionName avatar'
      'name status';
    padding: 1.6rem;
  }
}

.section {
  display: flex;
  align-items: center;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sessionTime {
  grid-area: time;
}

.sessionName {
  grid-area: sessionName;
}

.summary {
  grid-area: summary;

  .text {
    color: $stone;
  }

  .active {
    color: $jellyBean;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-right: 2.4rem;
    }
  }

  @include for-phone-only {
    display: none;
  }
}

.avatar {
  grid-area: avatar;
  justify-content: flex-end;
}

.name {
  display: none;
  grid-area: name;
  justify-content: flex-end;

  @include for-phone-only {
    display: flex;
    justify-content: flex-start;
  }
}

.status {
  grid-area: status;
  justify-content: flex-end;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px $chromium;
  background-color: $white;

  & > * {
    margin: 1.2rem 1.5rem;
  }

  @include for-phone-only {
    border-top: none;
    background-color: $mist;
  }
}

.draft {
  color: $orange;
}