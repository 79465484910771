@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

$item-height: 60px;

.contacts {
  header {
    padding: 2.4rem;
  }
}

.selectedItem {
  background: $pixieDust;
  cursor: default;
}

.peopleItemContainer {
  height: $item-height;
  display: flex;
  padding: 0.8rem 2.4rem;
  cursor: pointer;

  @include for-phone-only {
    padding: 0.5rem 1.5rem;
  }
}

.peopleItemContainer:hover {
  background-color: $background;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.peopleItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.line1 {
  font-size: 1.2rem;
	color: $primary;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.line2 {
  font-size: 1rem;
	color: $slate;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown {
  z-index: 1;
  position: absolute;
  top: 5.7rem;
  bottom: 0rem;
  left: 0;
  right: 0;
	background-color: $contrast;
  box-sizing: border-box;
  overflow: auto;
}

.listContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollContainer {
  flex: 1 0;
  overflow-y: auto;
}

.counter {
  color: $primary;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  flex: 0 1;
}

.loadingIndicator {
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.hidden {
  display: none;
}

.dot {
  color: $online;
  margin: 0 0.5rem;
  padding: 0.1rem 0;

  svg {
    width: 12px;
    height: 12px;
  }
}