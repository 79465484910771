@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.options {
  position: absolute;
  color: #fff;
  background-color: $primary;
  top: calc(0.8rem + 2px);
  left: 0;
  transform: translateX(-50%);
  border-radius: 2px;
  border: $activeInputBorderStyle;
  text-align: center;
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-bottom-color: $primary;
    border-bottom-style: solid;
    border-bottom-width: 0.8rem;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    top: calc(-0.8rem - 2px);
    left: calc(50% - 1rem + 2px);
  }
}
