@import 'components/atoms/colours.scss';

.rButtonContainer {
  flex: 0 1 7rem;
}

.labelText {
  color: $secondary;
  display: inline-block;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.rGroup {
  display: flex;
}
