@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.timeBlock {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% / 24);
  background: rgba(74, 144, 226, 0.1);
  border: 0.1rem solid $sky;
  color: $sky;
  cursor: pointer;
  z-index: $layerModal;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0.2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0.1rem;
  padding: 0.3rem 0.5rem;

  animation: appear 200ms;

  .time {
    font-size: 1rem;
    position: absolute;
    top: 0.3rem;
    left: 0.5rem;

    @include for-phone-only {
      font-size: 0.8rem;
    }
  }

  .plus {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: $sky;
  }
}

@keyframes appear {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}