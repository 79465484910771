@import "./base.scss";
@import "../typography.scss";

%item {
    @include set-typo-for-body-02();  
    background-color: var(--select-list-item_color_bkg);
    border-color: var(--select-list-item_color_border);
    color: var(--select-button_color);
    border-width: 0.5px 1px;
    border-style: solid;

    &.Mui-selected {
        background-color: var(--select-list-item_selected_color_bkg);
        &:hover {        
            background-color: var(--select-list-item_selected_color_bkg);
            border-color: var(--select-list-item_selected_color_border_hover);
        }
    }

    &:hover {        
        background-color: var(--select-list-item_color_bkg_hover);
        border-color: var(--select-list-item_color_border_hover);
    } 
}

.ds-select-list.MuiList-root {  
    @extend .typography;
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItem-root {
        @extend %item;

        &.Mui-selected {
            background-color: var(--select-list-item_color_border);
        }

        &:hover {
            border-width: 0.5px 1px;
            border-color: var(--select-list-item_color_border_hover);
            border-style: solid;
        }
    }
}

.ds-multiselect-list.MuiList-root { 
    @extend .typography;
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItem-container {
        .MuiListItem-root {
            @extend %item;    
        }

        .MuiIconButton-root, .MuiIconButton-root.ds-button.MuiButtonBase-root { 
            border-style: none;            
            background-color: transparent;

            .MuiSvgIcon-root {
                color: var(--color_indigo);            
            }
        }
    }
}

.ds-select.MuiFormControl-root {
    @extend .typography;

    .MuiFormLabel-root {        
        @include textbox-alike-label();        
    }
    
    &.ds-active .MuiButton-label .MuiButton-endIcon {
        transform: rotate(-180deg);
    }

    > .MuiButtonBase-root {            
        @include textbox-alike();   
        color: var(--select-button_color-empty);     
        text-transform: none;
        height: auto;
        min-height: 43px;
        padding: 6px 8px;
        
        &:hover {
            @include box-shadow();
        }  
        
        .MuiButton-label {
            @include set-typo-for-body-02();            
            
            .MuiButton-endIcon {
                transition: transform 0.3s;
            }
        }
    
        &.Mui-disabled {
            @include button-base("disabled");
    
            box-shadow: none;
        }
        
        .ds-chip {
            margin: 0 4px;
            
            &:focus {
                @include set-typo-for-bread-crumb();
            }
        }
    }
}

.ds-select-popover {
    .MuiPaper-root {
        max-height: 240px;
        
        .MuiListItem-root {
            height: 43px;
        }
    }
}