@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.personInitials {
  font-size: 1.4rem;
  width: 3.4rem;
  height: 3.4rem;
}

.avatar{
  margin-right: 1.6rem;
}

.comment {
  display: flex;
  padding: 1.6rem 0;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: normal;
  color: $primary;
  border-top: $defaultBorderStyle;

  &:hover > .commentSection > .commentMetaData .editButton {
    visibility: visible;
  }

}

.commentSection {
  width: 100%;
}

.commentMetaData {
  margin: 0;
  height: 3.4rem;
  line-height: 3.4rem;
  display: flex;
}

.commentText {
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
  line-height: 1.6;
  word-break: break-word;

  > p {
    margin: 0;
    ~ p {
      margin-top: 0.6rem;
    }
  }
}
.commentEdit {
  display: flex;
  width: 100%;
  margin: 1.2rem 0;
}

.formInput{
  display: block;
  min-height: 4.6rem;
  margin: 0;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  line-height: 1.6;
}

.timestamp {
  font-size: 1.2rem;
  color: $hint;
  margin: 0 0 0 0.8rem;
}
.menu {
  margin-top: 2.4rem;
  margin-left: 0.8rem;
}
.editButton {
  visibility: hidden;
  position: relative;

  &:hover {
    visibility: visible;
  }
  &:hover > .editIcon{
    color: $primary;
  }
  cursor: pointer;
  margin: 0 1rem 0 auto;
}

.editIcon{
  font-size: 2.5rem;
  color: $disabled;
}

.edit{
  cursor: pointer;
  padding: 0 1rem;
}
.delete {
  cursor: pointer;
  padding: 0 1rem;
}

.edit:hover, .delete:hover {
  background-color: $contrast;
  color: $primary;
}

.editIsClicked {
  visibility: visible;

  .editIcon{
    color: $primary;
  }
}

.tooltip {
  font-size: 1rem;
}
