@import 'components/atoms/colours.scss';

.formItemContainer {
  position: relative;
  margin-bottom: 1.6rem;
  font-size: 1.2rem;
  $color: $primary;
}

.formLabel {
  color: $secondary;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
}

.formControlWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.validationMessage {
  color: $warning;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.error {
  border-color: $warning;
  &:focus{
	  border-color: $warning;
  }
}

.disabled {
  cursor: not-allowed;
  color: $disabled;
  border-color: $border;
  background-color: $background;
  &:hover {
    cursor: not-allowed;
  }
}

:global {
  .formItemNoMargin {
    margin: 0;
  }
  .formItemLessMargin {
    margin-bottom: 1.6rem;
  }
}
