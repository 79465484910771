@import 'components/atoms/colours.scss';

.notFound {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  margin-top: 2.05rem;
}

.subheading {
  margin-top: 0.8rem;
}

.message {
  text-align: center;
}