@import 'components/atoms/colours.scss';
@import 'components/atoms/spacing.scss';
@import 'components/atoms/scrollbar.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.bg {
  background-color: $mist;
}

.body {
  @extend .bg;
  height: 100%;

  .item {
      background-color: $white;
  }
}

.leftPane {
  border-right: solid 1px $border;
}

.rightPane {
  padding: $spacing-04 5rem;
}

.studentHeader {
  padding: $spacing-02 $spacing-04;
}

.studentList {
  height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 10rem!important;

  @extend %scrollbar;
}

.selectItemHeader {
  margin: $spacing-03 0;
}

.selectItemSubHeader {
  font-size: 1.4rem;
  margin-left: 1rem;
  vertical-align: top;
}

.previewWrapper {
  margin-bottom: 3rem;
}

.preview {
  height: 65vh;
}

.dialog {
  width: 450px;
}

.btnText {
  margin: 0.5rem;
}

.contentWrapper {
  margin: 0 4rem;

  @include for-phone-only {
    margin: 0 2rem;
  }
}

.center {
  text-align: center;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.dialogActions {
  margin-top: $spacing-06;
  margin-bottom: $spacing-06;
}

.dialogPaper {
  padding: 5rem;
}

.svgContainer {
  margin: 7rem 0;
}

.linkText {
  text-decoration: none;
  color: $white;
}

.hidden {
  display: none!important;
}

.mobileButton {
  margin: 1em 0!important;
}

.padding-02 {
  padding: $spacing-02;
}

.padding-04 {
  padding: $spacing-04;
}

.padding-05 {
  padding: $spacing-05;
}

.padding-06 {
  padding: $spacing-06;
}

.margin-03 {
  margin: $spacing-03;
}