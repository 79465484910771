@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.dot {
  color: $online;
  margin: 0 0.5rem;
  padding: 0.1rem 0;

  svg {
    width: 12px;
    height: 12px;
  }
}
.threadListItem {
  display: flex;
  padding: 0.9rem 2.4rem;
  height: 5rem;
  justify-items: center;
  align-items: center;

  @include for-phone-only {
    padding: 0.5rem 1.5rem;
  }
}

.selectedItem {
  background: $pixieDust;
  cursor: default;
}

.selectedItem .threadNameContainer {
  color: $primary;
}

.threadListItem:hover {
  cursor: pointer;
  background: $background;
}

.selectedItem:hover {
  background: $background;
  cursor: default;
}

.contentContainer {
  flex-grow: 3;
  overflow: hidden;
}

.threadNameContainer {
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  padding-bottom: 0;
  margin: 0;
}

.nameContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notificationContainer {
  flex-shrink: 1;
}

.notification {
	height: 1.7rem;
  border-radius: 1rem;
  color: $contrast;
  float: right;
  text-align: center;
  vertical-align: bottom;
  margin: 1.5rem 0rem 1rem 2rem;
  min-width: 1.7rem;
  background-color: $salmon;
  box-sizing: border-box;
  padding-top: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.onlineDot {
  color: $online;
  margin-left: 0.5rem;
  padding-top: 0.1rem;
}