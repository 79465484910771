@import 'components/atoms/colours.scss';

// Ideally the base card wouldn't have these styles but it does so
// we have to clear them.
.card {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.tutor {
  display: grid;
  grid-template-areas: "top" "middle" "bottom";
  grid-template-rows: 2rem 2rem 2rem;
  grid-auto-rows: auto; /* There is sometimes a footer row added */
  grid-gap: 0.4rem;
  padding: 1rem 0rem;
}

.name {
  grid-area: top;
  padding-left: 1rem;
  /* This avoids the name text from overlapping the icon in the top right corner */
  padding-right: 4rem;
}

.fullName {
  display: inline-block;
  /* All 3 of these (text-overflow, white-space and overflow) are needed to trigger the ellipsis */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  text-overflow: ellipsis;
  max-width: 200px;
}

.markNew {
  display: inline-block;
  background-color: #741443;
  padding: 0.3rem 0.6rem;
  border-radius: 2px;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  position: relative;
  top: -6px;
  left: 5px;
}

.add {
  grid-area: top;
  color: $salmon;
  margin-left: auto; /* push it full right */
  padding-right: 1rem;
}

.rating {
  grid-area: middle;
  display: grid;
  grid-template-columns: 7rem 1fr;
  padding-left: 1rem;
}

.sessions {
  grid-area: middle;
}

.availability {
  grid-area: bottom;
  padding-left: 1rem;
}

.match {
  grid-area: bottom;
  margin-left: auto; /* push it full right */
  width: 10rem;
}

.footer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: auto;
  justify-content: start;
  border-top: 0.1rem solid $border;
  grid-column-gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  align-items: center;
}

.payRate {
  float: right;
  padding-right: 1rem;

  p {
    margin-top: 5px;
  }
}
