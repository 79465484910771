@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.cgaReports {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #F6F7FA;
  font-size: 1.4rem;
}

.actions {
  padding: 1.6rem 3.2rem;
  width: -moz-available;
  width: -webkit-stretch;

  .chips {
    margin: auto;
  }

  .select {
    width: 100%;
  }

  .generateReportsBtn {
    font-size: inherit !important;
  }
}

.reports {
  display: flex;
  flex-direction: row;
  padding: 1.6rem 3.2rem;
  flex-flow: wrap;
  background: #F6F7FA;
}

.reports::after {
  content: "";
  flex: auto;
}

.generateReportBtn {
  margin-left: auto;
}

.generateReportModal {
  width: 50rem;

  .modalTitleText {
    text-align: center;
  }

  .selectorLabel {
    text-align: left;
  }
}

.datePickerHeader {
  margin-bottom: -1px;
}

.selector {
  margin-right: 1rem;
}

.generateBtn {
  text-align: center;
}

.mobileButton {
  margin: 1em 0!important;
}