@import 'components/atoms/colours.scss';

.flowLayout {
  display: flex;
  flex: 1;

  &>div {
    flex: 1;
    overflow: hidden;
  }

  .resizer {

    flex: 0 0 3px;

    position: relative;
    user-select: none;
  }
}

.horizontal {
  flex-direction: row;

  .resizer {
    margin-left: -3px;
    border-right: solid 1px $chromium;
    cursor: col-resize;
  }
}

.vertical {
  flex-direction: column;

  .resizer {
    margin-top: -3px;
    border-bottom: solid 1px $chromium;
    cursor: row-resize;
  }
}

.dragging {
  opacity: 0.5;
}