@import 'components/atoms/colours.scss';

.container {
  margin: 3.2rem auto;
  width: 50rem;
}

.emailPreview {
  padding-bottom: 12rem;
}

.sentBanner {
  height: 5.8rem;
  background-color: $success;
  vertical-align: middle;
  position: relative;
  color: $contrast;
}

.bannerContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 3.7rem;
}

.bannerText {
  display: inline-block;
  vertical-align: top;
  margin: 0.4rem;
  padding-left: 2.3rem;
}

.modalText {
  margin-bottom: 1.6rem;
}

.checkbox {
  font-size: 1.2rem;
  font-weight: 600;
}

.checkboxCopy {
  margin-top: 4rem;
}