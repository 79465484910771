@import 'components/atoms/responsiveBreakpoints.scss';

.header {
  margin: 1rem 0;
  width: 100%;
}

.headerTitle {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 1.1px;

  @include for-phone-only {
    font-size: 1.4rem;
  }
}
