@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.wrapper {
  padding-top: 2rem;
}

.text {
 margin-top: 1.3rem;
 margin-bottom: 0.1rem;
 font-size: 11pt;
 font-weight: 600;
 color: $primary;
}

.helloText {
  color: $slate;
  margin-bottom: 1rem;
}

.avatars {
  display: flex;

  // Only show a max of 3 profile pictures
  .avatar:nth-child(n+4) {
    display: none;
  }

  .avatarOverlay {
    position: relative;
    background: rgba(0,0,0, 0.4);
    margin-top: -4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;

    @include for-phone-only {
      margin-top: -4rem;
      width: 4rem;
      height: 4rem;
    }
  }
}

.profilePic {
  text-decoration: none;
  cursor: pointer;
}