@import 'components/atoms/colours.scss';

.initialsAvatar {
  text-decoration: none;
  color: $contrast;
  background-color: black;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarColor0 {
  background-color: $avatar0;
}

.avatarColor1 {
  background-color: $avatar1;
}

.avatarColor2 {
  background-color: $avatar2;
}

.avatarColor3 {
  background-color: $avatar3;
}

.avatarColor4 {
  background-color: $avatar4;
}

.avatarColor5 {
  background-color: $avatar5;
}

.avatarColor6 {
  background-color: $avatar6;
}

.avatarColor7 {
  background-color: $avatar7;
}

.avatarColor8 {
  background-color: $avatar8;
}

.avatarColor9 {
  background-color: $avatar9;
}
