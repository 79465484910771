@mixin for-phone-only {
  @media (max-width: 812px) { @content; }
}

// do not use! weird overlap with desktop
@mixin for-tablet-only {
  @media (max-width: 1025px) { @content; }
}

@mixin for-desktop-only {
  @media (min-width: 813px) { @content; }
}

@mixin for-iphonex-only {
  @media only screen 
    and (device-width : 0px) 
    and (device-height : 0px) 
    and (-webkit-device-pixel-ratio : 3) { @content; }
}