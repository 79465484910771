@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

%filterTag {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}

.filterTagEmpty {
  @extend %filterTag;
  padding: 0.5rem 1rem;
  justify-content: center;

  background: $cloud;
  cursor: pointer;

  &:hover {
    background: darken($cloud, 10%);
  }

  & > *:last-child {
    padding-left: 0.8rem;
  }

  @include for-phone-only {
    padding: 1rem;
  }
}

.filterTag {
  @extend %filterTag;

  background: $mulberry;
  color: white;

  & > *:first-child {
    padding: 0.5rem 1rem;
    cursor: pointer;


    @include for-phone-only {
      padding: 1rem;
    }
  }

  & > *:last-child {
    background: $mulberryDark;
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    border-radius: 0 0.5rem 0.5rem 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include for-phone-only {
      padding: 1rem;
    }

    &:hover {
      i {
        font-weight: 600;
      }
    }
  }
}

.noIcon {
  display: none;
}