@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.keyContact {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;

  @include for-phone-only {
    flex-direction: column;
  }

  .contactWrapper {
    display: flex;
    flex: 1;
  }

  .keyContactDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0.3rem 0 0 1rem;

    & > *:first-child {
      margin-bottom: 0.5rem;
      word-break: break-all;
    }
  }

  .emailHistory {
    color: $slate;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.4rem;
    margin-left: 1rem;

    @include for-phone-only {
      margin: 0.3rem 0 0 3rem;
      font-size: 0.8rem;
    }

    & > *:last-child {
      margin-left: 0.5rem;
    }
  }
}


.divider {
  border: none;
  border-top: $defaultBorderStyle;
  margin: 2rem 0;
}

.inputCaption {
  margin: 2rem 0 0.5rem 0.5rem;
}

.tagError {
  color: $warning;
  font-size: 1rem;
  margin: 0.5rem;
}