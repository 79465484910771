@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

$inputHeight: 40px;
$inputHeightMobile: 3rem;

.bookingModal {
  width: 51.2rem;

  @include for-phone-only {
    width: 100%;
  }
}

.modalBody {
  padding: 0;
  color: $primary;

  & > * {
    display: block;
  }

  @include for-phone-only {
    margin: 0;
    padding: 0;
  }
}

.formGroup {
  label {
    display: block;
    margin-bottom: 4px;
  }
}

.disabled {
  color: $disabledText;
  cursor: default;
  input {
    color: $disabledText;
  }
}

.tutorSelect {
  height: $inputHeight;
  width: 100%;
  color: inherit;
  font-family: inherit;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: normal;
  padding: 5px;
  border: $defaultBorderStyle;
  border-radius: 2px;
  background-color: $contrast;
  -webkit-appearance: none;
  cursor: pointer;

  &:disabled {
    color: $disabledText;
    cursor: default;
  }

  @include for-phone-only {
    height: $inputHeightMobile;
  }
}
.bookForMessage {
  color: $primary;
  padding-top: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}
.invalidSelect {
  color: $warning;
  border-color: $warning;
}

.validationMessage {
  color: $warning;
  margin-bottom: .8rem;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.clientName {
  height: $inputHeight;
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: 1.4rem;
  margin-bottom: 10px;
  border-radius: 2px;
  border: $defaultBorderStyle;
  padding: 5px;
  box-sizing: border-box;

  @include for-phone-only {
    height: $inputHeightMobile;
  }
}

.timespan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include for-phone-only {
    flex-direction: column;
  }
}

.timespan > * {
  margin-right: 0.8rem;
  margin-bottom: 0px !important;

  @include for-phone-only {
    margin: 1.6rem 0;
  }
}

.timespan > :first-child {
  @include for-phone-only {
    margin-top: 0;
  }
}

.timespan > :last-child {
  margin-right: 0;
}

.timespan > .validationMessage {
  margin-top: 0.5rem;
}

.comments {
  color: inherit;
  height: 80px;
  width: 100%;
  padding: 5px;
  font-family: inherit;
  border-radius: 2px;
  border: $defaultBorderStyle;
  box-sizing: border-box;
  resize: none;
  font-size: 1rem;

  &:disabled {
    color: $disabledText;
    cursor: default;
  }
}

.timePicker > div:first-child {
  display: block;
  margin-bottom: 0.4rem;
  font-size: 1rem;
  color: $secondary;
}

.timePicker > div:nth-child(2) {
  font-size: 1.2rem;
  color: $primary;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
  padding-top: 0.8rem;
}

.cancelButton {
  composes: linkButton from "styles/buttons.scss";
  margin-right: 2.4rem;
  text-decoration: none;
}

.bookButton {
  composes: warning from "styles/buttons.scss";
  position: relative;
  min-height: 3.6rem;

  &.notClickable {
    background-color: $contrast;
    color: $disabledText;
    border-color: $disabledText;
    cursor: default;
  }
}

.bookButtonText {
  font-size: 1.2rem;
}

.buttonLoadingState {
  position: absolute;
  top: 1rem;
  left: calc(50% - 0.6rem);
}

.hidden {
  visibility: hidden;
}


.sendReminderButton {
  composes: primaryStroke from "styles/buttons.scss";
}

.deleteButton {
  composes: linkButton from "styles/buttons.scss";
  margin-right: auto;
  text-decoration: none;
}

.bookingWithWrapper {
  margin: 0 0 1.5rem 0;

  .bookingWithLabel {
    margin-bottom: 0.5rem;
    color: $slate;
  }
}

.errorMessage {
  color: $orange;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.textTimezoneOffset {
  margin-top: 0.8rem;
  font-size: 1rem;
  color: $slate;
}

.timezoneWarning {
  margin-bottom: 2rem;
  border-radius: 0.2rem;
}

.timezoneWarningText {
  & > * {
    margin-top: 1rem;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.onlyShowOnMobile {
  display: none;

  @include for-phone-only {
    display: block;
  }
}

.onlyShowOnDesktop {  
  @include for-phone-only {
    display: none;
  }
}

.showOvernight{
  width: 50%;
}

.showOvernightTimespan{
  margin-top: 1.6rem;
}

@media (max-width: 812px) {
  .showOvernight{
    width: 100%;
  }
}

.calendarList{
  border-top: 1px solid #e4e7ec;
  border-left: 1px solid #e4e7ec;
  border-right: 1px solid #e4e7ec;
}
ul,
ul li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: none;
}
.calendarDetail{
  padding: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.calendarUl{
  margin-top: 1rem;
  border-radius: 8px;
  border: solid 1px #e3e7ed;
  li {
    border-bottom: solid 1px #e3e7ed;
  }
  li:last-child {
    border-bottom: none;
  }
}
.subtitle{
  font-weight: 600;
}
.subtext{
  margin-top: 1rem;
  color: #a9acc0;
}
.syncedIcon{
  color: #454fc2;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.syncedErrorIcon{
  color: #ed4b53;
  vertical-align: middle;
  margin-right: 0.4rem;
  cursor: pointer;
}
.calendarSyncDetail{
  padding: 1.2rem;
  background-color: #e3e7ed;
}
.operateCalButton{
  margin-right: 2.4rem;
  color: #454fc2;
  cursor: pointer;
}
.syncedCalOperateArea{
  margin-top: 1rem;
}
.calIcon{
  width: 1.2rem;
  vertical-align: middle;
  margin-right: 1rem;
}
.cursor{
  cursor: pointer;
}