@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';

.paginator {
  font-weight: bold;
}

.pageNumber {
  display: inline-block;
  margin: 0 0.8rem;
  padding: 0 0.5rem;
  &:not(.currentPage){
    &:hover {
      cursor: pointer;
    }
  }
}
.currentPage {
  color: white;
  background-color: $primary;
  border: $activeInputBorderStyle;
  border-radius: 0.5rem;
}
.previous {
  display: inline-block;
  margin: 0 0.8rem;
  &:hover {
    cursor: pointer;
  }
}
.next {
  display: inline-block;
  margin: 0 0.8rem;
  &:hover {
    cursor: pointer;
  }
}
.ellipsis {
  display: inline-block;
  margin: 0 0.8rem;
}
