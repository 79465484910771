@import './colours.scss';

/* Main */
$defaultBorderStyle: solid 1px $border;
$activeBorderStyle: solid 1px $activeInputBorder;

/* Inputs */
$defaultInputBorderStyle: solid 1px $inputBorder;
$activeInputBorderStyle: solid 1px $activeInputBorder;
$warningInputBorderStyle: solid 1px $warning;
$contrastInputBorderStyle: solid 1px $contrast;

/* Progress bar */
$defaultProgressBarBorderStyle: solid 2px $secondary;
$warningProgressBarBorderStyle: solid 2px $warning;

:export {
  defaultInputBorderStyle: $defaultInputBorderStyle;
  activeInputBorderStyle: $activeInputBorderStyle;
  warningInputBorderStyle: $warningInputBorderStyle;
}