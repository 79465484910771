.card {
  margin-bottom: 1rem;
  border-left: 1px solid #e3e7ed;
  border-right: 1px solid #e3e7ed;
  border-radius: 4px;
  font-family: Montserrat;
}

.profileContainer {
  display: flex;
  justify-content: space-between;
}

.profileInfo {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  .name {
    font-size: 16px;
    font-weight: bold;
    color: #1d1e2b;
    font-family: Montserrat;
  }

  .role {
    font-size: 13px;
    font-weight: 500;
    color: #a9acc0;
    font-family: Montserrat;  
  }
}

.avatar{
  height: 40px;
  width: 40px;
}

.school{
  display: flex;
  margin-left: 6px;
  margin-top: 15px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #a9acc0;
  align-items: center;
    
  span {
    margin-left: 5px;
  }
}

.lastContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #a9acc0;
}

.locationIcon {
  color: #73747d;
}

.messageIcon {
  color: #ed4b53;
}

.messageButton {
  color: white;
  background-color: #ed4b53;
  border: none;
}
  
.acceptButton {
  color: #ed4b53;
  background-color: white;
  border: 1px solid #ed4b53;
  border-radius: 15px;
  padding: 4px 10px;
}
  
.friendAction {
  display: flex;
  align-items: center;
}
  
.editIcon {
  font-size: 1.8rem;
  transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #73747d;
}

.noneStyleButton {
  background-color: transparent;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.tipButton {
  border: none;
  background-color: transparent;
  color: white;
}

.modalStyle {
  z-index: 1000;
}