@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/layers.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.625rem;
  justify-content: space-between;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 1rem;
  float: left;
  cursor:pointer;
}
.info{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    height: auto;
    // box-shadow: 5px 10px 0 rgba(0,0,0,0.1);
    // max-width: 21.875rem;
}
.imageEement {
  max-width: 20rem;
  max-height: 17rem;
  cursor: zoom-in;
  border: 1px solid #e3e7ed;
}

.imageName{
  bottom: 4px;
  left: 6px;
  position: absolute;
  color: white;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #ffffff;
}

.downloadImage{
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #2b2b34;
  opacity: 0.29;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.5rem;
  color: #ffffff;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: $layerModal;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @include for-phone-only {
    -webkit-overflow-scrolling: touch;
    top: calc(0px + env(safe-area-inset-top));
    top: calc(0px + constant(safe-area-inset-top));
  }
}
.content {
  margin: 6rem auto;
  border-radius: 5px;
  outline: none;
  max-width: 100rem;
  // max-height: 60rem;
  background: rgb(255,255,255);
  box-shadow: 0 4px 5px 0 rgba(109, 109, 109, 0.5);
  display: flex;
  flex-direction: column;

  @include for-phone-only {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0;
    display: block;
    border-radius: 0;
    margin: 0;
    overflow: auto;
  }
}
.closeAction {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  cursor: pointer;
  float: right;
  @include for-phone-only {
    right: 0.5rem;
    top: 0.2rem;
  }

  &.withHeaderImage {
    position: absolute;
  }
}

.closeAction::before, .closeAction::after {
  height: 2.4rem;
  display: block;
  position: absolute;
}

.imageWrapper{
  position: relative;
  margin: 5vh 4vw;
  max-height: 92vh; 
  text-align: center;
  overflow: auto;
  @include for-phone-only {
    height: 100%;
  }
  &:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
  }
  .image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
    background-size: contain!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    animation: image-fade-in .4s ease;
    -webkit-animation: image-fade-in .4s ease;
  }
}