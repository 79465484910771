@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.notificationWrapper {
  font-size: 1.4rem;
}

.wrapperHidden {
  display: none;
}

.newNotifications {
  background-color: $primary;
  color: #fff;
  text-align: center;
  height: 5rem;
  line-height: 5rem;
  letter-spacing: .12rem;
}

.viewAll {
  background-color: $background;
  text-align: center;
  height: 3.4rem;
  line-height: 3.4rem;
  a {
    display: block;
    color: $primary;
    text-decoration: none;
  }
}

.notificationWidget {
  max-height: 48rem;
	overflow-y: auto;
}

.emptyItem {
  background-color: white;
  padding: 1.5rem;
  color: $hint;
  text-align: center;
}

.groupHeading {
  font-size: 1.4rem;
  font-weight: bold;
  color: $hint;
  padding: 1.6rem 0;
  text-transform: uppercase;

  @include for-phone-only {
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
  }
}
