.btn.btn {
  min-height: 0;
  min-width: 0;
  margin: 0;
  width: 100%;
  padding: 0;
  height: 3.6rem;
}

.btn.btn > div {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.reset.reset {
  width: auto;
  height: auto;
  min-height: 0;
  min-width: 0;
  margin: 0;
}
