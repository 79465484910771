@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.notification {
  display: flex;
  flex-direction: row;
  height: 7.8rem;
  border-top: 1px solid #fff;
  font-weight: bold;
  color: $primary;
  background-color: $background;
  cursor: pointer;
  overflow: hidden;

  &.seen {
    background-color: $contrast;
    border-top: 1px solid $background;
    border-left: $defaultBorderStyle;
    border-right: $defaultBorderStyle;
    font-weight: normal;
  }

  &:last-child {
    border-bottom: 1px solid $background;
  }
}

.notificationDetails {
  flex-grow: 1;
  flex-shrink: 1;

  @include for-phone-only {
    padding: 1rem 1.5rem 1rem 0;
  }
}

.notificationIcon {
  font-size: 1.6rem;
  text-align: center;
  margin: 1.2rem 2.4rem;

  @include for-phone-only {
    margin: 0;
    padding: 1.5rem;
  }
}

.notificationText {
  flex-grow: 1;
  flex-basis: 0;
  margin: 1.0rem 1.5rem 0.5rem 0;
  line-height: 1.5;
  word-break: break-word;

  @include for-phone-only {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }
}

.timestamp {
  flex-basis: 0;
  margin: 0 1.5rem 1.0rem 0;
  font-size: 1rem;
  color: $slate;
  font-weight: normal;
}

.notificationPage {
  &.notification {
    height: auto;
    &.seen {
      border-left: none;
      border-right: none;
    }
  }
  .timestamp {
    margin-bottom: 1.5rem;

    @include for-phone-only {
      margin: 0;
    }
  }
}
