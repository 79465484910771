@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

$inputHeight: 5rem;
$inputHeightMobile: 4rem;

.timeSelect {
  flex: 1;
  font-size: 1.2rem;
}

.label {
  color: $secondary;
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 0.2rem;
}

.timeDisplay {
  padding: 1.2rem;
  border: $defaultInputBorderStyle;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: $inputHeight;
  box-sizing: border-box;

  @include for-phone-only {
    height: $inputHeightMobile;
    padding: 1rem;
  }
}

.timeDisplayFocused {
  border: $activeInputBorderStyle;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.timeDisplayFocused > .dropdownIcon {
  transform: rotate(180deg);
  transition: transform 150ms ease;
}

.dropdownContainer {
  height: 0;
  flex: 1;
  position: relative;
}

.dropdownIcon {
  fill: $primary;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  transition: transform 150ms ease;
  transform: translate3d(0,0,0);
}

.dropdownContent {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  position: absolute;
  background-color: $contrast;
  border: $activeInputBorderStyle;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  margin-bottom: 1rem;
}

.dropdownColumn {
  height: 25rem;
  overflow-y: auto;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  a {
    color: $secondary;
    padding: 1rem 1.8rem 0;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .selected {
    color: $primary;
    font-weight: bold;
  }

  :last-child {
    padding-bottom: 1rem;
  }
}
.showCustomize{
  padding-bottom: 4rem;
  .customize{
    position: absolute;
    bottom: 0;
    padding: 1rem 1.8rem;
    width: 73%;
    border-top: solid 1px #e3e7ed;
    &:hover{
      cursor: pointer;
    }
  }
}

@media (max-width: 812px) {
  .showCustomize{
    .customize{
      width: 89%;
    }
  }
}