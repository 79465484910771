@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.displayWrapper {
  display: inline-block;

  @include for-phone-only {
    padding-left: 0.8rem;
  }
}

.statusLabel {
  display: flex;
  align-items: center;
  margin: 0;
}

.after {
  margin-right: 0.8rem;
}

.statusIcon {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;

  &.available {
    background-color: $available;
    border: solid 2px $available;
  }

  &.full {
    background-color: $full;
    border: solid 2px $full;
  }

  &.seeking {
    background-color: $contrast;
    border: solid 2px $seekingHours;
  }

  &.resigned {
    background-color: $resigned;
    border: solid 2px $resigned;
  }

  &.onboarding {
    background-color: $contrast;
    border: solid 2px $onboarding;
  }

  &.before {
    margin: 0 1.2rem 0 0.5rem;
  }
}
