@import 'components/atoms/responsiveBreakpoints.scss';
@import 'components/atoms/colours.scss';

.contents {
  width: 80%;
  max-width: 64rem;
  padding: 1rem;
  margin: 0 auto;

  @include for-phone-only {
    height: 100%;
    width: 100%;
    background-color: $mist;
    padding: 0;
  }
}

.emptyState {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.graphic {
  width: 10rem;
  margin-bottom: 2rem;
  margin-top: 12rem;
  @include for-phone-only {
    margin-top: 6rem;
    width: 8rem;
  }
}

.yourDone {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.button {
  margin-top: 1.5rem;
}

.emptyContent {
  min-width: 28.8rem;
  width: 80%;
  align-items: center;
  text-align: center;
}

.bannerIcon {
  width: 2rem;
  height: 2rem;
  margin: 0;
  display: inline-block;
  @include for-phone-only {
    margin-right: 0.5rem;
  }
}