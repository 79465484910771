:global(.static-tooltip-container) {
    position: relative;
  }
  
  :global(.static-tooltip) {
    position: absolute;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    opacity: 0.9;
    
    &:global(.static-tooltip-bottom) {
      padding: 0px;
      
      :global(.static-tooltip-arrow) {
        left: 50%;
        margin-left: -5px;
      }
    }
  }

:global(.static-tooltip-content) {
    position: relative;
    left: -50%;
 }

:global(.static-tooltip-arrow) {
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 0 6px 12px;
    border-bottom-color: #1D1E2B;
    position: relative;
  }

:global(.static-tooltip-inner) {
    display: table-cell;
    background-color: #1D1E2B;
    padding: 8px 10px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    min-height: 34px;
}