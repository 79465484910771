@import 'components/atoms/colours.scss';

.labelContainer {
  display: flex;
  flex: 1 0 auto;
  padding: 0.4rem;
  border-radius: 2px;
  min-width: 0;
  &:not(.disabled) {
    cursor: pointer;
  }
}

.categoryColor {
  display: inline-flex;
  margin-right: 1rem;
  min-width: 1.2rem;
  border-radius: 2px;
}

.textContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-width: 0;
  text-align: left;
}

.category {
  color: $secondary;
  display: block;
  font-size: 1rem;
}

.missionWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mission {
  margin-right: 0.8rem;
  white-space: nowrap;
}

