@import 'components/atoms/colours.scss';

.download i, .delete i {
  font-size: 1.5em;
  color: $hint;
  cursor: pointer;
}

.download, .delete {
  display: inline-block;
}

.download i:hover, .delete i:hover {
  color: $primary;
}

.block {
  display: block;
}

.download {
  margin-right: 1em;
}
