@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';


.container {
  display: flex;
  flex-flow: column;
  max-width: 64rem;
  margin: auto;
  padding: 3.2rem;
}

.deactivateButton {
  composes: warning from 'styles/buttons';
  font-weight: bold;
  text-decoration: none;
}

.form {
  flex-grow: 1;
  margin-top: 1.6rem;
}

.multiSelect {
  width: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.modalText {
  color: $primary;
  font-size: 1.2rem;
}

.hide {
  display: none;
}

.label {
  font: caption; 
}

.option {
  display: flex;
  align-content: center;
  margin-bottom: 1rem;

  input {
    margin: 0 0.5rem 0 0;
  }
}