.drawer {
  display: flex;
  flex-direction: column;
  max-width: 60vw;
}

.disableClick {
  pointer-events: none;
  touch-action: none;
}

.notificationPageWrapper {
  max-width: 70rem;
}

.closeIcon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}