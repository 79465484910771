@import 'components/atoms/colours.scss';

.wrapper {
  box-sizing: border-box;
  margin: 2rem 0 0;
  min-height: 3.6rem;
}

.avatarContainer {
  align-self: stretch;
  padding-right: 1.6rem;
  width: 3.4rem;
  height: 3.4rem;
  flex: 0 0 3.4rem;
}

.userSummary {
  min-height: 3.4rem;
  max-height: 3.4rem;
  align-items: center;
  justify-content: space-between;
}

.userInfo {
  align-self: stretch;
  justify-content: space-between;
  min-width: 20rem;
}

.userName {
  font-size: 1.4rem;
  color: $primary;
  text-decoration: none;
}

.userName:hover {
  text-decoration: underline;
}

.userSubtext {
  font-size: 1rem;
}

.close {
  cursor: pointer;
}
