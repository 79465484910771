@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.back{
  margin: 1.9rem 0 0 3rem;
  font-size: 1.4rem;
}

.header {
  color: $primary;
  text-align: left;
  padding: 1.6rem 3rem 2.4rem;
  border-bottom: $defaultBorderStyle;

  h2 {
    margin: 0;
    font-size: 2.8rem;
}
}

.notificationPage {
  padding: 1rem 3rem;

  @include for-phone-only {
    padding: 0;
  }
}
