@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

@mixin for-smallphone-only {
    @media (max-width: 359px) { @content; }
  }

%button {
    padding-top: 4px;
    border-radius: 3px;
    border: 0px;
    margin-left: 3px;
}

%button-active {
    @extend %button;

    background: $charcoal;
    color: $mist;
    
}

%button-disabled {
    @extend %button;

    background: $disabled;
    color: $disabledText;
    
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.navbar {
    width: 100%;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 57px;
    clear: both;
    position: relative;

    @include for-phone-only {
        height: 44px;
        padding: 4px 10px 4px 12px;
    }

    @include for-smallphone-only {
        height: 48px;
        padding: 4px 10px 4px 12px;
    }

    background: $charcoal;
    color: $white;

    button {
        cursor: pointer;
        @extend %button-active;
    }
    
    button:hover{
        background: #73747d;
        border-radius: 4px;
    }

    button:disabled {
        @extend %button-disabled;
    }

    .headerGroup {
        display: block;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);

        max-width: calc(100% - 448px);
        overflow: hidden;
        white-space: nowrap;

        text-align:center;
        text-overflow: ellipsis;
        font-size:24px;
        color: $white;

        

        @include for-phone-only {
            max-width: calc(100% - 100px);
            padding-top: 5px;
            font-size: 18px;
        }

        @include for-smallphone-only {
            padding-top: 9px;
        }

    }

    .zoomGroup {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        width: 224px;
        position: absolute;
        right: 60px;

        @include for-phone-only {
          width: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
        }

        img,svg{
            width: 20px;
            height: 20px;
        }

    }
}



.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:auto;
    box-sizing: border-box;
    
    height: calc(100vh - (69px));
    max-height: calc(100vh - (69px));

    padding-top: 20px;

    @include for-phone-only {
        height: calc(100vh - (40px + 50px));
        max-height: calc(100vh - (40px + 50px));
        width: 100vw;
        max-width: 100vw;
        padding-top: 10px;
    }
    
    background: #E5E7ED;

    .Document {
        .Page {
            max-width: calc(100% - 0px);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
            margin: 10px;
        }
    }
}

.contents {
    background-color: $mist;
}

.notFoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.fileWrapperContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    box-sizing: border-box;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
}

.documentViewer {
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
}

.operationBar {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
  
.pageButtonGroup, .zoomButtonGroup {
    display: flex;
    align-items: center;
}

.zoomButtonGroup{
    @include for-phone-only {
        display: none;
    }
}
.pageGroup {
    cursor: pointer;
    padding: 0 8px 0 2px;
    @include for-phone-only {
        display: none;
    }
}
.pageNumInput {
    min-width: 10px;
    max-width: 20px;
    border-style: hidden;
    text-align: right;
    margin: 0px;
    padding: 0px;
    color: $white;
    background-color: transparent;
    margin-right: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
