@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.page {
  height: 100%;
  background: $background url(/static/media/dash_background.svg);

  @include for-phone-only {
    min-height: calc(100vh - 5rem);
  }
}

.top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3.2rem;
  grid-row-gap: 2.4rem;

  padding: 3.2rem 2.4rem;
  padding-bottom: 0;


  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}

.main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3.2rem;
  grid-row-gap: 2.4rem;

  padding: 3.2rem 2.4rem;
  

  .bigCol {
    grid-row: 1 / span 2;
  }

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}