.headerStyle{

  border-bottom: none;
  margin-top: 20px;

  div {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #1d1e2b;
  }
}
.customButtonGroup {
  align-self: center;
}

.buttonCss{
  // background-color: #464ac9 !important;
  border-radius: 13px;
}
.secondButtonCss{
  background: none;
  border: solid 2px #ed4b53;
  border-radius: 13px;
}
.secondButtonText{
  color: #ed4b53;
}
.mainContent {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #1d1e2b;
}