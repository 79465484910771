@import 'components/atoms/colours.scss';

.bookingWith {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  max-height: calc(100vh - 8.5rem);
}

.unselectAll {
  align-self: flex-end;
  margin: 0.5rem 0;
  color: $hint;
  text-decoration: underline;
}

.unselectAllActive {
  cursor: pointer;
  color: $callToAction;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}