@import 'components/atoms/colours.scss';

.vipTagTitle {
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    height: 1rem;
    + span {
      display: inline-block;
      margin-left: 0.4rem;
    }
  }
}