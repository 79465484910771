@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

$item-height: 60px;

.contactListContainer {
  @include for-desktop-only {
    height: 40vh;
  }
  margin: 1rem 0;
  overflow: auto;
}

.contactListCaption {
  margin: 0.8rem 0 0 0.8rem;
}

.counter {
  color: $primary;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  flex: 0 1;
}

.loadingIndicator {
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.telescopeContainer {
  margin-top: 8rem;
  justify-self: center;
  display: flex;
  flex-direction: column;
}

.telescope {
  flex: 1;
  align-self: center;
}

.telescopeText {
  flex: 1;
  align-self: center;
  text-align: center;
  margin-top: 1rem;
}