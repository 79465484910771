@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';

.addNew {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.4rem 0.6rem;
  border-radius: 3px;
  margin-left: 1.6rem;
  background-color: transparent;

  @include for-phone-only {
    margin-top: 1.2rem;
  }

  > .addCircle {
    fill: $callToAction;
    margin-right: 0.6rem;
  }

  > .addNewText {
    color: $callToAction;
  }

  &:focus, &:hover {
    @include for-desktop-only {
      background-color: $backgroundHover;
      transition: background-color .2s;
    }
  }
}