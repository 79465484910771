@import "./base.scss";

.ds-checkbox.MuiButtonBase-root {    
    @extend .typography;
    border-style: none;            
    background-color: transparent;

    &:hover {
        background-color: var(--checkbox_default_color_bkg_hover);
    } 
    .MuiSvgIcon-root, .MuiTouchRipple-root {   
        width: 18px;
        height: 18px;
        color: var(--checkbox_default_color);            
    }  

    &.MuiIconButton-colorPrimary, &.MuiCheckbox-colorPrimary {
        &:hover {
            background-color: var(--checkbox_primary_color_bkg_hover);
        } 
        .MuiSvgIcon-root, .MuiTouchRipple-root {        
            color: var(--checkbox_primary_color);                        
        }        
    }    

    &.MuiIconButton-colorSecondary {                      
        &:hover {
            background-color: var(--checkbox_secondary_color_bkg_hover);
        }   
        .MuiSvgIcon-root, .MuiTouchRipple-root {   
            color: var(--checkbox_secondary_color);                        
        }        
    }  

    &.Mui-disabled {
        &:hover {
            background-color: var(--checkbox_disabled_color_bkg_hover);
        } 
        .MuiSvgIcon-root  {
            color: var(--checkbox_disabled_color); 
        }                
    }   
}
