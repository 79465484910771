@import 'components/atoms/colours.scss';
@import 'components/atoms/responsiveBreakpoints.scss';


.container {
  max-width: 64rem;
  margin: 1.6rem auto;

  &:hover {
    background-color: white;
  }
}

.pendingContainer {
  padding-bottom: 1rem;
}

.buttonContainer {
  padding-top: 1.6rem;
  display: flex;
}

.button {
  flex: 1;
}

.subjectText {
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.roleText {
  color: $slate;
  font-size: 1rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  color: $salmon;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: right;
  margin-top: 0.8rem;
  border-radius: 2px;
  
  &:hover {
    cursor: pointer;
  }
}