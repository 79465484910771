@import 'components/atoms/colours.scss';

.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33rem;
  margin-top: -2rem;

  > * {
    margin-top: 2rem;
  }
}

.header {
  color: $slate;
}

.body {
  text-align: center;
  color: $slate;
}
