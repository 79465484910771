@import 'components/atoms/colours.scss';
@import 'components/atoms/borders.scss';
@import '../styles.scss';

$inputHeight: 7rem;

.commentField {
  color: $primary;
  background-color: $contrast;
  min-height: $inputHeight;
  width: 100%;
  padding: .5rem 1.6rem;
  border-radius: 0.2rem;
  border: $defaultInputBorderStyle;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.2rem;
  resize: vertical;
  -webkit-appearance: none;

  &::placeholder{
    color: $hint;
  }
  &:focus{
	  border: $activeInputBorderStyle;
  }
}
