/* Base speeds  */
$tortoise: 10000ms;
$turkey: 500ms;
$bear: 300ms;
$elk: 150ms;
$gazelle: 100ms;
$cheetah: 50ms;

/* Base functions  */
$regular: ease;
$bounce: cubic-bezier(.75,-0.5,0,1.75);


/* Main app speeds */
$primarySpeed: $gazelle;

/* Main app functions */
$primaryTimingFunc: $regular;



.toggleTabsTransition {
  transition: left $primarySpeed $primaryTimingFunc, width $primarySpeed $primaryTimingFunc;
}

.toggleTabActiveTextTransition {
  transition: color $primarySpeed $primaryTimingFunc;
}

.fadeContentDown {
  animation: fadedown 0.2s ease-in;
}

.fadeContentUp {
  animation: fadeup 0.2s ease-in;
}

@keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-0.3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(0.3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}